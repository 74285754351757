import { useState, ReactNode } from 'react';

import { RatingContext } from './RatingContext';

type Props = {
  children: ReactNode;
};

export function RatingContextProvider({ children }: Props) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  return (
    <RatingContext.Provider
      value={{
        rating,
        setRating,
        comment,
        setComment
      }}
    >
      {children}
    </RatingContext.Provider>
  );
}
