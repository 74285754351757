/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { Box, Typography, useTheme, Button } from '@mui/material';
import { Container } from '../../components/Container';
import { useHeader } from '../../hooks/useHeaderContext';
import { appGasService } from 'config/services/appgas';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isLocalStorageAvailable } from 'hooks/useLocalStorage';
import { reset as resetCompany } from 'store/company/companySlice';
import { reset as resetOrder } from 'store/order/orderSlice';
import { OrderContext } from 'providers/order-context/order.context';
import { OrderCanceledIcon } from './assets/orderCanceled.icon';

export default function OrderCancelled() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setTitle, setSubtitle, setColor, setEditAddress } = useHeader();
  const { order, setOrder } = useContext(OrderContext);

  const [searchParams] = useSearchParams();
  const wasCancelledByClient =
    searchParams.get('cancelledBy') === 'client' || 'order_expired';

  const dictionary: { [key: string]: string } = {
    user_cancelled: 'pelo cliente',
    driver_cancelled: 'pelo motorista',
    admin_cancelled: 'pelo revendedor'
  };

  const clearOrder = () => {
    if (isLocalStorageAvailable()) {
      if (!wasCancelledByClient) {
        const permanentItems = ['leadData', 'addressData', 'chosen_products'];

        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);

          if (!permanentItems.includes(key as string)) {
            localStorage.removeItem(key as string);
          }
        }
      }
    }

    dispatch(resetOrder());
    dispatch(resetCompany());
    setOrder(null);
  };

  useEffect(() => {
    if (!order?.id) {
      return navigate('/');
    }

    setColor(theme.palette.error.main);
    setSubtitle('Seu pedido foi cancelado');
    setEditAddress(false);

    dispatch(resetOrder());

    (async () => {
      const orderId = order.id;

      const { data } = await appGasService.get(
        `/v2/integrations/orders/${orderId}`
      );

      setTitle(
        `Pedido cancelado ${data.canceled_at ? `em ${data.canceled_at}` : ''}`
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const repurchase = () => {
    setTitle('Compra Web Appgas');
    setSubtitle('Seja bem-vindo(a)');
    setEditAddress(false);
    setColor(theme.palette.primary.xxdark);
    clearOrder();

    if (!wasCancelledByClient) {
      navigate('/');
    }

    if (wasCancelledByClient) {
      navigate('/resellers');
    }
  };

  return (
    <Container>
      <Box sx={{ mt: '6rem', padding: '15px', textAlign: 'center' }}>
        <OrderCanceledIcon width={50} height={50} />
        {order && dictionary[order?.status] && (
          <Typography
            color="error"
            fontSize="large"
            sx={{
              mt: '6px',
              fontWeight: theme.typography.fontWeightMedium
            }}
          >
            Pedido cancelado {dictionary[order?.status]}
          </Typography>
        )}

        {order && !dictionary[order.status] && (
          <Typography
            color="error"
            fontSize="large"
            sx={{
              mt: '6px',
              fontWeight: theme.typography.fontWeightMedium
            }}
          >
            Pedido cancelado
          </Typography>
        )}

        <Typography
          sx={{
            mt: '6px',
            color: theme.palette.grey[600],
            fontWeight: theme.typography.fontWeightLight
          }}
        >
          Não se preocupe, você não será cobrado por este serviço.
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          sx={{
            py: '13.5px',
            mt: '30px',
            fontWeight: 700,
            width: { xs: '100%', md: 'initial' }
          }}
          onClick={() => repurchase()}
        >
          COMPRAR NOVAMENTE
        </Button>
      </Box>
    </Container>
  );
}
