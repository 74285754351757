import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { Order } from 'types';

export class GetOrderDataUseCase {
  private api = appGasService;

  async handle(orderId: string) {
    const { data } = await this.api.get<unknown, AxiosResponse<Order>>(
      `/v2/integrations/orders/${orderId}`
    );

    return data;
  }
}
