import {
  Button,
  CardMedia,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { ReactNode, useEffect, useState } from 'react';
import { IProductsState } from 'providers/chosen-products-context/chosen-products-contex';
import Slider from 'react-slick';

interface IProps {
  product: IProductsState;
  onSuccess: () => void;
}

export function ProductObservations(props: IProps) {
  const { product, onSuccess } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [picturesAmount, setPicturesAmount] = useState<number>(0);

  function onClose(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setIsOpen(false);
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(true);
    event.stopPropagation();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) =>
      setCurrentSlide(newIndex),
    appendDots: (dots: ReactNode) => (
      <div style={{ display: 'none' }}>{dots}</div>
    ),
    customPaging: (i: number) => (
      <div className={`custom-dot ${i === currentSlide ? 'active' : ''}`} />
    )
  };

  useEffect(() => {
    let amount = 0;

    if (product.picture) {
      amount += 1;
    }
    if (product.picture2) {
      amount += 1;
    }
    if (product.picture3) {
      amount += 1;
    }
    if (product.picture4) {
      amount += 1;
    }

    setPicturesAmount(amount);
  }, [product]);

  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        onClick={handleButtonClick}
        sx={{
          maxHeight: '15px',
          marginTop: '6px',
          px: '6px',
          fontSize: '10px',
          textTransform: 'none',
          fontWeight: '400',
          borderColor: theme.palette.text.primary,
          color: theme.palette.text.primary
        }}
      >
        + Detalhes do produto
        <Dialog
          open={isOpen}
          maxWidth="sm"
          fullWidth={true}
          onClose={onClose}
          sx={{ padding: '24px' }}
        >
          <Stack
            direction={'row'}
            px={'24px'}
            pt={'24px'}
            pb={'16px'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography
              variant="h5"
              fontSize={'22px'}
              fontWeight={'400'}
              color={'#1C3B77'}
            >
              {product.name}
            </Typography>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon width={16} />
            </IconButton>
          </Stack>
          <Stack px={'24px'} pb={'24px'} gap={'16px'}>
            <Stack
              border={`solid 1px #0000001A`}
              p={'24px'}
              borderRadius={'8px'}
            >
              {picturesAmount > 1 ? (
                <Slider {...settings}>
                  <div>
                    <Stack
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'row'}
                      direction={'row'}
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={product.picture}
                        sx={{
                          height: '110px',
                          objectFit: 'contain'
                        }}
                      />
                    </Stack>
                  </div>
                  {product.picture2 && (
                    <div>
                      <Stack
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'row'}
                        direction={'row'}
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={product.picture2}
                          sx={{
                            height: '110px',
                            objectFit: 'contain'
                          }}
                        />
                      </Stack>
                    </div>
                  )}
                  {product.picture3 && (
                    <div>
                      <Stack
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'row'}
                        direction={'row'}
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={product.picture3}
                          sx={{
                            height: '110px',
                            objectFit: 'contain'
                          }}
                        />
                      </Stack>
                    </div>
                  )}
                  {product.picture4 && (
                    <div>
                      <Stack
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'row'}
                        direction={'row'}
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={product.picture4}
                          sx={{
                            height: '110px',
                            objectFit: 'contain'
                          }}
                        />
                      </Stack>
                    </div>
                  )}
                </Slider>
              ) : (
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'row'}
                  direction={'row'}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <CardMedia
                    component="img"
                    image={product.picture}
                    sx={{
                      height: '110px',
                      objectFit: 'contain'
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Stack
              direction={'row'}
              gap={'10px'}
              justifyContent={'center'}
              mt={'-4px'}
            >
              {Array(picturesAmount)
                .fill({})
                .map((_, index) => (
                  <Stack
                    key={index}
                    className={`custom-dot ${
                      index === currentSlide ? 'active' : ''
                    }`}
                    sx={{
                      width: '8px',
                      height: '8px',
                      background:
                        index === currentSlide
                          ? theme.palette.primary.dark
                          : '#D9D9D9',
                      borderRadius: '8px'
                    }}
                  />
                ))}
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                color={theme.palette.grey[600]}
                fontSize={'14px'}
                fontWeight={'400'}
              >
                {product.details}
              </Typography>
            </Stack>
            <Stack direction={'row'} justifyContent={'flex-end'} gap={'16px'}>
              <Button
                variant="text"
                size="large"
                sx={{
                  height: '48px',
                  color: theme.palette.primary.dark,
                  fontWeight: '700'
                }}
                onClick={onClose}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={onSuccess}
                sx={{ height: '48px', background: theme.palette.green.xdark }}
              >
                Comprar
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      </Button>
    </>
  );
}
