import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useLoadingState } from 'hooks/useLoadingState';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FinishOrderUseCase } from 'use-cases/orders/finish-order.use-case';
import { Close as CloseIcon } from '@mui/icons-material';

interface IProps {
  orderId: string;
}

export function ConfirmDelivery(props: IProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const { finishLoading, isLoading, startLoading } = useLoadingState();
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);

  async function handleFinishOrder() {
    startLoading();

    await new FinishOrderUseCase()
      .handle(props.orderId)
      .then(() => {
        navigate('/order-rating');
      })
      .catch((err) => {
        const msg = err?.response?.data?.message[0];
        toast.error(msg);
      })
      .finally(() => {
        finishLoading();
      });
  }

  return (
    <Stack
      direction={'column'}
      sx={{ p: '16px' }}
      borderTop={`solid 1px ${theme.palette.grey[300]}`}
    >
      <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
        <Typography>Seu pedido chegou?</Typography>
      </Stack>
      <Typography
        fontWeight={'400'}
        sx={{ color: theme.palette.grey[600] }}
        fontSize={'14px'}
        lineHeight={'18px'}
        maxWidth={'290px'}
      >
        Confirme assim que receber seu pedido e nos ajude a saber que deu tudo
        certo.
      </Typography>
      <Button
        onClick={() => setIsOpenConfirm(true)}
        variant="contained"
        disabled={isLoading}
        size="large"
        sx={{
          py: '10px',
          height: '48px',
          mt: '16px',
          fontWeight: 700,
          width: { xs: '100%', md: 'initial' }
        }}
      >
        {isLoading ? 'Aguarde...' : 'Confirmar entrega'}
      </Button>
      <Dialog
        open={isOpenConfirm}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setIsOpenConfirm(false)}
        sx={{ padding: '24px' }}
      >
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                color: '#3968A6',
                fontSize: '22px',
                mb: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px'
                }
              }}
            >
              <span style={{ color: theme.palette.primary.main }}>
                Meu pedido foi entregue
              </span>
              <CloseIcon
                sx={{ cursor: 'pointer', color: '#BDBDBD' }}
                onClick={() => setIsOpenConfirm(false)}
              />
            </Box>
            <Box sx={{ color: '#9E9E9E', fontSize: '14px' }}>
              Você tem certeza que recebeu seu pedido e quer confirmar a
              entrega? Ele será finalizado como entregue.
            </Box>
            <Box
              sx={{
                alignItems: 'end',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                paddingTop: '10px'
              }}
            >
              <Button
                sx={{
                  height: '48px',
                  backgroundColor: theme.palette.green.main,
                  fontWeight: 700
                }}
                variant="contained"
                disabled={isLoading}
                onClick={handleFinishOrder}
              >
                {isLoading ? 'Aguarde...' : 'Confirmar entrega'}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
