import { useState, ReactNode, useContext } from 'react';
import { useTheme } from '@mui/material';
import { HeaderContext } from './HeaderContext';

import { useNavigate } from 'react-router-dom';
import { useAddresses } from 'hooks/useAddressesContext';
import { OrderContext } from 'providers/order-context/order.context';

interface IProviderProps {
  children: ReactNode;
}

export function HeaderProvider({ children }: IProviderProps) {
  const theme = useTheme();

  const [title, setTitle] = useState<string>('Compra Web Appgas');
  const [subtitle, setSubtitle] = useState<string>('Seja bem-vindo(a)');
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [color, setColor] = useState(theme.palette.primary.xxdark);

  const navigate = useNavigate();

  const { setAddressDataInput } = useAddresses();

  const { setOrder } = useContext(OrderContext);

  function clearOrder() {
    setOrder(null);
    setAddressDataInput(null);

    setTitle('Compra Web Appgas');
    setSubtitle('Seja bem-vindo(a)');
    setEditAddress(false);

    navigate('/');
  }

  return (
    <HeaderContext.Provider
      value={{
        title,
        color,
        subtitle,
        editAddress,
        setColor,
        setTitle,
        setSubtitle,
        setEditAddress,
        clearOrder
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}
