import { Box, Card, CardContent, Typography } from '@mui/material';
import { ISummaryData } from '../../types/ISummaryData';
import SummaryContent from '../SummaryContent';

type Props = {
  summary: ISummaryData;
};

export default function Summary({ summary }: Props) {
  return (
    <Box sx={{ mt: '2rem' }}>
      <Typography sx={{ my: '16px' }}>Resumo da compra:</Typography>

      <Card
        variant="custom"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            fontSize: '0.75rem',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}
        >
          <SummaryContent summary={summary} />
        </CardContent>
      </Card>
    </Box>
  );
}
