import TagManager from 'react-gtm-module';

enum integrationGTags {
  'alelo' = 'G-0DFZWH5LWB',
  'alelo-staging' = 'G-E7897M78X6',
  'aleloec' = 'G-L0D7L7ZZYW',
  'bancomercantil' = 'G-Q5K3RMP8WN',
  'bancooriginal' = 'G-REL6HM3LKN',
  'bradesco' = 'G-EXG7PB95YW',
  'c6bank' = 'G-PPL359K6FS',
  'consigaz' = 'G-HBT4HVWSWP',
  'creditas' = 'G-BZ7QDME3Y7',
  'credz' = 'G-KL37HMEDN3',
  'credsystem' = 'G-H80DK32MZL',
  'econobuz' = 'G-8MVPF1MQQ4',
  'elo' = 'G-7H687PP8GS',
  'ifood' = 'G-F0DDMX3PSC',
  'jeitto' = 'G-FLX4PX07FG',
  'kaledo' = 'G-MDPKDZRE91',
  'picpay' = 'G-QE3FWVVD5S',
  'sindrodsantos' = 'G-0R366J9YY9',
  'caixaelo' = 'G-Z0EMEC45Y6',
  'mastercard' = 'G-FK5LJ9F4B5',
  'paoclass' = 'G-V5LLNX1LT2',
  'parceiros' = 'G-F45SSL3QFG',
  'socialbank' = 'G-V8GDS8GKWV',
  'zaloo' = 'G-R4LWVRZB6V',
  'analytics' = 'G-TJ1FDD9WHV',
  'default' = 'G-FY2G31RQ7G'
}

export function initTagManager() {
  const domain = window.location.hostname.split('.appgas.com');

  const gaTag =
    integrationGTags[domain[0] as keyof typeof integrationGTags] ||
    integrationGTags.default;

  TagManager.initialize({
    gtmId: 'GTM-5HBH7H2',
    dataLayer: { subdomain_tag: gaTag }
  });
}
