// import Adjust from '@adjustcom/adjust-web-sdk';
// import { Tracking, Events } from 'config/tracking/type';
import { Events } from 'config/tracking/type';

// const environment = `${process.env.REACT_APP_ENV}`;

type TrackEventProps = {
  eventToken: keyof Events;
  sourceIntegration: string;
  logLevel?: 'verbose' | 'info' | 'warning' | 'error' | 'none';
};

export async function trackEvent({
  eventToken,
  logLevel = 'info',
  sourceIntegration = 'default'
}: TrackEventProps) {
  console.log(eventToken, logLevel, sourceIntegration);
  // let tracking: Tracking;

  // try {
  //   const { default: module } = await import(
  //     `../tracking/${sourceIntegration}`
  //   );
  //   tracking = module;
  // } catch {
  //   const { default: module } = await import(`../tracking/${'default'}`);
  //   tracking = module;
  // }

  // const webUUID = Adjust.getWebUUID();

  // if (!webUUID) {
  //   Adjust.initSdk({
  //     logLevel,
  //     appToken: tracking.token,
  //     environment: environment === 'production' ? environment : 'sandbox'
  //   });
  // }
  // Adjust.trackEvent({
  //   eventToken: tracking.events[eventToken] ?? ''
  // });
}
