import { useContext, useEffect } from 'react';
import { Container } from '../../components/Container';
import { Stepper } from '../../components/Stepper';
import { appGasService } from 'config/services/appgas';
import axios from 'axios';

import { formatPrice } from '../../helpers/formatPrice';

import {
  Box,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Divider,
  useTheme
} from '@mui/material';
import Summary from './components/Summary';
import PreviousAndNextButtons from 'components/PreviousAndNextButtons';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'components/Badge';
import { trackEvent } from 'config/services/adjustTracking';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { SummaryContext } from 'providers/summary-context/summary.context';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { useAddresses } from 'hooks/useAddressesContext';

export default function Review() {
  const navigate = useNavigate();

  const { palette, typography } = useTheme();

  const { integration } = useContext(SourceIntegrationContext);
  const source = integration.source;

  const { summaryData, setSummaryData } = useContext(SummaryContext);

  const { reseller } = useContext(ResellerContext);

  const { chosenProducts } = useContext(ChosenProductsContext);

  const { addressDataInput, createAddress } = useAddresses();

  useEffect(() => {
    if (!addressDataInput?.id) return navigate('/');
  }, [addressDataInput, navigate]);

  useEffect(() => {
    (async () => {
      await fetchSummary();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSummary = async () => {
    if (!reseller) {
      return;
    }

    try {
      if (!addressDataInput?.id) {
        await createAddress();
      }

      const { data } = await appGasService.post(
        `/v3/integrations/orders/summary`,
        {
          company_id: reseller.id,
          address_id: addressDataInput?.id,
          coupon: '',
          product_ids: chosenProducts.map((product) => {
            return {
              ...product,
              brand_id: product.brand_id || reseller.brand_id
            };
          }),
          sales_channel: 'compraweb',
          brand_id: reseller.brand_id,
          giftcard: summaryData?.giftcard || ''
        }
      );

      if (!summaryData?.coupon_id) {
        const summaryTime = new Date();
        setSummaryData({ ...data, summary_time: summaryTime });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error?.response?.data?.message);
      }
    }
  };

  return (
    <Container>
      <Box
        sx={{
          mt: '2rem',
          backgroundColor: 'white',
          padding: '15px',
          borderRadius: '8px',
          border: '1px solid #e0e0e0'
        }}
      >
        <Stepper
          totalSteps={source !== 'ifood' ? 4 : 3}
          currentStep={source !== 'ifood' ? 3 : 2}
          title={'Revisão do pedido'}
          showStepInfo={true}
        />
      </Box>

      <Box sx={{ mt: '2rem' }}>
        <Card
          variant="custom"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <CardHeader
            title={
              <Typography
                sx={{
                  color: palette.primary.xdark,
                  fontSize: '0.625rem',
                  fontWeight: 'bold'
                }}
              >
                Vendido e entregue por:
              </Typography>
            }
            subheader={
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  color: palette.grey[700],
                  fontWeight: typography.fontWeightLight
                }}
              >
                {reseller?.display_name?.toUpperCase()}
              </Typography>
            }
            sx={{
              backgroundColor: '#ECECEC'
            }}
          />
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              fontSize: '0.75rem'
            }}
          >
            {!!chosenProducts &&
              chosenProducts.map((product) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: '0.75rem',
                    marginBottom: '8px'
                  }}
                  key={product.id}
                >
                  <Typography
                    sx={{
                      fontSize: '0.875rem'
                    }}
                  >
                    <span style={{ fontWeight: 900 }}>{product.quantity}</span>x{' '}
                    {product.name}
                  </Typography>

                  {reseller && (
                    <Badge
                      text={reseller.brand?.toUpperCase()}
                      color={reseller.brand_color}
                    />
                  )}

                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: '900'
                    }}
                  >
                    {formatPrice(product.price)}
                  </Typography>
                </Box>
              ))}
          </CardContent>
        </Card>
      </Box>
      {summaryData && <Summary summary={summaryData} />}
      <Divider sx={{ my: '16px' }} />

      <PreviousAndNextButtons
        nextAction={() => {
          trackEvent({
            eventToken: 'Revisou pedido',
            sourceIntegration: source
          });
          navigate('/checkout');
        }}
        previousAction={() => {
          return navigate('/check-cnpj');
        }}
      />
    </Container>
  );
}
