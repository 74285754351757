import { styled } from '@mui/system';

import { InputLabel } from '@mui/material';

export const CustomLabel = styled(InputLabel)`
  &.MuiInputLabel-shrink {
    font-size: 0.95rem;
    font-weight: 600;
    line-height: 18px;
    height: 18px;
    top: initial;
  }
`;
