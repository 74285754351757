import {
  Box,
  styled,
  Button,
  IconButtonProps as MUIIconButtonProps,
  lighten
} from '@mui/material';

interface IconButtonProps extends MUIIconButtonProps {
  previousActionButtonColor: string | undefined;
  previousActionButtonTextColor: string | undefined;
}

export const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '16px',
    position: 'fixed',
    background: '#FFF',
    borderTop: `1px solid ${theme.palette.grey[300]}}`
  }
}));

export const GroupButtons = styled(Box)({
  mb: '16px',
  display: 'flex',
  justifyContent: 'space-between'
});

export const PreviousButton = styled(Button)(({ theme }) => ({
  paddingLeft: '13.5px',
  paddingRight: '13.5px',
  fontWeight: 700,
  width: 'initial',
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    fontSize: '14px',
    padding: '13.5px 16px'
  }
}));

export const NextButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'previousActionButtonTextColor' &&
    prop !== 'previousActionButtonColor'
})<IconButtonProps>(
  ({ theme, previousActionButtonTextColor, previousActionButtonColor }) => {
    const color = `${
      !previousActionButtonTextColor ? '#FFF' : previousActionButtonTextColor
    }`;

    const previousButtonBackgroundColor = !previousActionButtonColor
      ? theme.palette.primary.main
      : previousActionButtonColor;

    return {
      color,
      fontWeight: 700,
      width: 'initial',
      paddingLeft: '13.5px',
      paddingRight: '13.5px',
      '&:disabled': {
        color,
        background: lighten(previousButtonBackgroundColor, 0.2)
      },
      background: previousButtonBackgroundColor,
      '&:hover': {
        background: previousButtonBackgroundColor
      },
      [theme.breakpoints.down('md')]: {
        width: 'auto',
        fontSize: '14px',
        padding: '13.5px 16px'
      }
    };
  }
);
