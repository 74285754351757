import { createContext } from 'react';

export interface IHeaderContextData {
  title: string;
  color: string;
  subtitle: string;
  editAddress?: boolean;
  setColor: (input: string) => void;
  setTitle: (input: string) => void;
  clearOrder: () => void;
  setSubtitle: (input: string) => void;
  setEditAddress: (input: boolean) => void;
}

export const HeaderContext = createContext<IHeaderContextData>(
  {} as IHeaderContextData
);
