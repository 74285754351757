import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: '',
  accessToken: ''
};

export const authenticationSlice = createSlice({
  initialState,
  name: 'authentication',
  reducers: {
    save(state, action: PayloadAction<string>) {
      state.error = '';
      state.loading = false;
      state.accessToken = action.payload;
    },
    error(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.accessToken = '';
    },
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  }
});

export const { save, error, loading } = authenticationSlice.actions;

export default authenticationSlice.reducer;
