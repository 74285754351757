import { createContext } from 'react';
import { Order } from 'types';

export interface OrderContextData {
  order: Order | null;
  setOrder: (input: Order | null) => void;
}

export const OrderContext = createContext<OrderContextData>(
  {} as OrderContextData
);
