import { styled } from '@mui/system';

import { FormControl, InputBase } from '@mui/material';

export const Form = styled(FormControl)`
  background-color: ${(props) => {
    if (props.disabled) return props.theme.palette.grey[100];

    return '#FFFF';
  }};
  border: 1px solid;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 56px;
  padding: 9px 16px;

  &.MuiInputLabel-root {
    &.Mui-focused {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const Input = styled(InputBase)`
  color: ${({ theme }) => theme.palette.grey['700']};
  font-size: 0.96rem;
  font-weight: 400;
`;
