import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { LocalShippingOutlined } from '@mui/icons-material';
import { useAddresses } from 'hooks/useAddressesContext';
import HelpIcon from '@mui/icons-material/Help';
import { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { ButtonsBox, ConfirmButton } from '../CancelModal/styles';
import { ConfirmationCode } from '../ConfirmationCode/confirmation-code';
import { Order } from 'types';
import { ConfirmDelivery } from '../ConfirmDelivery';

interface IProps {
  order: Order;
  orderStatusId: number;
  isSeparatingOrder: boolean;
}

export function DeliveryInfos(props: IProps) {
  const { palette } = useTheme();
  const { addressDataInput } = useAddresses();
  const [openResellerModal, setOpenResellerModal] = useState(false);
  const theme = useTheme();
  const { order, orderStatusId, isSeparatingOrder } = props;

  const isOrderStatusIdInProgressOrAccepted =
    orderStatusId == 1 || orderStatusId == 2;

  const isOrderStatusIdExpiredOrProcessing =
    orderStatusId == 4 || orderStatusId == 5;

  return (
    <Stack direction={'column'} gap={'8px'}>
      <Typography fontSize={'14px'} fontWeight={'600'}>
        Entregar em:
      </Typography>
      <Card
        variant="custom"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Stack>
          <CardContent
            sx={{
              px: '12px',
              py: '8px !important'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LocationOnOutlinedIcon
                  fontSize="small"
                  color="primary"
                  sx={{ mr: '8px' }}
                />
                <Stack>
                  <Typography
                    sx={{ fontSize: '12px' }}
                    variant="caption"
                    lineHeight={'16px'}
                    color={palette.grey[800]}
                    fontWeight={'500'}
                  >
                    {addressDataInput?.street}, {addressDataInput?.number} -{' '}
                    {addressDataInput?.neighborhood}
                    {addressDataInput?.complement}, {addressDataInput?.city} /{' '}
                    {addressDataInput?.state}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '12px' }}
                    variant="caption"
                    lineHeight={'16px'}
                    color={palette.grey[800]}
                    fontWeight={'500'}
                  ></Typography>
                </Stack>
              </Box>
            </Box>
          </CardContent>
        </Stack>
        {order?.confirmation_code &&
          !isOrderStatusIdExpiredOrProcessing &&
          isOrderStatusIdInProgressOrAccepted && (
            <ConfirmationCode code={order?.confirmation_code} />
          )}
        {order.delayed && !isSeparatingOrder && (
          <ConfirmDelivery orderId={order.id} />
        )}
        <CardContent
          sx={{
            px: '16px',
            py: '6px !important',
            backgroundColor: '#f5f5f5',
            borderTop: `solid 1px ${palette.grey[300]}`
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <LocalShippingOutlined
                  sx={{
                    color: palette.grey[800],
                    fontSize: '13px',
                    mr: '6px'
                  }}
                />
                <Typography
                  sx={{ fontSize: '10px' }}
                  variant="caption"
                  lineHeight={'12px'}
                  color={palette.grey[800]}
                  fontWeight={'500'}
                >
                  Entrega feita pelo revendedor.
                </Typography>
              </Stack>
              <HelpIcon
                onClick={() => setOpenResellerModal(true)}
                sx={{
                  color: '#757575',
                  fontSize: '13px',
                  ml: '6px',
                  cursor: 'pointer'
                }}
              />
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <Dialog
        open={openResellerModal}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpenResellerModal(false)}
        sx={{ padding: '24px' }}
      >
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                color: '#3968A6',
                fontSize: '22px',
                mb: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px'
                }
              }}
            >
              <span style={{ color: theme.palette.primary.main }}>
                Entrega não rastreável
              </span>
              <CloseIcon
                sx={{ cursor: 'pointer', color: '#BDBDBD' }}
                onClick={() => setOpenResellerModal(false)}
              />
            </Box>
            <Box sx={{ color: '#9E9E9E', fontSize: '14px' }}>
              A revenda que você escolheu para o seu pedido é responsável pela
              entrega.
            </Box>
            <ButtonsBox
              sx={{
                alignItems: 'end',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left'
              }}
            >
              <ConfirmButton
                sx={{
                  width: '91px',
                  height: '48px'
                }}
                variant="contained"
                onClick={() => setOpenResellerModal(false)}
              >
                ENTENDI
              </ConfirmButton>
            </ButtonsBox>
          </Box>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
