import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { Source } from 'types/Source';

interface IntegrationAuthenticationResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export class IntegrationAuthenticationUseCase {
  private api = appGasService;

  async handle(isource: Source) {
    const { data } = await this.api.get<
      unknown,
      AxiosResponse<IntegrationAuthenticationResponse>
    >(`/v2/compraweb/tokens/${isource}`);

    return data;
  }
}
