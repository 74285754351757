import { createContext } from 'react';
import { Integration } from './source-integration-provider';

export interface SourceIntegrationContextData {
  integration: Integration;
  isLoading: boolean;
  integrationAuthErr: string | null;
}

export const SourceIntegrationContext =
  createContext<SourceIntegrationContextData>(
    {} as SourceIntegrationContextData
  );
