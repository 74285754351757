import { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { appGasService } from 'config/services/appgas';

import { IconButton, ButtonsBox, ModalTitle, ConfirmButton } from './styles';
import { Order } from 'types';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface IModalProps {
  open: boolean;
  onClose: () => void;
  order: Order;
}

type Reason = {
  id: string;
  name: string;
};

export default function Reasons(props: IModalProps) {
  const { onClose, open, order } = props;
  const navigate = useNavigate();
  const [reasons, setReasons] = useState<Reason[]>([]);
  const [reasonId, setReasonId] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const { data } = await appGasService.get(
          `/v2/integrations/cancellation_reasons`
        );

        setReasons(data);
        setReasonId(data[0].id);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleConfirm = () => {
    (async () => {
      try {
        await appGasService.put(`/v2/integrations/orders/${order.id}/cancel`, {
          reason_id: reasonId
        });

        navigate('/order-cancelled?cancelledBy=client');
      } catch (e) {
        const error = e as AxiosError;
        const msg = error?.response?.data?.message;

        if (msg) {
          toast.error(msg, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      }
    })();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{ padding: '24px' }}
    >
      <DialogTitle>
        <ModalTitle>Motivo do cancelamento</ModalTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography
          sx={{
            fontSize: '0.9rem',
            color: '#9E9E9E'
          }}
        >
          Você ainda pode cancelar o seu pedido pois o mesmo não saiu para
          entrega, selecione abaixo o motivo do cancelamento.
        </Typography>

        <Box sx={{ width: '100%' }}>
          <FormControl>
            <RadioGroup
              defaultValue={reasonId}
              name="payment-methods-buttons-group"
              aria-labelledby="forma-de-pagamento"
              onChange={(event) => setReasonId(event.target.value)}
            >
              {!!reasons.length &&
                reasons.map((reason) => (
                  <FormControlLabel
                    control={<Radio />}
                    key={reason.id}
                    value={reason.id}
                    label={reason.name}
                  />
                ))}
            </RadioGroup>
          </FormControl>
          <ButtonsBox>
            <ConfirmButton variant="contained" onClick={() => handleConfirm()}>
              CONFIRMAR
            </ConfirmButton>
          </ButtonsBox>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
