import { createTheme } from '@mui/material/styles';
import { BancoMercantilRoundedIcon } from '../../components/LogoRounded/BancoMercantilLogo';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    custom: true;
  }
}

export const bancoMercantil = createTheme({
  logo: <BancoMercantilRoundedIcon />,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          padding: '8px 16px',
          boxShadow: 'none'
        }
      }
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'custom' },
          style: {
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            boxShadow: 'none'
          }
        }
      ]
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'custom' },
          style: {
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            boxShadow: 'none'
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    }
  },
  palette: {
    primary: {
      xxdark: '#164194',
      xdark: '#29508E',
      main: '#164194', //default
      light: '#6695C9',
      xlight: '#B5D9F6',
      xxlight: '#E5F5FF'
    },
    secondary: {
      xxdark: '#934605',
      xdark: '#DB7C0C',
      main: '#2ED4FA', //default
      light: '#FFBC4C',
      xlight: '#FFE39F',
      xxlight: '#FFF6DC'
    },
    grey: {
      '900': '#212429',
      '800': '#424242',
      '700': '#616161',
      '600': '#757575',
      '500': '#9E9E9E',
      '400': '#BDBDBD',
      '300': '#E0E0E0',
      '200': '#EEEEEE',
      '100': '#ECECEC',
      '50': '#FBFBFB'
    },
    text: {
      primary: '#212429',
      secondary: '#757575'
    },
    danger: {
      xxdark: '#7D0F3D',
      xdark: '#B92346',
      main: '#D83148', //default
      light: '#E76167',
      xlight: '#FBB8AD',
      xxlight: '#FFEFEA'
    },
    green: {
      xxdark: '#146A20',
      xdark: '#309E2E',
      main: '#4CB840', //default
      light: '#7FD46C',
      xlight: '#CDF7B7',
      xxlight: '#EFFFE3'
    }
  },
  typography: {
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h4: {
      fontWeight: 500
    },
    subtitle1: {
      fontSize: '1rem'
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 600
    },
    caption: {
      fontSize: '0.875rem'
    },
    fontFamily: ['Poppins', 'sans-serif'].join(',')
  }
});
