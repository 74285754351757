import * as React from 'react';

import Box from '@mui/material/Box';

interface IProps {
  children?: React.ReactNode;
  maxWidth?: string;
}

export function Container({ children, maxWidth }: IProps) {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: maxWidth ? maxWidth : '40rem',
        margin: '0 auto',
        paddingX: '24px'
      }}
    >
      {children}
    </Box>
  );
}
