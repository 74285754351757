import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';

interface GetAppUserUseCaseProps {
  name: string;
  phone: string;
}

interface GetAppUserUseCaseResponse {
  phone: string;
}

export class GetAppUserUseCase {
  private api = appGasService;

  async handle(props: GetAppUserUseCaseProps) {
    const { name, phone } = props;

    const payload = {
      name,
      phone
    };

    const { data } = await this.api.post<
      unknown,
      AxiosResponse<GetAppUserUseCaseResponse>
    >(`/v2/integrations/appuser/get`, payload);

    return data;
  }
}
