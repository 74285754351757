import { styled, Typography as MUITypography } from '@mui/material';

export const Description = styled(MUITypography)(
  ({ theme }) => `
  margin-top: 6px;
  margin-left: 8px;
  margin-bottom: 32px;
  color: ${theme.palette.grey[600]};
  font-weight: ${theme.typography.fontWeightLight}
`
);
