import * as React from 'react';

interface IProps {
  width: number;
  height: number;
}

export function FaqLogo(props: IProps) {
  return (
    <svg
      {...props}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.667 21.9993H15.3337V19.3327H12.667V21.9993ZM14.0003 0.666016C6.64033 0.666016 0.666992 6.63935 0.666992 13.9993C0.666992 21.3593 6.64033 27.3327 14.0003 27.3327C21.3603 27.3327 27.3337 21.3593 27.3337 13.9993C27.3337 6.63935 21.3603 0.666016 14.0003 0.666016ZM14.0003 24.666C8.12033 24.666 3.33366 19.8793 3.33366 13.9993C3.33366 8.11935 8.12033 3.33268 14.0003 3.33268C19.8803 3.33268 24.667 8.11935 24.667 13.9993C24.667 19.8793 19.8803 24.666 14.0003 24.666ZM14.0003 5.99935C11.0537 5.99935 8.66699 8.38602 8.66699 11.3327H11.3337C11.3337 9.86602 12.5337 8.66602 14.0003 8.66602C15.467 8.66602 16.667 9.86602 16.667 11.3327C16.667 13.9993 12.667 13.666 12.667 17.9993H15.3337C15.3337 14.9993 19.3337 14.666 19.3337 11.3327C19.3337 8.38602 16.947 5.99935 14.0003 5.99935Z"
        fill="white"
      />
    </svg>
  );
}
