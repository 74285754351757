import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import CustomInput from '../../../../components/CustomInput';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { toPattern } from 'vanilla-masker';
import axios from 'axios';
import { trackEvent } from 'config/services/adjustTracking';
import PreviousAndNextButtons from 'components/PreviousAndNextButtons';
import SendCodeVerificationModal from './SendCodeVerificationModal';

import { gasToast } from 'helpers/toasts';
import { eventInputName, eventInputPhone } from 'config/services/gtmEvents';

import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { GetAppUserUseCase } from 'use-cases/appuser/get-appuser.use-case';
import { useLoadingState } from 'hooks/useLoadingState';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';

export default function FormCreateLead() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { integration } = useContext(SourceIntegrationContext);
  const { setTemporaryPhone } = useContext(LeadDataContext);

  const source_integration = integration.source;

  const [leadDataInputErrors, setLeadDataInputErrors] = useState({
    name: '',
    phone: ''
  });

  const { finishLoading, isLoading, startLoading } = useLoadingState();

  const [phoneNumberWithoutMask, setPhoneNumberWithoutMask] = useState('');

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [modal, setModal] = useState(false);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    setLeadDataInputErrors({
      name: '',
      phone: ''
    });

    startLoading();

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
        phone: Yup.string().required('Campo obrigatório')
      });

      await schema.validate(
        { name, phone },
        {
          abortEarly: false
        }
      );

      await new GetAppUserUseCase()
        .handle({
          name: name,
          phone: toPattern(phone, '99999999999')
        })
        .then((data) => {
          setTemporaryPhone(toPattern(data.phone, '99999999999'));
          setPhoneNumberWithoutMask(toPattern(data.phone, '99999999999'));
          setModal(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err) => {
          setLeadDataInputErrors({
            ...leadDataInputErrors,
            [err.path as string]: err.message
          });
        });
      }

      if (axios.isAxiosError(error)) {
        setLeadDataInputErrors({
          name: error?.response?.data?.message?.name,
          phone: error?.response?.data?.message?.phone
        });
      }

      gasToast({
        message: 'Ocorreu um erro ao tentar salvar os dados.',
        type: 'error'
      });
    } finally {
      finishLoading();
    }
  }

  function navigateBack(e: React.FormEvent) {
    e.preventDefault();
    navigate('/resellers');
  }

  return (
    <>
      <SendCodeVerificationModal
        open={modal}
        onClose={() => setModal(false)}
        phoneNumber={phoneNumberWithoutMask}
      />

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CustomInput
              required
              name="name"
              label="Nome e sobrenome"
              value={name}
              onBlur={(e) => {
                if (e.target.value) {
                  trackEvent({
                    eventToken: 'Digitou nome',
                    sourceIntegration: source_integration
                  });
                  eventInputName();
                }
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              error={!!leadDataInputErrors.name}
              helperText={leadDataInputErrors.name}
              icon={
                <AccountCircleOutlinedIcon
                  sx={{ fontWeight: theme.typography.fontWeightLight }}
                  color={leadDataInputErrors.name ? 'error' : 'primary'}
                />
              }
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <CustomInput
              required
              name="phone"
              label="Telefone (11) 11111-1111"
              type="phone"
              componentsProps={{
                input: { inputMode: 'numeric' }
              }}
              error={!!leadDataInputErrors.phone}
              helperText={leadDataInputErrors.phone}
              value={phone && toPattern(phone, '(99) 99999-9999')}
              onBlur={(e) => {
                if (e.target.value) {
                  trackEvent({
                    eventToken: 'Digitou telefone',
                    sourceIntegration: source_integration
                  });
                  eventInputPhone();
                }
              }}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              icon={
                <LocalPhoneOutlinedIcon
                  color={leadDataInputErrors.phone ? 'error' : 'primary'}
                />
              }
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box>
              <Typography
                style={{
                  color: theme.palette.grey[600]
                }}
                sx={{
                  marginLeft: '20px',
                  fontSize: '12px'
                }}
              >
                Um código de confirmação será enviado para este número
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider light />
          </Grid>

          <Grid item xs={12} md={12}>
            <PreviousAndNextButtons
              nextActionType="submit"
              previousAction={navigateBack}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
