export function getOnlyHourFromDateTime(stringDate: string) {
  const date = new Date(stringDate);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return (
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ' '
  );
}
