import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Company = {
  cnpj: string;
  name: string;
  phone: string;
};

const initialState = {
  loading: false,
  error: '',
  company: {} as Company
};

export const companySlice = createSlice({
  initialState,
  name: 'company',
  reducers: {
    reset(state) {
      state.error = '';
      state.loading = false;
      state.company = {} as Company;
    },
    company(state, action: PayloadAction<Company>) {
      state.error = '';
      state.loading = false;
      state.company = action.payload;
    },
    error(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.company = initialState.company;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  }
});

export const { company, error, loading, reset } = companySlice.actions;

export default companySlice.reducer;
