import { styled, CardContent as MUICardContent } from '@mui/material';

export const CardContent = styled(MUICardContent)(
  ({ theme }) => `
  display: flex;
  font-size: 0.75rem;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid ${theme.palette.grey[300]};
`
);
