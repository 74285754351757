import CustomInput from '../../../../components/CustomInput';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

import { Description } from './styles';
import { useRating } from 'views/OrderRating/hooks/useOrderRatingContext';

export default function Comment() {
  const { comment, setComment } = useRating();

  return (
    <>
      <CustomInput
        name="message"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        icon={<TextsmsOutlinedIcon color="primary" />}
        inputProps={{
          placeholder: 'Deixe um comentário'
        }}
      />

      <Description>
        Seu comentário é importante para que possamos melhorar sua experiência
        de compra.
      </Description>
    </>
  );
}
