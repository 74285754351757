import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { AccessTime } from '@mui/icons-material';
import { useContext } from 'react';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { useAddresses } from 'hooks/useAddressesContext';

export function DeliveryInfos() {
  const { palette } = useTheme();
  const { reseller } = useContext(ResellerContext);
  const { addressDataInput } = useAddresses();

  return (
    <Stack direction={'column'} gap={'8px'}>
      <Typography fontSize={'14px'} fontWeight={'600'}>
        Entregar em:
      </Typography>
      <Card
        variant="custom"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Stack>
          <CardContent
            sx={{
              px: '12px',
              py: '8px !important'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LocationOnOutlinedIcon
                  fontSize="small"
                  color="primary"
                  sx={{ mr: '8px' }}
                />
                <Typography
                  sx={{ fontSize: '12px' }}
                  variant="caption"
                  lineHeight={'12px'}
                  color={palette.grey[800]}
                  fontWeight={'500'}
                >
                  {addressDataInput?.address}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Stack>
        <CardContent
          sx={{
            px: '16px',
            py: '6px !important',
            backgroundColor: '#f5f5f5',
            borderTop: `solid 1px ${palette.grey[300]}`
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTime
                sx={{
                  color: palette.grey[800],
                  fontSize: '13px',
                  mr: '6px'
                }}
              />
              <Typography
                sx={{ fontSize: '10px' }}
                variant="caption"
                lineHeight={'12px'}
                color={palette.grey[800]}
                fontWeight={'500'}
              >
                Estimativa de entrega: {reseller?.delivery_time}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
}
