import { useTheme, InputAdornment } from '@mui/material';

type Props = {
  error?: boolean;
  success?: boolean;
  children?: React.ReactNode;
};

export default function StartIcon({ children, success, error }: Props) {
  const theme = useTheme();

  const override = {
    '.MuiSvgIcon-root': {
      color: `${(() => {
        if (error) return theme.palette.error.main;
        if (success) return theme.palette.green.main;

        return theme.palette.main;
      })()} !important`
    }
  };

  return (
    <InputAdornment position="start" sx={override}>
      {children}
    </InputAdornment>
  );
}
