import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';

import { useAddresses } from '../../../../hooks/useAddressesContext';

import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

export default function ListAddresses() {
  const theme = useTheme();

  const { chooseAddress, isSearchAddressLoading, addresses } = useAddresses();

  if (isSearchAddressLoading) return <CircularProgress sx={{ mt: '20px' }} />;

  return (
    <Stack width={'100%'} direction={'column'} gap={'16px'}>
      {!!addresses.length && !isSearchAddressLoading && (
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '1.75'
          }}
          variant="h6"
          color="text.primary"
        >
          Endereços encontrados
        </Typography>
      )}

      {!!addresses.length &&
        !isSearchAddressLoading &&
        addresses.map((location) => (
          <Card
            onClick={() => {
              chooseAddress(location);
            }}
            key={location.place_id}
            variant="custom"
            sx={{
              height: '78px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              mb: '12px'
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingY: '0 !important'
              }}
            >
              <RoomOutlinedIcon
                height="20px"
                sx={{
                  userSelect: 'none',
                  width: '1em',
                  height: '1em',
                  display: 'inline-block',
                  fill: 'currentcolor',
                  flexShrink: '0',
                  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  fontSize: '2rem',
                  marginRight: '20px',
                  color: theme.palette.primary.main
                }}
              />

              <Box>
                <Typography
                  variant="subtitle2"
                  color="text.primary"
                  sx={{ fontSize: '1rem' }}
                >
                  {location.structured_formatting.main_text}
                </Typography>

                <Typography
                  sx={{ fontSize: '0.75rem', fontWeight: '400' }}
                  color="text.secondary"
                >
                  {location.structured_formatting.secondary_text}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
    </Stack>
  );
}
