import moengage from '@moengage/web-sdk';

interface MoengageUser {
  userId?: string;
  name?: string;
  phone?: string;
  mainAddress?: string;
}

export function updateMoengageUser(data: MoengageUser) {
  const { name, phone, userId, mainAddress } = data;

  userId && moengage.update_unique_user_id(userId);
  name && moengage.add_first_name(name);
  phone && moengage.add_mobile(phone);
  mainAddress && moengage.add_user_attribute('address', mainAddress);
}
