import React, { useContext, useEffect } from 'react';
import {
  Box,
  CardMedia,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { trackEvent } from '../../config/services/adjustTracking';

import AleloAlert from './components/AleloAlert';
import CreditasAlert from './components/CreditasAlert';
import KaledoAlert from './components/KaledoAlert';
import CredSystemAlert from './components/CredSystemAlert';
import CaixaAlert from './components/CaixaAlert';
import EloAlert from './components/EloAlert';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { useHeader } from 'hooks/useHeaderContext';
import background from './assets/background.png';
import appimage from './assets/app.png';

import { Search } from '@mui/icons-material';
import { useAddresses } from 'hooks/useAddressesContext';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import consigaz from './assets/consigaz.png';
import copagaz from './assets/copagaz.png';
import fogas from './assets/fogas.png';
import liquigas from './assets/liquigas.png';
import nacionalgas from './assets/nacionalgas.png';
import supergasbras from './assets/supergasbras.png';
import ultragaz from './assets/ultragaz.png';

const AddressSearch: React.FC = () => {
  const { integration } = useContext(SourceIntegrationContext);
  const { setIsAddressModalOpen, addressDataInput } = useAddresses();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { setColor, setSubtitle, setTitle, setEditAddress } = useHeader();

  function handleOpenAddressModal() {
    setIsAddressModalOpen(true);
  }

  useEffect(() => {
    setTitle('Compra Web Appgas');
    setSubtitle('Seja bem-vindo(a)');
    setColor(theme.palette.primary.xxdark);
    setEditAddress(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackEvent({
      eventToken: 'Começou a jornada',
      sourceIntegration: integration.source
    });
  }, [integration]);

  useEffect(() => {
    addressDataInput && navigate('/product-choice');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDataInput]);

  return (
    <Box
      sx={{
        height: '100%',
        background: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <Container sx={{ height: '100%' }}>
        <Box
          sx={{
            py: '24px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            gap: '50px'
          }}
        >
          <Stack
            direction={isSmallScreen ? 'column' : 'row'}
            justifyContent={'space-between'}
            gap={isSmallScreen ? '20px' : '50px'}
            alignItems={'center'}
          >
            {isSmallScreen && (
              <CardMedia
                component="img"
                image={appimage}
                sx={{
                  width: '80%',
                  objectFit: 'contain'
                }}
              />
            )}
            <Stack direction={'column'} gap={'20px'}>
              <Stack direction={'column'} gap={'8px'}>
                <Typography
                  variant="h1"
                  fontWeight={isSmallScreen ? '700' : '600'}
                  fontSize={isSmallScreen ? '16px' : '28px'}
                  lineHeight={isSmallScreen ? '24px' : '40px'}
                  textAlign={isSmallScreen ? 'center' : 'left'}
                >
                  Compre seu botijão de gás com menor preço e frete grátis
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={'400'}
                  fontSize={isSmallScreen ? '12px' : '14px'}
                  lineHeight={isSmallScreen ? '16px' : '21px'}
                  color={theme.palette.grey[700]}
                  textAlign={isSmallScreen ? 'center' : 'left'}
                >
                  Informe o endereço que deseja receber seu pedido e realize a
                  compra em poucos cliques.
                </Typography>
              </Stack>
              <Stack
                width={'100%'}
                height={'56px'}
                border={`solid 1px ${theme.palette.grey[300]}`}
                bgcolor={'#fff'}
                borderRadius={'8px'}
                display={'flex'}
                gap={'10px'}
                flexDirection={'row'}
                alignItems={'center'}
                px={'19px'}
                onClick={handleOpenAddressModal}
                sx={{ cursor: 'pointer' }}
              >
                <Search sx={{ color: theme.palette.grey[500] }} />
                <Typography
                  fontWeight={'400'}
                  fontSize={'14px'}
                  sx={{ color: theme.palette.grey[700] }}
                >
                  Digite aqui o endereço de entrega
                </Typography>
              </Stack>
            </Stack>
            {!isSmallScreen && (
              <CardMedia
                component="img"
                image={appimage}
                sx={{
                  width: '600px',
                  objectFit: 'contain'
                }}
              />
            )}
          </Stack>
          <Stack
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'20px'}
          >
            <Typography fontSize={'12px'} fontWeight={'600'}>
              Trabalhamos com as melhores marcas de botijão:
            </Typography>
            {isSmallScreen ? (
              <Stack
                direction={'row'}
                gap={'16px'}
                width={'100%'}
                maxWidth={'600px'}
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap={'nowrap'}
                overflow={'hidden'}
              >
                <Carousel
                  showArrows={false}
                  showThumbs={false}
                  showIndicators={false}
                  showStatus={false}
                  swipeable={true}
                  centerSlidePercentage={13.4}
                  autoPlay={true}
                  centerMode={true}
                  infiniteLoop={true}
                  width={'100%'}
                  emulateTouch={false}
                  swipeScrollTolerance={200}
                >
                  {brands.map((brand, index) => (
                    <div key={index}>
                      <Stack
                        width={'124px'}
                        height={'50px'}
                        bgcolor={'#fff'}
                        borderRadius={'12px'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ cursor: 'pointer' }}
                        onClick={handleOpenAddressModal}
                      >
                        <CardMedia
                          component="img"
                          image={brand.image}
                          sx={{
                            height: brand.height,
                            objectFit: 'contain'
                          }}
                        />
                      </Stack>
                    </div>
                  ))}
                </Carousel>
              </Stack>
            ) : (
              <Stack
                direction={'row'}
                gap={'16px'}
                width={'100%'}
                maxWidth={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap={'wrap'}
                overflow={'hidden'}
              >
                {brands.map((brand, index) => (
                  <div key={index}>
                    <Stack
                      width={'124px'}
                      height={'50px'}
                      bgcolor={'#fff'}
                      borderRadius={'12px'}
                      justifyContent={'center'}
                      sx={{ cursor: 'pointer' }}
                      alignItems={'center'}
                      onClick={handleOpenAddressModal}
                    >
                      <CardMedia
                        component="img"
                        image={brand.image}
                        sx={{
                          height: brand.height,
                          objectFit: 'contain'
                        }}
                      />
                    </Stack>
                  </div>
                ))}
              </Stack>
            )}
          </Stack>
          <AleloAlert />
          <EloAlert />
          <KaledoAlert />
          <CredSystemAlert />
          <CreditasAlert />
          <CaixaAlert />
        </Box>
      </Container>
    </Box>
  );
};

export default AddressSearch;

const brands: { name: string; image: string; height: string }[] = [
  {
    name: 'consigaz',
    image: consigaz,
    height: '18px'
  },
  {
    name: 'ultragaz',
    image: ultragaz,
    height: '18px'
  },
  {
    name: 'copagaz',
    image: copagaz,
    height: '13px'
  },
  {
    name: 'fogas',
    image: fogas,
    height: '18px'
  },
  {
    name: 'liquigas',
    image: liquigas,
    height: '18px'
  },
  {
    name: 'nacionalgas',
    image: nacionalgas,
    height: '13px'
  },
  {
    name: 'supergasbras',
    image: supergasbras,
    height: '13px'
  }
];
