import { createContext, Dispatch, SetStateAction } from 'react';

export interface ICoupon {
  label: string;
  discountText?: string;
}

export interface ICheckoutContextData {
  cpf: string;
  firstName: string;
  lastName: string;
  change: string;
  coupon: ICoupon;
  paymentId: string;
  cardNumber: string;
  cardholderName: string;
  validate: string;
  securityCode: string;
  formIsValid: boolean;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setFormIsValid: Dispatch<SetStateAction<boolean>>;
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setValidate: Dispatch<SetStateAction<string>>;
  setCardNumber: Dispatch<SetStateAction<string>>;
  setCardholderName: Dispatch<SetStateAction<string>>;
  setSecurityCode: Dispatch<SetStateAction<string>>;
  setCpf: Dispatch<SetStateAction<string>>;
  setChange: Dispatch<SetStateAction<string>>;
  setCoupon: Dispatch<SetStateAction<ICoupon>>;
  setPaymentId: Dispatch<SetStateAction<string>>;
}

export const CheckoutContext = createContext<ICheckoutContextData>(
  {} as ICheckoutContextData
);
