import { createContext } from 'react';
import { Reseller } from 'types';

export interface ResellerContextData {
  reseller: Reseller | null;
  setReseller: (input: Reseller | null) => void;
}

export const ResellerContext = createContext<ResellerContextData>(
  {} as ResellerContextData
);
