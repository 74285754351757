import { formatPrice } from '../../../../helpers/formatPrice';

import CouponModal from '../CouponModal';
import { useTheme, Box, Typography, Skeleton } from '@mui/material';
import { ISummaryData } from 'views/Review/types/ISummaryData';

import { useState } from 'react';

type Props = {
  summary: ISummaryData;
  isSummaryLoading?: boolean;
};

export default function SummaryContent({ summary, isSummaryLoading }: Props) {
  const theme = useTheme();

  const [modalCouponOpen, setModalCouponOpen] = useState(false);

  const {
    coupon_discount_text,
    delivery_fee,
    discount,
    promotion,
    promotion_text,
    subtotal,
    total,
    service_tax
  } = summary;

  return (
    <>
      <Box
        sx={{
          mb: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: '0.75rem', color: theme.palette.grey['600'] }}
          >
            Custo dos produtos
          </Typography>
        </Box>
        <Box>
          {isSummaryLoading ? (
            <Skeleton variant="text" width={'100px'} />
          ) : (
            <Typography
              sx={{ fontSize: '0.75rem', color: theme.palette.grey['700'] }}
              variant="subtitle2"
              fontWeight={'700'}
            >
              {subtotal && formatPrice(subtotal)}
            </Typography>
          )}
        </Box>
      </Box>
      {Boolean(service_tax) && service_tax && service_tax > 0 && (
        <Box
          sx={{
            mb: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: '0.75rem', color: theme.palette.grey['600'] }}
            >
              Taxa de serviço
            </Typography>
          </Box>
          <Box>
            {isSummaryLoading ? (
              <Skeleton variant="text" width={'100px'} />
            ) : (
              <Typography
                sx={{ fontSize: '0.75rem', color: theme.palette.grey['700'] }}
                variant="subtitle2"
                fontWeight={'700'}
              >
                {formatPrice(service_tax)}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {!!promotion && (
        <Box
          sx={{
            mb: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '0.75rem',
                color: theme.palette.grey['600']
              }}
            >
              Desconto {promotion_text}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: theme.palette.green.main,
                fontWeight: theme.typography.fontWeightBold
              }}
            >
              {isSummaryLoading ? (
                <Skeleton variant="text" width={'100px'} />
              ) : (
                formatPrice(promotion)
              )}
            </Typography>
          </Box>
        </Box>
      )}

      {!!discount && (
        <Box
          sx={{
            mb: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: '0.75rem',
                color: theme.palette.grey['600']
              }}
            >
              Cupom ({coupon_discount_text})
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: theme.palette.green.main,
                fontWeight: theme.typography.fontWeightBold
              }}
            >
              {isSummaryLoading ? (
                <Skeleton variant="text" width={'100px'} />
              ) : (
                formatPrice(discount)
              )}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          mb: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: '0.75rem', color: theme.palette.grey['600'] }}
          >
            Taxa de entrega
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '0.75rem',
              color: theme.palette.green.main,
              fontWeight: theme.typography.fontWeightBold
            }}
          >
            {isSummaryLoading ? (
              <Skeleton variant="text" width={'100px'} />
            ) : (
              (delivery_fee && parseFloat(delivery_fee)) || `Grátis`
            )}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '14px',
              color: theme.palette.grey[900]
            }}
          >
            Total
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '14px',
              color: theme.palette.grey[900]
            }}
          >
            {isSummaryLoading ? (
              <Skeleton variant="text" width={'100px'} />
            ) : total ? (
              formatPrice(total)
            ) : (
              'R$ 0,00'
            )}
          </Typography>
        </Box>
      </Box>

      <CouponModal
        title="Alterar cupom"
        open={modalCouponOpen}
        onClose={() => setModalCouponOpen(false)}
      />
    </>
  );
}
