import { ReactNode, useState } from 'react';
import {
  ChosenProductsContext,
  IProductsState
} from './chosen-products-contex';
import { useNavigate } from 'react-router-dom';
import { eventChooseProduct } from 'config/services/gtmEvents';

interface ChosenProductsProviderProps {
  children: ReactNode;
}

export function ChosenProductsProvider({
  children
}: ChosenProductsProviderProps) {
  const [chosenProducts, setChosenProducts] = useState<IProductsState[]>([]);
  const navigate = useNavigate();

  function handleProductQuantity(
    product: IProductsState,
    action: 'add' | 'sub'
  ) {
    const actions = {
      add: (value: number) => ++value,
      sub: (value: number) => (value > 0 ? --value : value)
    };

    const chosenProductsUpdated = chosenProducts.length
      ? chosenProducts.map((p) => {
          if (p.id === product.id) {
            if (
              action === 'sub' &&
              p.quantity === 1 &&
              chosenProducts.length === 1
            )
              navigate('/product-choice');

            return { ...p, quantity: actions[action](p.quantity) };
          }

          return p;
        })
      : [];

    const productAlreadyChosen =
      chosenProducts.length && chosenProducts.find((p) => p.id === product.id);

    if (!productAlreadyChosen)
      chosenProductsUpdated.push({ ...product, quantity: 1 });

    setChosenProducts(
      chosenProductsUpdated.filter((item) => item.quantity > 0)
    );
    const chosenProcutsString = chosenProductsUpdated
      .map((p) => p.name + ': ' + p.quantity)
      .join(', ');
    eventChooseProduct([product], action, chosenProcutsString);
  }

  return (
    <ChosenProductsContext.Provider
      value={{ chosenProducts, setChosenProducts, handleProductQuantity }}
    >
      {children}
    </ChosenProductsContext.Provider>
  );
}
