import { useState, ReactNode } from 'react';

import { CheckoutContext, ICoupon } from './CheckoutContext';

type Props = {
  children: ReactNode;
};

export function CheckoutProvider({ children }: Props) {
  const [change, setChange] = useState('');

  /** Only is payment of type Jeitto */
  const [cpf, setCpf] = useState('');

  /** Only is payment of type Alelo */
  const [cardNumber, setCardNumber] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [validate, setValidate] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [formIsValid, setFormIsValid] = useState(true);
  const [email, setEmail] = useState('');

  const [paymentId, setPaymentId] = useState('');

  const [coupon, setCoupon] = useState<ICoupon>({} as ICoupon);

  return (
    <CheckoutContext.Provider
      value={{
        email,
        cpf,
        lastName,
        firstName,
        cardNumber,
        cardholderName,
        validate,
        securityCode,
        change,
        coupon,
        formIsValid,
        setEmail,
        setFormIsValid,
        setCpf,
        setFirstName,
        setLastName,
        setCardholderName,
        setCardNumber,
        setValidate,
        setSecurityCode,
        setChange,
        paymentId,
        setCoupon,
        setPaymentId
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}
