import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import FormSaveAddress from './components/FormSaveAddress';
import { useAddresses } from 'hooks/useAddressesContext';

export default function SaveAddress() {
  const navigate = useNavigate();
  const { setFormAddressDataInput, formAddressDataInput } = useAddresses();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!formAddressDataInput) return setFormAddressDataInput(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formAddressDataInput, navigate]);

  const latitude = formAddressDataInput?.latitude || formAddressDataInput?.lat;
  const longitude =
    formAddressDataInput?.longitude || formAddressDataInput?.long;

  return (
    <Stack
      px={isSmallScreen ? '16px' : '40px'}
      direction={'column'}
      justifyContent={' center'}
      alignItems={'center'}
      gap={'20px'}
      pb={'60px'}
    >
      <Typography
        variant="h6"
        fontSize={'16px'}
        textAlign={'center'}
        lineHeight={'20px'}
      >
        Em qual endereço você quer receber seu botijão?
      </Typography>

      {latitude && longitude && (
        <Box
          height={isSmallScreen ? '90px' : '200px'}
          width={'100%'}
          overflow={'hidden'}
          display="flex"
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            boxShadow: `0px 2px 8px 0px #0000002e`
          }}
        >
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=16&size=592x246&key=${process.env.REACT_APP_GOOGLE_MAPS_ID}&markers=size:mid%7Ccolor:red%7C${latitude},${longitude}`}
            style={{ border: 0 }}
          />
        </Box>
      )}

      <FormSaveAddress />
    </Stack>
  );
}
