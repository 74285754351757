import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';

interface SendCodeAppUserUseCaseProps {
  gateway: string;
  phone: string;
}

export class SendCodeAppUserUseCase {
  private api = appGasService;

  async handle(props: SendCodeAppUserUseCaseProps) {
    const { gateway, phone } = props;

    const payload = {
      phone,
      gateway
    };

    const { data } = await this.api.post<unknown, AxiosResponse>(
      `/v2/integrations/appuser/send_code`,
      payload
    );

    return data;
  }
}
