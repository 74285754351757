import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ResellerBadge } from 'components/ResellerBadge/reseller-badge';
import { useState } from 'react';
import { ResellerBadgeModel } from 'types';

interface ResellerBadgeListProps {
  badges: ResellerBadgeModel[];
}

export function ResellerBadgeList(props: ResellerBadgeListProps) {
  const { badges } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();

  if (badges.length <= 0) {
    return null;
  }

  return (
    <Stack
      direction={'row'}
      gap={'6px'}
      justifyContent={'space-between'}
      width={'100%'}
      alignItems={'flex-start'}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Stack
        direction={'row'}
        gap={'6px'}
        width={'100%'}
        maxWidth={'80%'}
        height={'fit-content'}
        flexWrap={'wrap'}
      >
        {badges.length <= 2 &&
          badges.map((badge, index) => (
            <ResellerBadge key={index} badge={badge} />
          ))}
        {badges.length > 2 && !isOpen && (
          <>
            <ResellerBadge badge={badges[0]} />
            <ResellerBadge badge={badges[1]} />
            <Box
              p={'2px 4px'}
              sx={{ backgroundColor: theme.palette.grey[200] }}
              borderRadius={'4px'}
            >
              <Typography
                fontSize={'10px'}
                fontWeight={'600'}
                sx={{ color: theme.palette.grey[800] }}
              >
                +{badges.length - 2}
              </Typography>
            </Box>
          </>
        )}
        {badges.length > 2 &&
          isOpen &&
          badges.map((badge, index) => (
            <ResellerBadge key={index} badge={badge} />
          ))}
      </Stack>
      {badges.length > 2 && isOpen && (
        <KeyboardArrowUp sx={{ mt: '-2px', color: theme.palette.grey[600] }} />
      )}
      {badges.length > 2 && !isOpen && (
        <KeyboardArrowDown
          sx={{ mt: '-2px', color: theme.palette.grey[600] }}
        />
      )}
    </Stack>
  );
}
