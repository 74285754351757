import { Box, Typography, Link, useTheme, useMediaQuery } from '@mui/material';

interface IProps {
  terms?: string;
}

export function Footer({ terms = 'cliente' }: IProps) {
  const theme = useTheme();
  const { palette } = theme;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        marginBottom: { md: '10px' }
      }}
    >
      <Typography
        sx={{
          fontSize: isSmallScreen ? '12px' : '14px',
          margin: '24px 0',
          lineHeight: isSmallScreen ? '16px' : '21px',
          fontWeight: 'normal'
        }}
      >
        Ao clicar em Avançar, você concorda com nossos{' '}
        <Link
          sx={{
            color: palette.primary.xdark,
            fontWeight: 'bold',
            textDecoration: 'none'
          }}
          href={`https://dashboard.appgas.com/termos_${terms}.html`}
          target="_blank"
          rel="noopener"
        >
          Termos e Condições
        </Link>{' '}
        e{' '}
        <Link
          sx={{
            color: palette.primary.xdark,
            fontWeight: 'bold',
            textDecoration: 'none'
          }}
          href={`https://dashboard.appgas.com/termos_${terms}.html`}
          target="_blank"
          rel="noopener"
        >
          Políticas de Privacidade
        </Link>
        .
      </Typography>
    </Box>
  );
}
