import {
  Box,
  Card,
  useTheme,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography
} from '@mui/material';
import { Container } from '../../components/Container';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddMoreProductsModal from './components/AddMoreProductsModal';
import { useContext, useEffect, useState } from 'react';
import ChoiceRellesers from './components/ChoiceResellers';
import { useNavigate } from 'react-router-dom';
import { useHeader } from 'hooks/useHeaderContext';
import { eventChooseProduct } from 'config/services/gtmEvents';
import { useAddresses } from 'hooks/useAddressesContext';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';

interface IProductsState {
  id: string;
  name: string;
  desc: string;
  picture: string;
  price: number;
  quantity: number;
}

export default function ProductChoice() {
  const theme = useTheme();

  const { setSubtitle, setTitle, setEditAddress } = useHeader();

  const [modalOpen, setModalOpen] = useState(false);

  const { addressDataInput } = useAddresses();
  const { chosenProducts, setChosenProducts } = useContext(
    ChosenProductsContext
  );

  const address = addressDataInput;

  const navigate = useNavigate();

  useEffect(() => {
    if (chosenProducts.length <= 0) {
      navigate('/product-choice');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    address &&
      setTitle(
        `${
          address.street
            ? `${address.street}, `
            : address?.address
            ? `${address.address.split(',')[0]}, `
            : ''
        }${address.number}`
      );
    setSubtitle('Endereço de entrega');
    setEditAddress(true);
  }, [address, setTitle, setSubtitle, setEditAddress]);

  function handleProductQuantity(
    product: IProductsState,
    action: 'add' | 'sub'
  ) {
    const actions = {
      add: (value: number) => ++value,
      sub: (value: number) => (value > 0 ? --value : value)
    };

    const chosenProductsUpdated = chosenProducts.length
      ? chosenProducts.map((p) => {
          if (p.id === product.id) {
            if (
              action === 'sub' &&
              p.quantity === 1 &&
              chosenProducts.length === 1
            )
              navigate('/product-choice');

            return { ...p, quantity: actions[action](p.quantity) };
          }

          return p;
        })
      : [];

    const productAlreadyChosen =
      chosenProducts.length && chosenProducts.find((p) => p.id === product.id);

    if (!productAlreadyChosen)
      chosenProductsUpdated.push({ ...product, quantity: 1 });

    setChosenProducts(
      chosenProductsUpdated.filter((item) => item.quantity > 0)
    );
    const chosenProcutsString = chosenProductsUpdated
      .map((p) => p.name + ': ' + p.quantity)
      .join(', ');

    eventChooseProduct([product], action, chosenProcutsString);
  }

  return (
    <>
      <Box sx={{ background: '#FFF', pb: '12px', pt: '32px' }}>
        <Container>
          <Box
            sx={{
              mb: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography>Produtos selecionados</Typography>
            <Typography
              onClick={() => setModalOpen(true)}
              sx={{
                fontWeight: 600,
                cursor: 'pointer',
                fontSize: '0.75rem',
                color: theme.palette.primary.main
              }}
            >
              + Adicionar outros produtos
            </Typography>
          </Box>

          <Box>
            {!!chosenProducts.length &&
              chosenProducts.map((product) => (
                <Card
                  key={product.id}
                  variant="custom"
                  sx={{
                    height: '86px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: '8px',
                    p: '16px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={product.picture}
                      alt="Live from space album cover"
                      sx={{ height: 44, width: 27.5, objectFit: 'contain' }}
                    />
                    <CardContent
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingY: '0 !important'
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle2">
                          {product.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '0.75rem',
                            color: theme.palette.text.secondary
                          }}
                        >
                          {product.desc}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Box>

                  <CardActions>
                    <IconButton
                      sx={{
                        mr: '8px',
                        width: '22px',
                        height: '22px',
                        border: '1px solid #E0E0E0',
                        '&:hover': {
                          backgroundColor: '#9E9E9E',
                          border: '1px solid #9E9E9E'
                        }
                      }}
                      onClick={() => handleProductQuantity(product, 'sub')}
                    >
                      <RemoveIcon sx={{ fontSize: '16px', color: '#E0E0E0' }} />
                    </IconButton>

                    <Typography>{product.quantity}</Typography>

                    <IconButton
                      sx={{
                        m: 0,
                        width: '22px',
                        height: '22px',
                        backgroundColor: '#4CB840',
                        '&:hover': {
                          backgroundColor: '#62c157'
                        }
                      }}
                      onClick={() => handleProductQuantity(product, 'add')}
                    >
                      <AddIcon sx={{ fontSize: '16px', color: '#FFFF' }} />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
          </Box>

          {!chosenProducts.length && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontSize: '0.85rem',
                  color: theme.palette.error.main
                }}
              >
                Nenhum produto foi escolhido
              </Typography>
            </Box>
          )}
        </Container>
      </Box>

      <AddMoreProductsModal
        open={modalOpen}
        productsAlreadyChosen={chosenProducts.map((product) => product.id)}
        onClose={() => setModalOpen(false)}
        handleProductQuantity={handleProductQuantity}
      />

      <ChoiceRellesers products={chosenProducts} />
    </>
  );
}
