import { toast as reactToastify } from 'react-toastify';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

type ToastProps = {
  message: string;
  type: 'success' | 'error';
};

export function gasToast({ message, type }: ToastProps) {
  const icon =
    type === 'success' ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />;

  reactToastify[type](message, {
    autoClose: 5000,
    draggable: true,
    theme: 'colored',
    closeOnClick: true,
    pauseOnHover: true,
    closeButton: false,
    hideProgressBar: false,
    position: 'bottom-center',
    icon: () => icon
  });
}
