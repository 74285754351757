import { ReactNode, useEffect, useState } from 'react';
import { LeadDataContext } from './lead-data.context';
import { LeadInputData } from 'views/CreateLead/types';
import { readStorage, writeStorage } from 'hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { ValidateTokenAppUserUseCase } from 'use-cases/appuser/validate-token-appuser.use-case';

interface LeadDataProviderProps {
  children: ReactNode;
}

export function LeadDataProvider({ children }: LeadDataProviderProps) {
  const customerId = readStorage('customer_id');
  const customerToken = readStorage('customer_token');
  const customerPhone = readStorage('customer_phone');
  const [leadData, setLeadData] = useState<LeadInputData>({} as LeadInputData);
  const [temporaryPhone, setTemporaryPhone] = useState<string>('');
  const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(false);

  const navigate = useNavigate();

  async function getUserData() {
    setIsLoadingUserData(true);

    await new ValidateTokenAppUserUseCase()
      .handle({
        auth_token: customerToken,
        phone: customerPhone
      })
      .then((data) => {
        setLeadData(data);
      })
      .catch((e) => {
        console.log('loginErr', e);
      })
      .finally(() => {
        setIsLoadingUserData(false);
      });
  }

  useEffect(() => {
    if (customerToken && customerId && customerPhone) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function logoutUser() {
    setLeadData({} as LeadInputData);
    writeStorage('customer_token', '');
    writeStorage('customer_id', '');
    writeStorage('customer_phone', '');
    writeStorage('addressDataInput', '');
    navigate('/');
  }

  return (
    <LeadDataContext.Provider
      value={{
        leadData,
        temporaryPhone,
        isLoadingUserData,
        setLeadData,
        logoutUser,
        setTemporaryPhone
      }}
    >
      {children}
    </LeadDataContext.Provider>
  );
}
