/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useAddresses } from '../../../../hooks/useAddressesContext';
import { trackEvent } from 'config/services/adjustTracking';
import { eventChooseAddress } from 'config/services/gtmEvents';
import { ExploreOutlined } from '@mui/icons-material';
import { useContext } from 'react';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';

export default function CurrentLocation() {
  const theme = useTheme();
  const { integration } = useContext(SourceIntegrationContext);
  const { currentLocation, getCurrentGeolocationByBrowser, chooseAddress } =
    useAddresses();

  const sourceIntegration = integration.source;

  function handleClick() {
    if (!currentLocation?.data?.formatted_address) {
      trackEvent({
        eventToken: 'Ativou a localização',
        sourceIntegration
      });
      getCurrentGeolocationByBrowser();
      eventChooseAddress();
    } else {
      chooseAddress({
        description: currentLocation.data?.formatted_address || '',
        place_id: currentLocation.data?.place_id || '',
        structured_formatting: {
          main_text: currentLocation.data?.formatted_address || '',
          secondary_text: currentLocation.data?.formatted_address || ''
        }
      });
    }
    eventChooseAddress();
  }

  return (
    <Stack
      direction={'row'}
      justifyContent={'center'}
      gap={'16px'}
      alignItems={'center'}
      border={`solid 1px ${
        currentLocation.data
          ? theme.palette.primary.main
          : theme.palette.grey[300]
      }`}
      width={'100%'}
      p={'8px'}
      borderRadius={'8px'}
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      {currentLocation.data ? (
        <Stack direction={'row'} gap={'16px'} alignItems={'center'} px={'10px'}>
          <MyLocationIcon color="primary" sx={{ fontSize: '22px' }} />
          <Stack direction={'column'}>
            <Typography variant="body1" fontWeight={'600'} fontSize={'14px'}>
              Usar minha localização atual
            </Typography>
            <Typography
              sx={{
                color: theme.palette.grey['600'],
                fontWeight: theme.typography.fontWeightLight,
                lineHeight: '18px',
                fontSize: '14px'
              }}
            >
              {currentLocation.data.formatted_address}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <>
          {currentLocation.loading ? (
            <CircularProgress color="primary" size="20px" />
          ) : (
            <ExploreOutlined color="primary" />
          )}

          {currentLocation.loading && !currentLocation.error ? (
            <Typography
              variant="body1"
              fontWeight={'500'}
              fontSize={'14px'}
              sx={{ color: theme.palette.grey['600'] }}
            >
              Buscando localização atual...
            </Typography>
          ) : currentLocation.error && !currentLocation.loading ? (
            <Typography
              variant="body1"
              fontWeight={'600'}
              fontSize={'14px'}
              color="error"
            >
              {currentLocation.error}
            </Typography>
          ) : (
            <Typography variant="body1" fontWeight={'600'} fontSize={'14px'}>
              Usar minha localização atual
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
}
