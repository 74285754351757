import { Box, Typography, useTheme } from '@mui/material';
import { Container } from '../../components/Container';
import FormCreateLead from './components/FormCreateLead';
import { Stepper } from '../../components/Stepper';
import { useEffect } from 'react';
import { useHeader } from 'hooks/useHeaderContext';
import { useAddresses } from 'hooks/useAddressesContext';

export default function CreateLead() {
  const theme = useTheme();

  const { setSubtitle, setTitle, setEditAddress } = useHeader();

  const { addressDataInput } = useAddresses();

  const address = addressDataInput;

  useEffect(() => {
    if (address) {
      address &&
        setTitle(
          `${
            address.street
              ? `${address.street}, `
              : address?.address
              ? `${address.address.split(',')[0]}, `
              : ''
          }${address.number}`
        );
      setSubtitle('Endereço de entrega');
      setEditAddress(true);
    } else {
      setTitle(`Bem-vindo de volta ao Appgas`);
      setEditAddress(false);
    }
  }, [address, setTitle, setSubtitle, setEditAddress]);

  return (
    <Container>
      <Box
        sx={{
          mt: '2rem',
          backgroundColor: 'white',
          padding: '15px',
          borderRadius: '8px',
          border: '1px solid #e0e0e0'
        }}
      >
        <Stepper
          totalSteps={3}
          currentStep={1}
          title={'Dados pessoais'}
          showStepInfo={true}
        />
      </Box>
      <Box>
        <Typography
          sx={{ my: '16px', fontWeight: theme.typography.fontWeightLight }}
        >
          Preencha seus dados
        </Typography>
      </Box>
      <FormCreateLead />
    </Container>
  );
}
