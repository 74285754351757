import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useContext, useState } from 'react';
import { formatPrice } from 'helpers/formatPrice';
import { OrderContext } from 'providers/order-context/order.context';
import { CreditCard } from '@mui/icons-material';
import { MoneyIcon } from 'components/Icons/MoneyIcon';
import HelpIcon from '@mui/icons-material/Help';
import { Close as CloseIcon } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

export function CartProductsList() {
  const { palette } = useTheme();
  const { order } = useContext(OrderContext);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <Stack direction={'column'} gap={'8px'}>
      <Typography fontSize={'14px'} fontWeight={'600'}>
        Pedido #{order?.order_number}:
      </Typography>
      <Card
        variant="custom"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Stack>
          <CardContent
            sx={{
              px: '16px',
              py: '8px !important',
              backgroundColor: '#f5f5f5'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Typography
                  sx={{ fontSize: '12px' }}
                  variant="caption"
                  lineHeight={'18px'}
                  color={palette.grey[600]}
                  fontWeight={'500'}
                >
                  {order?.reseller.display_name}
                </Typography>
                <Typography
                  sx={{ fontSize: '12px' }}
                  variant="caption"
                  lineHeight={'15px'}
                  color={palette.grey[900]}
                  fontWeight={'500'}
                  alignItems={'center'}
                  display={'flex'}
                >
                  Total:
                  <Typography
                    sx={{ fontSize: '12px' }}
                    variant="caption"
                    lineHeight={'15px'}
                    color={palette.grey[900]}
                    fontWeight={'700'}
                    ml={'3px'}
                  >
                    {order?.total && formatPrice(order?.total)}
                  </Typography>
                  <HelpIcon
                    onClick={() => setIsSummaryModalOpen(true)}
                    sx={{
                      color: '#757575',
                      fontSize: '13px',
                      ml: '6px',
                      cursor: 'pointer'
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Stack>
        <CardContent
          sx={{
            px: '16px',
            py: '16px !important',
            borderTop: `solid 1px ${palette.grey[300]}`,
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'
          }}
        >
          {!!order?.products?.length &&
            order.products.map((product) => (
              <Stack
                key={product.id}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    <CardMedia
                      component="img"
                      image={product.picture}
                      alt="Live from space album cover"
                      sx={{ height: 28, width: 20, objectFit: 'contain' }}
                    />
                    <CardContent
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingY: '0 !important'
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle2" lineHeight={'20px'}>
                          {product.quantity}x {product.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '10px',
                            fontWeight: '600',
                            lineHeight: '14px',
                            color: palette.grey[600]
                          }}
                        >
                          {formatPrice(product.unit_price * product.quantity)}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Stack>
                  <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    bgcolor={product?.brand_color}
                    px={'5px'}
                    py={'3px'}
                    borderRadius={'3px'}
                    width={'fit-content'}
                    mb={'4px'}
                  >
                    <Typography
                      fontSize={'8px'}
                      fontWeight={'700'}
                      lineHeight={'9px'}
                      color={'#fff'}
                    >
                      {product?.brand_name}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            ))}
        </CardContent>
        <CardContent
          sx={{
            px: '16px',
            py: '6px !important',
            backgroundColor: '#f5f5f5',
            borderTop: `solid 1px ${palette.grey[300]}`
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                {order?.payment_method.is_money && (
                  <MoneyIcon
                    height={11}
                    width={15}
                    color={theme.palette.primary.main}
                  />
                )}
                {!order?.payment_method.is_money && (
                  <CreditCard
                    sx={{
                      height: '13px',
                      width: '15px',
                      color: theme.palette.primary.main
                    }}
                  />
                )}
                <Typography
                  sx={{ fontSize: '10px' }}
                  variant="caption"
                  lineHeight={'12px'}
                  color={palette.grey[800]}
                  fontWeight={'500'}
                >
                  {order?.payment_method.name}{' '}
                  {order?.payment_method.is_money && order.change
                    ? `- Troco para ${formatPrice(order.change)}`
                    : order?.payment_method.is_money && !order.change
                    ? '- Não precisa de troco'
                    : ''}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <Dialog
        open={isSummaryModalOpen}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setIsSummaryModalOpen(false)}
        sx={{ padding: '24px' }}
      >
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                color: '#3968A6',
                fontSize: '22px',
                mb: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px'
                }
              }}
            >
              <span style={{ color: theme.palette.primary.main }}>
                Resumo do pedido
              </span>
              <CloseIcon
                sx={{ cursor: 'pointer', color: '#BDBDBD' }}
                onClick={() => setIsSummaryModalOpen(false)}
              />
            </Box>
            <Stack
              sx={{
                p: '12px 16px',
                border: `solid 1px ${grey[300]}`,
                borderRadius: '0px 0px 8px 8px '
              }}
              direction={'column'}
              gap={'8px'}
            >
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography
                  color={grey[600]}
                  fontSize={'12px'}
                  fontWeight={'600'}
                >
                  Custo dos produtos
                </Typography>
                <Typography
                  color={grey[700]}
                  fontSize={'12px'}
                  fontWeight={'700'}
                >
                  {order && formatPrice(order.price)}
                </Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography
                  color={grey[600]}
                  fontSize={'12px'}
                  fontWeight={'600'}
                >
                  Taxa de serviço
                </Typography>
                {order && order.service_tax ? (
                  <Typography
                    color={grey[700]}
                    fontSize={'12px'}
                    fontWeight={'700'}
                  >
                    {order.service_tax}
                  </Typography>
                ) : (
                  <Typography
                    color={'green.main'}
                    fontSize={'12px'}
                    fontWeight={'700'}
                  >
                    Grátis
                  </Typography>
                )}
              </Stack>
              {order && Boolean(order.discount) && (
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography
                    color={grey[600]}
                    fontSize={'12px'}
                    fontWeight={'600'}
                  >
                    Cupom de desconto
                  </Typography>
                  <Typography
                    color={'green.main'}
                    fontSize={'12px'}
                    fontWeight={'700'}
                  >
                    {order && formatPrice(order.discount)}
                  </Typography>
                </Stack>
              )}
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography
                  color={grey[600]}
                  fontSize={'12px'}
                  fontWeight={'600'}
                >
                  Taxa de entrega
                </Typography>
                <Typography
                  color={'green.main'}
                  fontSize={'12px'}
                  fontWeight={'700'}
                >
                  Grátis
                </Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography
                  color={grey[900]}
                  fontSize={'14px'}
                  fontWeight={'700'}
                >
                  Total
                </Typography>
                <Typography
                  color={grey[900]}
                  fontSize={'14px'}
                  fontWeight={'700'}
                >
                  {order && formatPrice(order.total)}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
