import { ReactNode, useState } from 'react';
import { ResellerContext } from './reseller-context';
import { Reseller } from 'types';

interface ResellerProviderProps {
  children: ReactNode;
}

export function ResellerProvider({ children }: ResellerProviderProps) {
  const [reseller, setReseller] = useState<Reseller | null>(null);

  return (
    <ResellerContext.Provider value={{ reseller, setReseller }}>
      {children}
    </ResellerContext.Provider>
  );
}
