import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #F5F5F5;
    --primary-main: #3968A6;
    --primary-dark: #1C3B77;

    --toastify-color-success: #EFFFE3;
    --toastify-color-error: #FFEFEA;
    --toastify-text-color-error: #7D0F3D;
    --toastify-text-color-success: #146A20;
    --toastify-color-progress-success: #4CB840;
    --toastify-color-progress-error: #E76167;
  }

  .Toastify__toast--success {
    font-family: 'Poppins';
    font-weight: 600;
  }

  .Toastify__toast--error {
    font-family: 'Poppins';
    font-weight: 600;
  }

  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
    background: var(--toastify-color-progress-success);
  }

  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
    background: var(--toastify-color-progress-error);
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-witdh: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
