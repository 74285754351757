import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { LeadInputData } from 'views/CreateLead/types';

interface CheckCodeAppUserUseCaseProps {
  code: string;
  phone: string;
}

export class CheckCodeAppUserUseCase {
  private api = appGasService;

  async handle(props: CheckCodeAppUserUseCaseProps) {
    const { code, phone } = props;

    const payload = {
      phone,
      code
    };

    const { data } = await this.api.post<unknown, AxiosResponse<LeadInputData>>(
      `/v2/integrations/appuser/check_code`,
      payload
    );

    return data;
  }
}
