import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { Order } from 'types';

export class FinishOrderUseCase {
  private api = appGasService;

  async handle(orderId: string) {
    const { data } = await this.api.post<unknown, AxiosResponse<Order>>(
      `/v2/integrations/orders/${orderId}/finish`
    );

    return data;
  }
}
