import * as S from './style';

import { throttle } from '../../helpers/throttle';
import { eventFinishAddress } from 'config/services/gtmEvents';

type Props = {
  nextAction?: () => void;
  disableNextButton?: boolean;
  previousActionText?: string;
  nextActionType?: 'button' | 'submit';
  previousActionButtonColor?: string;
  previousActionButtonTextColor?: string;
  previousAction?: (args: React.FormEvent) => void;
  onFinishAddress?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
};

export default function PreviousAndNextButtons({
  nextAction,
  previousAction,
  previousActionText,
  nextActionType = 'button',
  disableNextButton = false,
  previousActionButtonColor,
  previousActionButtonTextColor,
  onFinishAddress = 'false',
  isLoading,
  children
}: Props) {
  function handleClickNext() {
    if (onFinishAddress === 'true') {
      eventFinishAddress();
    }
    return nextAction && nextAction ? throttle(nextAction(), 5000) : nextAction;
  }

  return (
    <S.Container>
      {children}
      <S.GroupButtons>
        <S.PreviousButton
          type="button"
          variant="outlined"
          onClick={previousAction}
        >
          Voltar
        </S.PreviousButton>
        <S.NextButton
          variant="contained"
          type={nextActionType}
          onClick={handleClickNext}
          disabled={disableNextButton || isLoading}
          previousActionButtonColor={previousActionButtonColor}
          previousActionButtonTextColor={previousActionButtonTextColor}
        >
          {isLoading
            ? 'Aguarde...'
            : !previousActionText
            ? 'Avançar'
            : previousActionText}
        </S.NextButton>
      </S.GroupButtons>
    </S.Container>
  );
}
