import React from 'react';
import { Typography } from '@mui/material';
import { Container } from '../../components/Container';

const CrashApp: React.FC = () => {
  return (
    <Container>
      <Typography sx={{ my: '24px' }}>
        Infelizmente, algo de errado aconteceu! Por favor, tente mais tarde.
      </Typography>
    </Container>
  );
};

export default CrashApp;
