import {
  styled,
  Box as MUIBox,
  Typography as MUITypography
} from '@mui/material';

export const Description = styled(MUITypography)(
  ({ theme }) => `
  margin-top: 6px;
  margin-left: 8px;
  margin-bottom: 32px;
  color: ${theme.palette.grey[600]};
  font-weight: ${theme.typography.fontWeightLight}
`
);

export const StyledError = styled(MUIBox)`
  margin-top: 13px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.004em;
  color: ${(props) => props.theme.palette.error.main};
`;

type InputVerificationCodeProp = {
  isInvalid: boolean;
};

export const StyledReactInputVerificationCode = styled(
  MUIBox
)<InputVerificationCodeProp>`
  display: flex;
  justify-content: flex-start;

  --ReactInputVerificationCode-itemWidth: 56px;
  --ReactInputVerificationCode-itemHeight: 56px;
  --ReactInputVerificationCode-itemSpacing: 8px;

  .ReactInputVerificationCode__item {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) =>
      props.isInvalid
        ? props.theme.palette.error.main
        : props.theme.palette.grey[900]};
    background: #ffff;
    border: 2px solid
      ${(props) =>
        props.isInvalid
          ? props.theme.palette.error.main
          : props.theme.palette.grey[300]};
    border-radius: 8px;
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;
