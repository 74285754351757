import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { toPattern } from 'vanilla-masker';

import CustomInput from 'components/CustomInput';
import { FormTitle } from './styles';
import PreviousAndNextButtons from 'components/PreviousAndNextButtons';
import { Box, Divider } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import { useNavigate } from 'react-router-dom';
import { LeadInputData } from 'views/CreateLead/types';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { appGasService } from 'config/services/appgas';
import { toast } from 'react-toastify';
import { AddressInputData } from 'views/SaveAddress/types';
import { trackEvent } from 'config/services/adjustTracking';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';

export default function FormCNPJ() {
  const navigate = useNavigate();

  const [cnpjInput, setCnpjInput] = useState('');
  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');

  const [leadDataInput, setLeadDataInput] = useLocalStorage<LeadInputData>(
    'leadData',
    {} as LeadInputData
  );

  const [address, setAddressDataInput] = useLocalStorage<AddressInputData>(
    'addressData',
    {} as AddressInputData
  );

  const { integration } = useContext(SourceIntegrationContext);

  const sourceIntegration = integration.source;
  const source_integration = integration.source;

  function formatCNPJ(value: string) {
    if (value) {
      return toPattern(value, '99.999.999/9999-99');
    }

    return value;
  }

  async function handleSubmit(e: React.FormEvent) {
    try {
      e.preventDefault();

      const schema = Yup.object().shape({
        name: Yup.string().required('O campo nome da empresa é obrigatório'),
        phone: Yup.string().test(
          'len',
          'O campo telefone deve ter entre 10-11 caracteres',
          (val) => {
            if (val == undefined) return true;

            return val.length == 0 || (val.length >= 10 && val.length <= 11);
          }
        ),
        cnpj: Yup.string()
          .length(14, 'O campo CNPJ deve ter 11 caracteres')
          .required('O campo CNPJ é obrigatório')
      });

      await schema.validate(
        {
          name: nameInput,
          phone: toPattern(phoneInput, '99999999999'),
          cnpj: toPattern(cnpjInput, '99999999999999')
        },
        {
          abortEarly: false
        }
      );

      const { data } = await appGasService.post(
        `/v2/integrations/ifood_companies/app_users`,
        {
          ...leadDataInput,
          source_integration,
          business_name: nameInput,
          business_cnpj: cnpjInput,
          phone: toPattern(phoneInput, '99999999999')
        }
      );

      setLeadDataInput(data);

      if (!address.id) {
        const { data: address_data } = await appGasService.post(
          `/v2/addresses`,
          {
            ...address,
            street: address.street || '',
            user_id: data.id
          }
        );

        setAddressDataInput({
          ...address_data,
          address: address_data.full_address,
          latitude: address.latitude || address.lat,
          longitude: address.longitude || address.long
        });
      }

      navigate('/checkout');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e) => {
          toast.error(`${e.message}`, {
            position: 'bottom-center',
            autoClose: 5000,
            draggable: true,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: false
          });
        });

        return;
      }

      toast.error('Ocorreu um erro ao tentar salvar os dados.', {
        position: 'bottom-center',
        autoClose: 5000,
        draggable: true,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false
      });
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormTitle variant="subtitle1">Preencha seus dados</FormTitle>

      <Box sx={{ mb: '16px' }}>
        <CustomInput
          required
          name="cnpj"
          value={formatCNPJ(cnpjInput)}
          label="CNPJ"
          icon={<WorkOutlineIcon color={'primary'} />}
          onChange={(e) => {
            setCnpjInput(e.target.value);
            trackEvent({ eventToken: 'Digitou CNPJ', sourceIntegration });
          }}
        />
      </Box>

      <Box sx={{ mb: '16px' }}>
        <CustomInput
          required
          name="company-name"
          value={nameInput}
          label="Nome da empresa"
          icon={<AccountCircleOutlinedIcon color="primary" />}
          onChange={(e) => {
            setNameInput(e.target.value);
            trackEvent({ eventToken: 'Digitou nome', sourceIntegration });
          }}
        />
      </Box>

      <Box>
        <CustomInput
          required
          name="phone"
          value={phoneInput}
          label="Telefone"
          icon={<LocalPhoneOutlinedIcon color="primary" />}
          onChange={(e) => {
            setPhoneInput(toPattern(e.target.value, '(99) 99999-9999'));

            trackEvent({ eventToken: 'Digitou telefone', sourceIntegration });
          }}
        />
      </Box>

      <Divider sx={{ my: '16px' }} />

      <PreviousAndNextButtons
        previousAction={() => navigate('/resellers')}
        nextActionType="submit"
      />
    </form>
  );
}
