import { useContext, useState } from 'react';

import {
  isLocalStorageAvailable,
  readStorage,
  writeStorage
} from 'hooks/useLocalStorage';

import Divider from '@mui/material/Divider';
import { CodeVerificationInput } from '../CodeVerificationInput';
import PreviousAndNextButtons from 'components/PreviousAndNextButtons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackEvent } from 'config/services/adjustTracking';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  eventCodeConfirmed,
  eventInvalidSMSCode,
  eventInvalidWPPCode
} from 'config/services/gtmEvents';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import { useAddresses } from 'hooks/useAddressesContext';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { useLoadingState } from 'hooks/useLoadingState';
import { CheckCodeAppUserUseCase } from 'use-cases/appuser/check-code-appuser.use-case';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { updateMoengageUser } from 'config/services/moengage/updateMoengageUser';

export default function FormCreateLead() {
  const navigate = useNavigate();
  const theme = useTheme();

  const { finishLoading, isLoading, startLoading } = useLoadingState();

  const isScreenSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<string | null>(null);

  const { reseller } = useContext(ResellerContext);
  const { chosenProducts } = useContext(ChosenProductsContext);

  const { temporaryPhone, setLeadData } = useContext(LeadDataContext);

  const { addressDataInput, setAddressDataInput } = useAddresses();

  const { integration } = useContext(SourceIntegrationContext);

  const sourceIntegration = integration.source;

  async function handleCodeVerification() {
    if (inputValue === '' || inputValue === '····' || !temporaryPhone) {
      const message = 'Código ou número do telefone não informado.';

      setError(message);
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

      return;
    }

    startLoading();

    await new CheckCodeAppUserUseCase()
      .handle({ code: inputValue, phone: temporaryPhone })
      .then(async (data) => {
        setLeadData(data);
        writeStorage('customer_token', data.token);
        writeStorage('customer_id', data.id);
        writeStorage('customer_phone', data.phone);

        try {
          if (isLocalStorageAvailable()) {
            window.dispatchEvent(new Event('updateUser'));
          }

          eventCodeConfirmed();
          trackEvent({ eventToken: 'Validou SMS', sourceIntegration });

          updateMoengageUser({
            name: data?.name,
            phone: data?.phone,
            userId: data?.id,
            mainAddress: data?.main_address?.full_address
          });
        } catch (e) {
          console.log(e);
        }

        if (data?.main_address?.id && !addressDataInput) {
          setAddressDataInput({
            ...data.main_address,
            address: data.main_address.full_address
          });
        }

        if (!reseller || chosenProducts.length <= 0) {
          navigate('/product-choice');
        } else {
          navigate('/cart');
        }
      })
      .catch(() => {
        trackEvent({ eventToken: 'SMS Inválido', sourceIntegration });

        const gateway = readStorage('gateway') as 'WHATSAPP' | 'SMS';
        if (gateway == 'WHATSAPP') {
          eventInvalidWPPCode();
        } else {
          eventInvalidSMSCode();
        }

        const Message = () => (
          <Box>
            <Box>Código inválido.</Box>
            <Box sx={{ fontWeight: '400', color: '#424242', mb: '8px' }}>
              Tente reenviar o código para avançar.
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Box sx={{ color: '#212429' }}>Entendi</Box>
            </Box>
          </Box>
        );

        toast.error(<Message />, {
          autoClose: 5000,
          draggable: true,
          theme: 'colored',
          closeOnClick: true,
          pauseOnHover: true,
          closeButton: false,
          hideProgressBar: false,
          position: 'bottom-center',
          icon: () => <ErrorOutlineIcon />
        });

        setError('Código inválido.');
      })
      .finally(() => {
        finishLoading();
      });
  }

  return (
    <>
      <CodeVerificationInput
        error={error}
        value={inputValue}
        setError={setError}
        setValue={setInputValue}
      />

      {isScreenSmall && <Divider sx={{ mb: '25px' }} />}

      <PreviousAndNextButtons
        previousAction={() => navigate('/create-lead')}
        nextAction={() => handleCodeVerification()}
        isLoading={isLoading}
      />
    </>
  );
}
