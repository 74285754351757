import { styled, Typography } from '@mui/material';

export const ModalTitle = styled(Typography)(
  ({ theme }) => `
  margin-bottom: 8px;
  font-size: 1.375rem;
  color: ${theme.palette.primary.main};
  font-weight: ${theme.typography.fontWeightLight}
`
);

export const ModalSubTitle = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.grey[600]};
  font-weight: ${theme.typography.fontWeightLight};
`
);
