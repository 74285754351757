import axios from 'axios';
export * from './errors';
import { sendErrorToAppsignal } from 'config/services/appsignal';

import {
  isLocalStorageAvailable,
  readStorage
} from '../../../hooks/useLocalStorage';

export const appGasService = axios.create({
  baseURL: `${process.env.REACT_APP_APPGAS_API}/api`
});

appGasService.interceptors.request.use(async (config) => {
  const customerId = readStorage('customer_id');
  const customerToken = readStorage('customer_token');

  const currentUser = customerId && customerToken;

  const accessTokenCookie = readStorage('access_token');

  if (accessTokenCookie) {
    config.headers = { Authorization: `Bearer ${accessTokenCookie}` };
  }

  if (currentUser) {
    config.headers = {
      ...config.headers,
      'X-User-Id': customerId,
      'X-User-Token': customerToken
    };
  }

  return config;
});

appGasService.interceptors.response.use(
  (response) => response,
  (error) => {
    let reduxPersistStorage = null;

    if (isLocalStorageAvailable()) {
      reduxPersistStorage = JSON.parse(
        localStorage.getItem('persist:root') || ''
      );
    }

    if (axios.isAxiosError(error)) {
      const url = error.config.url?.search('geolocations?');

      if (error.response?.status === 401) {
        localStorage.removeItem('persist:root');
        const sourceIntegrationCookie = document.cookie.match(
          new RegExp('(^| )' + `sourceIntegration` + '=([^;]+)')
        );
        document.cookie = 'addressDataInput=;';
        document.cookie = 'customer_id=;';
        document.cookie = 'customer_token=;';
        window.location.assign(
          `/?isource=${
            sourceIntegrationCookie ? sourceIntegrationCookie[2] : ''
          }`
        );
      }

      if (url === -1) {
        sendErrorToAppsignal({
          error,
          action: `${error.response?.config.url}`,
          params: error.response?.config?.data || error.response?.data
        });
      }

      sendErrorToAppsignal({
        error,
        action: `${error.response?.config.url}`,
        params: {
          response: error.response?.config?.data || error.response?.data,
          integration: reduxPersistStorage?.integration || {}
        }
      });
    }

    return Promise.reject({ ...error });
  }
);
