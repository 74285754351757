import { ReactNode, useState } from 'react';
import { SummaryContext } from './summary.context';
import { ISummaryData } from 'views/Review/types/ISummaryData';

interface SummaryProviderProps {
  children: ReactNode;
}

export function SummaryProvider({ children }: SummaryProviderProps) {
  const [summaryData, setSummaryData] = useState<ISummaryData | null>(null);

  return (
    <SummaryContext.Provider value={{ summaryData, setSummaryData }}>
      {children}
    </SummaryContext.Provider>
  );
}
