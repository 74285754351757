import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';

interface ApplyGiftCardUseCaseProps {
  giftcard: string;
  company_id: string;
  product_ids: {
    id: string;
    quantity: number;
  }[];
  address_id: string;
  brand_id: string;
}

export class ApplyGiftCardUseCase {
  private api = appGasService;

  async handle(props: ApplyGiftCardUseCaseProps) {
    const { data } = await this.api.post<unknown, AxiosResponse>(
      `/v2/integrations/giftcards/apply`,
      props
    );

    return data;
  }
}
