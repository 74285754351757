import {
  Alert,
  AlertTitle,
  Box,
  Snackbar,
  Typography,
  useTheme
} from '@mui/material';

import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { useContext, useEffect, useState } from 'react';
import { Progress } from './style';
import useWindowDimensions from 'hooks/useWindowDimensions/useWindowDimensions';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';

export default function AleloAlert() {
  const theme = useTheme();
  const [progress, setProgress] = useState(1);

  const { width } = useWindowDimensions();

  const { integration } = useContext(SourceIntegrationContext);

  const time = 600;

  const [open, setOpen] = useState(
    integration.source === 'alelo' ? true : false
  );

  useEffect(() => {
    if (progress <= 6) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => oldProgress + 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress]);

  return (
    <Snackbar
      sx={{
        boxShadow: 3,
        my: '16px',
        overflow: 'hidden',
        borderRadius: '4px',
        background: '#E5F5FF',
        [theme.breakpoints.up('md')]: {
          width: `calc(40rem - 48px)`
        },
        [theme.breakpoints.down('md')]: {
          maxWidth: `calc(${width}px - 48px)`,
          left: '24px',
          right: '24px'
        }
      }}
      open={open}
      autoHideDuration={time * 10}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Box sx={{ width: '100%' }}>
        <Alert
          severity="warning"
          icon={
            <PaymentsOutlinedIcon sx={{ color: '#1C3B77' }} fontSize="medium" />
          }
          sx={{
            mb: 0,
            display: 'flex',
            alignItems: 'center',
            background: '#E5F5FF'
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              component={'span'}
              sx={{
                fontSize: '14px',
                color: '#1C3B77',
                fontWeight: theme.typography.fontWeightBold
              }}
            >
              <AlertTitle sx={{ m: 0 }}>
                Pague seu botijão como desejar!
              </AlertTitle>
            </Typography>

            <Typography
              component={'span'}
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                color: '#424242',
                mb: '8px'
              }}
            >
              Pagamento online com Alelo Multibenefícios ou na entrega com
              dinheiro, pix, cartão de débito e crédito.
            </Typography>

            <Typography
              component={'span'}
              sx={{ fontWeight: 400, fontSize: '10px', color: '#1C3B77' }}
            >
              *Não serão aceitos cartões refeição ou alimentação.
            </Typography>
          </Box>
        </Alert>

        <Progress
          sx={{ borderRadius: '4px' }}
          variant="determinate"
          value={((progress * 100) / time) * 100}
        />
      </Box>
    </Snackbar>
  );
}
