import { styled, Typography as TypographyMUI } from '@mui/material';

export const FormTitle = styled(TypographyMUI)(
  ({ theme }) => `
  font-size: 1rem;
  margin-bottom: 16px;
  color: ${theme.palette.grey[900]};
  font-weight: ${theme.typography.fontWeightLight};
`
);
