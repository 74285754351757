import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../config/services/rtk-query';
import { Banner } from '../types';

export const bannerAPI = createApi({
  baseQuery,
  reducerPath: 'bannerApi',
  endpoints: (builder) => ({
    getBanners: builder.query<Banner[], void>({
      query: () => ({ url: 'banners/getBanners' })
    })
  })
});

export const { useGetBannersQuery } = bannerAPI;
