import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { IAddress } from 'hooks/useAddressesContext';

interface GeolocationsUseCaseProps {
  input: string;
  sessionToken: string;
}

interface IntegrationAuthenticationResponse {
  predictions: IAddress[];
  status: string;
}

export class GeolocationsUseCase {
  private api = appGasService;

  async handle(props: GeolocationsUseCaseProps) {
    const { input, sessionToken } = props;

    const { data } = await this.api.get<
      unknown,
      AxiosResponse<IntegrationAuthenticationResponse>
    >(`/v2/geolocations?address_text=${input}&sessiontoken=${sessionToken}`);

    return data;
  }
}
