import { styled, Typography as TypographyMUI } from '@mui/material';

export const Typography = styled(TypographyMUI)(
  ({ theme }) => `
  margin-top: 4px;
  margin-left: 16px;
  font-size: 0.75rem;
  font-weight: ${theme.typography.fontWeightLight};
`
);
