import { useContext, useEffect, useState } from 'react';
import CouponModal from './CouponModal';
import CouponIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { Box, useTheme, Typography, IconButton, lighten } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ISummaryData } from '../types/ISummaryData';
import { ICoupon, useCheckout } from '../hooks/useCheckoutContext';
import CloseIcon from '@mui/icons-material/Close';
import { SummaryContext } from 'providers/summary-context/summary.context';

interface IProps {
  couponApplied: ICoupon;
  setSummary: (input: ISummaryData | null) => void;
}

export default function Coupon({ setSummary, couponApplied }: IProps) {
  const theme = useTheme();

  const { summaryData, setSummaryData } = useContext(SummaryContext);

  const { setCoupon } = useCheckout();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setCoupon(couponApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeCoupon = () => {
    const newSummary = {
      ...summaryData,
      coupon: '',
      coupon_id: '',
      coupon_discount_text: ''
    };

    setSummaryData(newSummary);
    setSummary(newSummary);
    setCoupon({ label: '', discountText: '' });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <CouponIcon color="primary" sx={{ mr: '12px' }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: !couponApplied.label
                ? theme.palette.grey[900]
                : theme.palette.primary.main
            }}
            variant="subtitle1"
          >
            {!couponApplied.label ? 'Cupom de desconto' : couponApplied.label}
          </Typography>
          {couponApplied.label && (
            <Box
              sx={{
                ml: '14px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 6px',
                borderRadius: '4px',
                fontSize: '10px',
                fontWeight: 700,
                height: '22px',
                background: theme.palette.primary.main,
                color: '#fff'
              }}
            >
              <span>{couponApplied.discountText}</span>
            </Box>
          )}
        </Box>
      </Box>

      <IconButton
        size="small"
        onClick={() =>
          !couponApplied.label ? setModalOpen(true) : removeCoupon()
        }
        sx={{
          background: couponApplied.label
            ? '#E0E0E0'
            : theme.palette.primary.xdark,
          color: couponApplied.label ? ' #757575 ' : '#FFF',
          '&:hover': {
            background: lighten(
              couponApplied.label ? '#E0E0E0' : theme.palette.primary.xdark,
              0.1
            )
          }
        }}
      >
        {!couponApplied.label && <AddIcon fontSize="small" />}
        {couponApplied.label && <CloseIcon fontSize="small" />}
      </IconButton>

      <CouponModal
        open={modalOpen}
        title="Adicionar cupom"
        onClose={() => setModalOpen(false)}
      />
    </Box>
  );
}
