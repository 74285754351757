import { useState, useEffect, ReactNode, useContext } from 'react';
import { appGasService, EGeolocationErrors } from 'config/services/appgas';
import {
  IAddress,
  AddressDetails,
  ICurrentLocation,
  AddressesContext
} from './AddressContext';
import { readStorage, writeStorage } from 'hooks/useLocalStorage';
import { toast } from 'react-toastify';
import { GeolocationsUseCase } from 'use-cases/geolocations/geolocations.use-case';
import { AddressInputData } from 'views/SaveAddress/types';
import { AddressModal } from 'components/AddressModal/address-modal';
import { useNavigate } from 'react-router-dom';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';

interface ISearchAddressesProviderProps {
  children: ReactNode;
}

export function SearchAddressesProvider({
  children
}: ISearchAddressesProviderProps) {
  const { integration } = useContext(SourceIntegrationContext);
  const sessionToken = integration.sessionToken;
  const [latlng, setLatlng] = useState('');
  const navigate = useNavigate();
  const customerToken = readStorage('customer_token');
  const customerId = readStorage('customer_id');
  const [addresses, setAdrreses] = useState<IAddress[]>([]);
  const addressDataCookie = readStorage('addressDataInput')
    ? (JSON.parse(readStorage('addressDataInput')) as AddressInputData)
    : null;
  const [addressDataInput, setAddressDataInput] =
    useState<AddressInputData | null>(
      addressDataCookie as unknown as AddressInputData
    );
  const [isSearchAddressLoading, setIsSearchAddressLoading] =
    useState<boolean>(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [formAddressDataInput, setFormAddressDataInput] =
    useState<AddressInputData | null>(
      addressDataCookie as unknown as AddressInputData
    );

  const [currentLocation, setCurrentLocation] = useState<ICurrentLocation>({
    data: null,
    error: null,
    loading: false
  });

  useEffect(() => {
    addressDataInput &&
      writeStorage('addressDataInput', JSON.stringify(addressDataInput));
  }, [addressDataInput]);

  async function searchAddress(input: string) {
    setIsSearchAddressLoading(true);

    await new GeolocationsUseCase()
      .handle({
        input: input,
        sessionToken: sessionToken
      })
      .then((data) => {
        if (!data || !data.predictions) {
          const errorMessage = 'Endereço não encontrado';

          toast.error(errorMessage, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          return;
        }

        setAdrreses(data.predictions);
      })
      .catch((e) => {
        console.log('searchAddress - Failed', e);
      })
      .finally(() => {
        setIsSearchAddressLoading(false);
      });
  }

  useEffect(() => {
    async function getGeolocation() {
      if (latlng) {
        try {
          const {
            data: {
              results: [currentPosition]
            }
          } = await appGasService.get(`/v2/geolocations?latlng=${latlng}`);

          setCurrentLocation({
            error: null,
            loading: false,
            data: currentPosition
          });
        } catch (error) {
          setCurrentLocation({
            data: null,
            loading: false,
            error: EGeolocationErrors.ERROR_IDENTIFYING_LOCATION
          });
        }
      }
    }

    getGeolocation();
  }, [latlng]);

  const getCurrentGeolocationByBrowser = () => {
    setLatlng('');
    setCurrentLocation({
      data: null,
      error: null,
      loading: true
    });

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          setLatlng(`${latitude},${longitude}`);
        },
        () => {
          setCurrentLocation({
            data: null,
            loading: false,
            error: EGeolocationErrors.ENABLE_BROWSER_LOCATION
          });
        }
      );

      return;
    }

    setCurrentLocation({
      data: null,
      loading: false,
      error: EGeolocationErrors.ERROR_IDENTIFYING_LOCATION
    });
  };

  async function chooseAddress(address: IAddress) {
    const {
      data: {
        results: [addressDetails]
      }
    } = await appGasService.get<AddressDetails>(
      `/v2/geolocations/${address.place_id}`
    );

    const addressHashMap: { [key: string]: string[] } = {
      zip: ['postal_code'],
      city: ['administrative_area_level_2'],
      state: ['administrative_area_level_1'],
      street: ['sublocality_level_4', 'route'],
      number: ['street_number'],
      neighborhood: ['sublocality_level_1', 'sublocality_level_3']
    };

    const addressMap = new Map();

    addressDetails.address_components.forEach((addressComponent) => {
      Object.keys(addressHashMap).forEach((key) => {
        const haveAtLeastOne = addressComponent.types.some((item) =>
          addressHashMap[key].includes(item)
        );

        if (haveAtLeastOne) addressMap.set(key, addressComponent.long_name);
      });
    });

    addressMap.set('latitude', addressDetails.geometry.location.lat);
    addressMap.set('longitude', addressDetails.geometry.location.lng);

    const addressMapped = Object.fromEntries(addressMap);

    setFormAddressDataInput({
      ...addressMapped,
      placeId: address.place_id,
      address: address.description
    });
  }

  function clearList() {
    setAdrreses([]);
  }

  async function createAddress() {
    if (!customerId || !customerToken) {
      return;
    }

    const address = addressDataInput;

    const payload = {
      address: address?.address,
      city: address?.city,
      latitude: address?.latitude || address?.lat,
      longitude: address?.longitude || address?.long,
      neighborhood: address?.neighborhood,
      state: address?.state,
      street: address?.street || address?.address?.split(',')[0] || '',
      user_id: customerId,
      number: address?.number,
      zip: address?.zip,
      complement: address?.complement,
      ref: address?.ref
    };

    const { data } = await appGasService.post(`/v2/addresses`, payload);

    address &&
      setAddressDataInput({
        ...data,
        address: data.full_address,
        latitude: address.latitude || address.lat,
        longitude: address.longitude || address.long
      });

    return data;
  }

  return (
    <AddressesContext.Provider
      value={{
        addresses,
        isSearchAddressLoading,
        addressDataInput,
        formAddressDataInput,
        setFormAddressDataInput,
        clearList,
        setAddressDataInput,
        setAdrreses,
        chooseAddress,
        searchAddress,
        currentLocation,
        setCurrentLocation,
        getCurrentGeolocationByBrowser,
        setIsAddressModalOpen,
        createAddress
      }}
    >
      {children}
      <AddressModal
        open={isAddressModalOpen}
        onClose={() => {
          setIsAddressModalOpen(false);
          if (!addressDataInput) {
            navigate('/');
          }
        }}
      />
    </AddressesContext.Provider>
  );
}
