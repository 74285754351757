import { Typography } from '@mui/material';

type Props = {
  color: string;
  text: string;
};

export function Badge({ color, text }: Props) {
  return (
    <Typography
      sx={{
        p: '5px',
        width: 'auto',
        color: '#FFFF',
        fontWeight: 600,
        fontSize: '12px',
        borderRadius: '5px',
        textAlign: 'center',
        background: color
      }}
    >
      {text}
    </Typography>
  );
}
