import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../../../store';

import { readStorage } from '../../../hooks/useLocalStorage';

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APPGAS_API}/api`,
  prepareHeaders: (headers, { getState }) => {
    const leadData = readStorage('leadData');
    const currentUser = leadData && JSON.parse(leadData);

    const accessToken = (getState() as RootState).authentication?.accessToken;

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    if (currentUser) {
      headers.set('X-User-Id', currentUser.id);
      headers.set('X-User-Token', currentUser.token);
    }

    return headers;
  }
});
