import Appsignal from '@appsignal/javascript';
import { HashMap, HashMapValue } from '@appsignal/types';

export const appsignal = new Appsignal({
  key: `${process.env.REACT_APP_APPSIGNAL_KEY}`
});

interface IErrorParams {
  error: Error;
  action: string;
  params: Record<string, unknown>;
}

export const sendErrorToAppsignal = ({
  error,
  action,
  params
}: IErrorParams) => {
  appsignal.sendError(error, (span) => {
    span.setAction(action);
    span.setParams(params as HashMap<HashMapValue>);
  });
};
