import { useContext, useState } from 'react';

import axios from 'axios';
import { appGasService } from 'config/services/appgas';

import { useCheckout } from 'views/Review/hooks/useCheckoutContext';
import { eventAddCoupon, eventInvalidCoupon } from 'config/services/gtmEvents';
import {
  Close as CloseIcon,
  ConfirmationNumberOutlined as CouponIcon
} from '@mui/icons-material';
import {
  IconButton,
  ButtonsBox,
  ModalTitle,
  CancelButton,
  ConfirmButton
} from './styles';
import CustomInput from '../../../../components/CustomInput';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { SummaryContext } from 'providers/summary-context/summary.context';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { useAddresses } from 'hooks/useAddressesContext';

export interface IModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
}

export default function AddCouponModal(props: IModalProps) {
  const { coupon, setCoupon } = useCheckout();
  const { title, onClose, open } = props;
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const [error, setError] = useState('');

  const { summaryData, setSummaryData } = useContext(SummaryContext);

  const { addressDataInput } = useAddresses();

  const { reseller } = useContext(ResellerContext);

  const { chosenProducts } = useContext(ChosenProductsContext);

  const handleCoupon = () => {
    if (!coupon) {
      summaryData &&
        setSummaryData({
          ...summaryData,
          coupon: '',
          coupon_id: '',
          coupon_discount_text: ''
        });

      onClose();
      return;
    }

    if (!reseller || !addressDataInput) {
      return;
    }

    try {
      appGasService
        .post(`/v3/integrations/coupons/apply`, {
          coupon: coupon.label,
          company_id: reseller.id,
          product_ids: chosenProducts.map((product) => {
            return {
              ...product,
              brand_id: product.brand_id || reseller.brand_id
            };
          }),
          address_id: addressDataInput.id,
          brand_id: reseller.brand_id
        })
        .then(({ data }) => {
          setSummaryData({ ...summaryData, ...data });

          eventAddCoupon(coupon.label);
          onClose();
        })
        .catch((error) => {
          setError(error.response.data.message);
          eventInvalidCoupon(coupon.label);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error?.response?.data?.message);
      }
    }
  };

  const ClearButton = () => {
    if (!coupon?.label?.length) return <></>;

    return (
      <IconButton
        sx={{ p: 0 }}
        onClick={() => {
          setError('');
          setCoupon({ label: '' });
        }}
      >
        <HighlightOffIcon color="primary" />
      </IconButton>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{ padding: '24px' }}
    >
      <DialogTitle>
        <ModalTitle>{title}</ModalTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <CustomInput
            error={!!error && coupon.label.length > 0}
            name="coupon"
            value={coupon.label}
            label="Adicionar cupom"
            helperText={error}
            icon={<CouponIcon color="primary" />}
            onChange={(e) => setCoupon({ label: e.target.value })}
            endIcon={<ClearButton />}
          />

          <ButtonsBox>
            {isScreenSmall && (
              <CancelButton
                variant="text"
                onClick={() => {
                  if (summaryData?.coupon) {
                    setCoupon({
                      label: summaryData?.coupon,
                      discountText: summaryData?.coupon_discount_text
                    });
                  }
                  onClose();
                }}
              >
                Cancelar
              </CancelButton>
            )}

            <ConfirmButton
              type="submit"
              variant="contained"
              onClick={() => handleCoupon()}
            >
              Adicionar
            </ConfirmButton>
          </ButtonsBox>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
