import {
  styled,
  Box as MUIBox,
  Button as MUIButton,
  IconButton as MUIIconButton,
  Typography as MUITypography
} from '@mui/material';

export const ModalTitle = styled(MUITypography)(
  ({ theme }) => `
  position: relative;
  font-size: 1.375rem;
  margin-bottom: 16px;
  color: ${theme.palette.primary.main}
`
);

export const IconButton = styled(MUIIconButton)(
  ({ theme }) => `
  top: 12px;
  right: 16px;
  position: absolute;
  color: ${theme.palette.grey[500]}
`
);

export const ConfirmButton = styled(MUIButton)(
  ({ theme }) => `
  width: initial;
  font-weight: 700;
  margin-top: 16px;
  padding: '13.5px 16px'
  ${theme.breakpoints.down('md')} {
    width: 100%;
    fontSize: '14px',
    padding: '13.5px 16px'
  }
`
);

export const CancelButton = styled(MUIButton)(
  ({ theme }) => `
  width: initial;
  font-weight: 700;
  margin-top: 16px;
  margin-right: 16px;
  padding: '13.5px 16px'
  ${theme.breakpoints.down('md')} {
    width: 100%;
    fontSize: '14px',
    padding: '13.5px 16px'
  }
`
);

export const ButtonsBox = styled(MUIBox)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: end;

  ${theme.breakpoints.down('md')} {
    justify-content: center;
    flex-direction: column;
  }
`
);
