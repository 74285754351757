import { Fragment } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import * as S from './style';

interface IProps {
  totalSteps?: number;
  currentStep?: number;
  title?: string;
  showStepInfo?: boolean;
}

function StepperItem({
  filled = false,
  keyValue
}: {
  filled: boolean;
  keyValue: number;
}) {
  const theme = useTheme();

  if (filled) {
    return (
      <S.LinearProgressBar
        key={keyValue}
        sx={{
          '.MuiLinearProgress-bar': {
            backgroundColor: theme.palette.green.main
          }
        }}
        variant="determinate"
        value={100}
      />
    );
  }
  return (
    <S.LinearProgressBar
      key={keyValue}
      sx={{ backgroundColor: theme.palette.grey['300'] }}
      variant="determinate"
      value={0}
    />
  );
}

export function Stepper({
  totalSteps = 1,
  currentStep = 1,
  title = '',
  showStepInfo = true
}: IProps) {
  const steps = Array.from(Array(totalSteps).keys());
  return (
    <Box display="flex" flexDirection="column" width={1}>
      {showStepInfo && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width={1}
          pb={1}
        >
          <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
            {title}
          </Typography>
          <Typography variant="caption">
            Etapa{' '}
            <b>
              {currentStep} de {totalSteps}
            </b>
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: `repeat(${totalSteps}, 1fr)`
        }}
      >
        {steps.length &&
          steps.map((_: unknown, index: number) => (
            <Fragment key={index}>
              <StepperItem filled={index + 1 <= currentStep} keyValue={index} />
            </Fragment>
          ))}
      </Box>
    </Box>
  );
}
