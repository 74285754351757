import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { LeadInputData } from 'views/CreateLead/types';

interface ValidateTokenAppUserUseCaseProps {
  auth_token: string;
  phone: string;
}

export class ValidateTokenAppUserUseCase {
  private api = appGasService;

  async handle(props: ValidateTokenAppUserUseCaseProps) {
    const { auth_token, phone } = props;

    const { data } = await this.api.get<unknown, AxiosResponse<LeadInputData>>(
      `/v2/integrations/appuser/validate_token?auth_token=${auth_token}&phone=${phone}`
    );

    return data;
  }
}
