import { Help } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { ConfirmationCodeModal } from './confirmation-code-modal';

interface ConfirmationCodeProps {
  code: string;
}

export function ConfirmationCode(props: ConfirmationCodeProps) {
  const theme = useTheme();
  const { code } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <Stack
      direction={'column'}
      sx={{ p: '16px' }}
      borderTop={`solid 1px ${theme.palette.grey[300]}`}
    >
      <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
        <Typography>Código de entrega</Typography>
        <Help
          sx={{
            color: theme.palette.grey[600],
            fontSize: '20px',
            ml: '6px',
            cursor: 'pointer'
          }}
          onClick={() => setIsModalOpen(true)}
        />
      </Stack>
      <Typography
        fontWeight={'400'}
        sx={{ color: theme.palette.grey[600] }}
        fontSize={'14px'}
        lineHeight={'18px'}
        maxWidth={'290px'}
      >
        Informe o código abaixo ao entregador e receba seu pedido com segurança.
      </Typography>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        mt={'8px'}
        border={`solid 1px ${theme.palette.primary.light}`}
        borderRadius={'4px'}
        sx={{ backgroundColor: theme.palette.primary.xxlight }}
        height={'41px'}
      >
        <Typography
          variant="h5"
          fontWeight={'600'}
          sx={{ color: theme.palette.primary.xdark }}
          letterSpacing={'10px'}
        >
          {code}
        </Typography>
      </Stack>
      <ConfirmationCodeModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Stack>
  );
}
