import { useContext, useEffect, useMemo, useState } from 'react';

import { Button, useMediaQuery, useTheme } from '@mui/material';
import { StyledReactInputVerificationCode } from './styles';
import ReactInputVerificationCode from 'react-input-verification-code';
import { toast } from 'react-toastify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  eventResendWPPCode,
  eventResendSMSCode
} from 'config/services/gtmEvents';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import { SendCodeAppUserUseCase } from 'use-cases/appuser/send-code-appuser.use-case';
import { useLocation } from 'react-router-dom';
import { useLoadingState } from 'hooks/useLoadingState';

type Props = {
  value: string;
  error: string | null;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function CodeVerificationInput({
  value,
  error,
  setValue,
  setError
}: Props) {
  const theme = useTheme();
  const query = useQuery();
  const [seconds, setSeconds] = useState<number>(60);
  const { temporaryPhone } = useContext(LeadDataContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { finishLoading, isLoading, startLoading } = useLoadingState();

  useEffect(() => {
    const interval = setInterval(
      () =>
        setSeconds((seconds) => {
          return seconds !== 0 ? seconds - 1 : 0;
        }),
      1000
    );

    return () => clearInterval(interval);
  }, []);

  const resendCode = async () => {
    startLoading();

    const gateway = query.get('gateway');

    if (gateway === 'WHATSAPP') {
      eventResendWPPCode();
    } else {
      eventResendSMSCode();
    }

    await new SendCodeAppUserUseCase()
      .handle({
        gateway: gateway || '',
        phone: temporaryPhone
      })
      .then(() => {
        setSeconds(60);
        toast.success(`Código enviado por ${gateway}`, {
          autoClose: 5000,
          draggable: true,
          theme: 'colored',
          closeOnClick: true,
          pauseOnHover: true,
          closeButton: false,
          hideProgressBar: false,
          position: isSmallScreen ? 'top-center' : 'bottom-center',
          icon: () => <CheckCircleOutlineIcon />
        });
      })
      .finally(() => {
        finishLoading();
      });
  };

  return (
    <>
      <StyledReactInputVerificationCode isInvalid={!!error}>
        <ReactInputVerificationCode
          placeholder=""
          value={value}
          length={4}
          onChange={(newValue) => {
            setValue(newValue);

            if (newValue !== '') setError(null);
          }}
        />
      </StyledReactInputVerificationCode>

      <Button
        disabled={seconds > 0 || isLoading}
        onClick={resendCode}
        variant="outlined"
        sx={{
          '&.Mui-disabled': {
            '&:hover': {
              background: 'transparent'
            },
            opacity: 1,
            background: 'transparent',
            cursor: 'no-drop',
            pointerEvents: 'inherit',
            border: `1px solid ${theme.palette.primary.xdark} !important`,
            color: `${theme.palette.primary.xdark} !important`
          },
          '&:hover': {
            background: theme.palette.primary.xdark
          },
          background: theme.palette.primary.xdark,
          color: 'white',
          py: '10px',
          my: '16px',
          fontWeight: 700,
          width: { md: 'initial' }
        }}
      >
        {seconds > 0 &&
          `Reenviar código em 00:
          ${seconds.toLocaleString('pt-BR', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}`}

        {seconds === 0 && `Reenviar código`}
      </Button>
    </>
  );
}
