import { useState } from 'react';

export function isLocalStorageAvailable() {
  const teste = 'teste';
  try {
    localStorage.setItem(teste, teste);
    localStorage.removeItem(teste);
    return true;
  } catch (e) {
    return false;
  }
}

export function readStorage(key: string) {
  return getCookie(key);
}

export function writeStorage(key: string, value: string) {
  setCookie(key, value);
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = getCookie(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      setCookie(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue] as const;
}

function setCookie(cname: string, cvalue: string, exdays?: number) {
  const cexdays = exdays ? exdays : 100;
  const d = new Date();
  d.setTime(d.getTime() + cexdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie =
    cname + '=' + cvalue.replaceAll('%', '%25') + ';' + expires + ';path=/';
}

function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length).replaceAll('%25', '%');
    }
  }
  return '';
}
