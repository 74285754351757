import { createContext } from 'react';

export interface IProductsState {
  id: string;
  name: string;
  desc: string;
  picture: string;
  price: number;
  quantity: number;
  brand_id?: string;
  brand_name?: string;
  brand_color?: string;
  observation?: string;
  details?: string;
  picture2?: string;
  picture3?: string;
  picture4?: string;
}

export interface ChosenProductsContextData {
  chosenProducts: IProductsState[];
  setChosenProducts: (input: IProductsState[]) => void;
  handleProductQuantity: (
    product: IProductsState,
    action: 'add' | 'sub'
  ) => void;
}

export const ChosenProductsContext = createContext<ChosenProductsContextData>(
  {} as ChosenProductsContextData
);
