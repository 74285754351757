import { Routes, Route } from 'react-router-dom';
import ProductChoice from 'views/ProductChoice';
import Resellers from 'views/Resellers';
import Review from 'views/Review';
import Checkout from 'views/Review/checkout';
import DefaultLayout from '../../layouts/DefaultLayout';
import Home from '../../views/Home';
import CheckCNPJ from 'views/CheckCNPJ';
import SaveAddress from '../../views/SaveAddress';
import CreateLead from '../../views/CreateLead';
import CheckPhone from 'views/CheckPhone';
import OrderTracking from 'views/OrderTracking';
import OrderRating from 'views/OrderRating';
import OrderCancelled from 'views/OrderCancelled';
import CrashApp from 'components/Errors/CrashApp';

import { CheckoutProvider } from 'views/Review/hooks/useCheckoutContext';
import { RatingContextProvider } from 'views/OrderRating/hooks/useOrderRatingContext';

import { ErrorBoundary } from '@appsignal/react';
import { appsignal } from 'config/services/appsignal';
import Cart from 'views/Cart';

export function AppRoutes() {
  return (
    <ErrorBoundary instance={appsignal} fallback={() => <CrashApp />}>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Home />} />
          <Route path="/address" element={<SaveAddress />} />
          <Route path="/check-phone" element={<CheckPhone />} />
          <Route path="/check-cnpj" element={<CheckCNPJ />} />
          <Route path="/create-lead" element={<CreateLead />} />
          <Route path="/product-choice" element={<ProductChoice />} />
          <Route path="/resellers" key="resellers" element={<Resellers />} />
          <Route
            path="/review"
            element={
              <CheckoutProvider>
                <Review />
              </CheckoutProvider>
            }
          />
          <Route
            path="/cart"
            element={
              <CheckoutProvider>
                <Cart />
              </CheckoutProvider>
            }
          />
          <Route
            path="/checkout"
            element={
              <CheckoutProvider>
                <Checkout />
              </CheckoutProvider>
            }
          />
          <Route path="/order-tracking" element={<OrderTracking />} />
          <Route
            path="/order-rating"
            element={
              <RatingContextProvider>
                <OrderRating />
              </RatingContextProvider>
            }
          />
          <Route path="/order-cancelled" element={<OrderCancelled />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
}
