import { styled, Button } from '@mui/material';

export const BuyButton = styled(Button)(({ theme }) => ({
  padding: '4px 16px',
  backgroundColor: theme.palette.green['xdark'],
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: theme.palette.green['xxdark']
  }
}));
