import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useContext } from 'react';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { formatPrice } from 'helpers/formatPrice';

export function CartProductsList() {
  const { palette } = useTheme();
  const { reseller } = useContext(ResellerContext);
  const { chosenProducts, handleProductQuantity } = useContext(
    ChosenProductsContext
  );

  return (
    <Stack direction={'column'} gap={'8px'}>
      <Typography fontSize={'14px'} fontWeight={'600'}>
        Meu pedido:
      </Typography>
      <Card
        variant="custom"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Stack>
          <CardContent
            sx={{
              px: '16px',
              py: '8px !important',
              backgroundColor: '#f5f5f5'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Typography
                  sx={{ fontSize: '10px' }}
                  variant="caption"
                  lineHeight={'15px'}
                  color={palette.primary.dark}
                  fontWeight={'600'}
                >
                  Vendido e entregue por:
                </Typography>
                <Typography
                  sx={{ fontSize: '12px' }}
                  variant="caption"
                  lineHeight={'18px'}
                  color={palette.grey[700]}
                  fontWeight={'400'}
                >
                  {reseller?.display_name}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Stack>
        <CardContent
          sx={{
            px: '0px',
            py: '0px !important',
            borderTop: `solid 1px ${palette.grey[300]}`,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {!!chosenProducts.length &&
            chosenProducts.map((product, index) => (
              <Stack
                key={product.id}
                direction={'row'}
                justifyContent={'space-between'}
                borderBottom={
                  index == chosenProducts.length - 1
                    ? ''
                    : `solid 1px ${palette.grey[300]}`
                }
                p={'16px 16px'}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <CardMedia
                    component="img"
                    image={product.picture}
                    alt="Live from space album cover"
                    sx={{ height: 28, width: 20, objectFit: 'contain' }}
                  />
                  <CardContent
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingY: '0 !important'
                    }}
                  >
                    <Box>
                      <Stack
                        alignItems={'center'}
                        justifyContent={'center'}
                        bgcolor={product.brand_color || reseller?.brand_color}
                        px={'5px'}
                        py={'3px'}
                        borderRadius={'3px'}
                        width={'fit-content'}
                        mb={'4px'}
                      >
                        <Typography
                          fontSize={'8px'}
                          fontWeight={'700'}
                          lineHeight={'9px'}
                          color={'#fff'}
                        >
                          {product.brand_name || reseller?.brand}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2" lineHeight={'20px'}>
                        {product.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '10px',
                          fontWeight: '600',
                          lineHeight: '14px',
                          color: palette.grey[600]
                        }}
                      >
                        {formatPrice(product.price * product.quantity)}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>

                <CardActions>
                  <IconButton
                    sx={{
                      mr: '8px',
                      width: '22px',
                      height: '22px',
                      border: '1px solid #E0E0E0',
                      '&:hover': {
                        backgroundColor: '#9E9E9E',
                        border: '1px solid #9E9E9E'
                      }
                    }}
                    onClick={() => handleProductQuantity(product, 'sub')}
                  >
                    <RemoveIcon sx={{ fontSize: '16px', color: '#E0E0E0' }} />
                  </IconButton>

                  <Typography>{product.quantity}</Typography>

                  <IconButton
                    sx={{
                      m: 0,
                      width: '22px',
                      height: '22px',
                      backgroundColor: '#4CB840',
                      '&:hover': {
                        backgroundColor: '#62c157'
                      }
                    }}
                    onClick={() => handleProductQuantity(product, 'add')}
                  >
                    <AddIcon sx={{ fontSize: '16px', color: '#FFFF' }} />
                  </IconButton>
                </CardActions>
              </Stack>
            ))}
        </CardContent>
      </Card>
    </Stack>
  );
}
