import { Outlet } from 'react-router-dom';
import { SearchAddressesProvider } from 'hooks/useAddressesContext';
import { HeaderProvider } from 'hooks/useHeaderContext';

import { Header } from '../../components/Header';
import { Box } from '@mui/material';

export function DefaultLayout() {
  return (
    <>
      <SearchAddressesProvider>
        <HeaderProvider>
          <Box height={'100vh'} display={'flex'} flexDirection={'column'}>
            <Header />
            <Outlet />
          </Box>
        </HeaderProvider>
      </SearchAddressesProvider>
    </>
  );
}

export default DefaultLayout;
