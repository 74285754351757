import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CustomInput from '../../../../components/CustomInput';
import { useNavigate } from 'react-router-dom';
import { Footer } from 'components/Footer';
import { trackEvent } from 'config/services/adjustTracking';
import { useAddresses } from 'hooks/useAddressesContext';
import { AddressInputData } from 'views/SaveAddress/types';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';

export default function FormSaveAddress() {
  const navigate = useNavigate();

  const {
    formAddressDataInput,
    setFormAddressDataInput,
    setIsAddressModalOpen,
    setAddressDataInput
  } = useAddresses();

  const { integration } = useContext(SourceIntegrationContext);

  const configurations = integration.configurations;
  const sourceIntegration = integration.source;

  const [addressDataInputErrors, setAddressDataInputErrors] = useState({
    id: '',
    number: '',
    address: '',
    complement: '',
    referencePoint: ''
  });

  const sourceIntegrationName =
    sourceIntegration == 'consigaz' ? 'consigaz' : 'cliente';

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    setAddressDataInputErrors({
      id: '',
      number: '',
      address: '',
      complement: '',
      referencePoint: ''
    });

    try {
      const schema = Yup.object().shape({
        referencePoint: Yup.string(),
        number: Yup.number().typeError('Este campo aceita apenas números'),
        complement: Yup.string().nullable(),
        address: Yup.string()
          .min(6)
          .typeError('Este campo deve ter no mínimo 6 caracteres')
          .required('Campo obrigatório')
      });

      await schema.validate(formAddressDataInput, {
        abortEarly: false
      });

      trackEvent({ eventToken: 'Finalizou endereço', sourceIntegration });
      setIsAddressModalOpen(false);

      const removedId = { ...formAddressDataInput };
      delete removedId.id;
      setFormAddressDataInput(removedId as AddressInputData);
      setAddressDataInput(removedId as AddressInputData);

      navigate('/product-choice');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          setAddressDataInputErrors({
            ...addressDataInputErrors,
            [error.path as string]: error.message
          });
        });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <CustomInput
            name="address"
            required
            disabled
            label="Endereço"
            value={formAddressDataInput?.address || ''}
            error={!!addressDataInputErrors.address}
            helperText={addressDataInputErrors.address}
            onChange={(e) =>
              formAddressDataInput &&
              setFormAddressDataInput({
                ...formAddressDataInput,
                address: e.target.value
              })
            }
            icon={<RoomOutlinedIcon />}
            endIcon={
              <IconButton onClick={() => setFormAddressDataInput(null)}>
                <EditOutlinedIcon
                  color={addressDataInputErrors.address ? 'error' : 'primary'}
                />
              </IconButton>
            }
          />
        </Grid>

        <Grid item xs={4} md={3}>
          <CustomInput
            required
            name="number"
            label="Número"
            inputProps={{ maxLength: 5 }}
            error={!!addressDataInputErrors.number}
            helperText={addressDataInputErrors.number}
            value={formAddressDataInput?.number}
            onChange={(e) => {
              let value = e.target.value;

              if (!Number(value)) {
                value = value.substring(0, value.length - 1);
              }

              formAddressDataInput &&
                setFormAddressDataInput({
                  ...formAddressDataInput,
                  number: value
                });
            }}
          />
        </Grid>

        <Grid item xs={8} md={9}>
          <CustomInput
            name="complement"
            label="Complemento"
            value={formAddressDataInput?.complement || ''}
            onChange={(e) =>
              formAddressDataInput &&
              setFormAddressDataInput({
                ...formAddressDataInput,
                complement: e.target.value
              })
            }
            error={!!addressDataInputErrors.complement}
            helperText={addressDataInputErrors.complement}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomInput
            name="reference-point"
            label="Ponto de referência"
            value={formAddressDataInput?.ref || ''}
            onChange={(e) =>
              formAddressDataInput &&
              setFormAddressDataInput({
                ...formAddressDataInput,
                ref: e.target.value
              })
            }
            error={!!addressDataInputErrors.referencePoint}
            helperText={addressDataInputErrors.referencePoint}
          />
          {configurations.terms && (
            <Footer terms={`${sourceIntegrationName}`} />
          )}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ height: '48px', fontWeight: '700' }}
          >
            Salvar endereço
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
