import {
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { AddressGraphic } from './address-graphics';
import { Close } from '@mui/icons-material';
import { SearchAddressForm } from 'views/Home/components/SearchAddress/components/SearchAddressForm';
import CurrentLocation from 'views/Home/components/CurrentLocation';
import ListAddresses from 'views/Home/components/ListAddresses';
import { useAddresses } from 'hooks/useAddressesContext';
import SaveAddress from 'views/SaveAddress';
import { useEffect } from 'react';

interface AddressModalProps {
  open: boolean;
  onClose: () => void;
}

export function AddressModal(props: AddressModalProps) {
  const { onClose, open } = props;
  const {
    addresses,
    formAddressDataInput,
    setFormAddressDataInput,
    addressDataInput
  } = useAddresses();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    addressDataInput && open && setFormAddressDataInput(addressDataInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={onClose}>
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <IconButton sx={{ mr: '20px', mt: '20px' }} onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      {formAddressDataInput ? (
        <SaveAddress />
      ) : (
        <Stack
          direction={'column'}
          gap="20px"
          justifyContent={'center'}
          alignItems={'center'}
          pb="60px"
          pt="0px"
          px={isSmallScreen ? '16px' : '56px'}
        >
          {addresses.length <= 0 && <AddressGraphic width={200} height={200} />}
          <Typography
            variant="h6"
            fontSize={'16px'}
            textAlign={'center'}
            lineHeight={'22px'}
          >
            Em qual endereço você quer receber seu botijão?
          </Typography>
          <SearchAddressForm />
          {addresses.length <= 0 && <CurrentLocation />}
          <ListAddresses />
        </Stack>
      )}
    </Dialog>
  );
}
