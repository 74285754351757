import { useContext, useState } from 'react';
import { CardGiftcard, Close as CloseIcon } from '@mui/icons-material';
import {
  IconButton,
  ButtonsBox,
  ModalTitle,
  CancelButton,
  ConfirmButton
} from './styles';
import CustomInput from '../../../../components/CustomInput';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { SummaryContext } from 'providers/summary-context/summary.context';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { useAddresses } from 'hooks/useAddressesContext';
import { ApplyGiftCardUseCase } from 'use-cases/giftcards/apply-giftcard.use-case';
import { useLoadingState } from 'hooks/useLoadingState';

export interface IModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
}

export default function SmashGiftCardsModal(props: IModalProps) {
  const { title, onClose, open } = props;
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const { finishLoading, isLoading, startLoading } = useLoadingState();

  const [error, setError] = useState('');

  const { summaryData, setSummaryData } = useContext(SummaryContext);
  const [giftCardCode, setGiftCardCode] = useState<string>('');

  const { addressDataInput } = useAddresses();
  const { reseller } = useContext(ResellerContext);
  const { chosenProducts } = useContext(ChosenProductsContext);

  async function handleAddGiftCard() {
    if (!giftCardCode || !reseller || !addressDataInput) {
      return;
    }

    startLoading();
    setError('');

    const payload = {
      giftcard: giftCardCode,
      company_id: reseller.id,
      product_ids: chosenProducts.map((product) => {
        return { ...product, brand_id: product.brand_id || reseller.brand_id };
      }),
      address_id: addressDataInput.id,
      brand_id: reseller.brand_id
    };

    await new ApplyGiftCardUseCase()
      .handle(payload)
      .then((data) => {
        const isValid = data.giftcard;

        if (isValid) {
          const newSummary = {
            ...summaryData,
            giftcard: data.giftcard,
            giftcard_discount: data.discount
          };

          setSummaryData(newSummary);
          onClose();
        } else {
          setError('Código inválido');
        }
      })
      .catch((e) => {
        console.log('Gifcard Err', e);
      })
      .finally(() => {
        finishLoading();
      });
  }

  const ClearButton = () => {
    if (!giftCardCode) return <></>;

    return (
      <IconButton
        sx={{ p: 0 }}
        onClick={() => {
          setError('');
          setGiftCardCode('');
        }}
      >
        <HighlightOffIcon color="primary" />
      </IconButton>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{ padding: '24px' }}
    >
      <DialogTitle>
        <ModalTitle>{title}</ModalTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <CustomInput
            error={!!error}
            name="giftcardcode"
            value={giftCardCode}
            label="Código do gift card"
            helperText={error}
            icon={<CardGiftcard color="primary" />}
            onChange={(e) => setGiftCardCode(e.target.value)}
            endIcon={<ClearButton />}
          />

          <ButtonsBox>
            {isScreenSmall && (
              <CancelButton
                variant="text"
                onClick={() => {
                  onClose();
                }}
                disabled={isLoading}
              >
                Cancelar
              </CancelButton>
            )}

            <ConfirmButton
              type="submit"
              variant="contained"
              onClick={() => handleAddGiftCard()}
              disabled={isLoading}
            >
              {isLoading ? 'Aguarde...' : 'Adicionar'}
            </ConfirmButton>
          </ButtonsBox>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
