/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Container } from '../../components/Container';
import { useLocalStorage } from 'hooks/useLocalStorage';

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography
} from '@mui/material';
import { useHeader } from '../../hooks/useHeaderContext';
import { toast } from 'react-toastify';
import { trackEvent } from 'config/services/adjustTracking';
import { eventChooseProduct } from 'config/services/gtmEvents';
import Loading from 'components/Loading';
import { ErrorOutline } from '@mui/icons-material';
import { useAddresses } from 'hooks/useAddressesContext';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import {
  ChosenProductsContext,
  IProductsState
} from 'providers/chosen-products-context/chosen-products-contex';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { ListProductsUseCase } from 'use-cases/products/list-products.use-case';
import { Category } from 'types/Category';
import { CategoriesList } from './components/CategoriesList/CategoriesList';
import { CartIcon } from 'components/Icons/CartIcon/cart-icon';
import { ProductObservations } from 'components/ProductObservations/product-obersavtions';

declare global {
  interface Window {
    dataLayer: Array<{ event: string }>;
  }
}

export default function ProductChoice() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setTitle, setSubtitle, setEditAddress } = useHeader();
  const { chosenProducts, setChosenProducts } = useContext(
    ChosenProductsContext
  );
  const [products, setProducts] = useState<IProductsState[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [isFetchedProducts, setisFetchedProducts] = useState<boolean>(false);

  const { setReseller } = useContext(ResellerContext);

  const { addressDataInput, setIsAddressModalOpen } = useAddresses();
  const { integration } = useContext(SourceIntegrationContext);

  const address = addressDataInput;

  const sourceIntegration = integration.source;

  const [, setCheckoutLastTime] = useLocalStorage('checkout_last_time', '');

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );

  const [firstFetch, setFirstFetch] = useState<boolean>(false);

  useEffect(() => {
    setCheckoutLastTime(new Date().toLocaleString('pt-Br'));
  }, []);

  useEffect(() => {
    if (!address) return setIsAddressModalOpen(true);
    setTitle(
      `${
        address.street
          ? `${address.street}, `
          : address?.address
          ? `${address.address.split(',')[0]}, `
          : ''
      }${address.number}`
    );
    setSubtitle('Endereço de entrega');
    setEditAddress(true);
  }, [address]);

  useEffect(() => {
    firstFetch && listProducts();
  }, [firstFetch]);

  async function listProducts() {
    if (!address) return setIsAddressModalOpen(true);

    setLoadingProducts(true);
    setChosenProducts([]);
    setReseller(null);

    const lat = address.latitude || address.lat;
    const long = address.longitude || address.long;

    await new ListProductsUseCase()
      .handle({
        latitude: lat,
        longitude: long,
        address_id: addressDataInput.id,
        category_id: selectedCategory?.id
      })
      .then((data) => {
        setProducts(data.products);
        setCategories(data.categories);
        if (!firstFetch) {
          setSelectedCategory(
            data.categories.find((item) => item.slug == 'gas') || null
          );
        }
        setFirstFetch(true);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao tentar buscar os produtos.', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .finally(() => {
        setLoadingProducts(false);
        setisFetchedProducts(true);
      });
  }

  useEffect(() => {
    listProducts();
  }, [selectedCategory, address]);

  useEffect(() => {
    // Close modal when component unmount
    return () => {
      setIsAddressModalOpen(false);
    };
  }, []);

  function handleProductChoice(product: IProductsState) {
    const chosenProductsUpdated = chosenProducts?.length
      ? chosenProducts.map((p) => {
          if (p.id === product.id) {
            return { ...p, quantity: ++p.quantity };
          }

          return p;
        })
      : [];

    const productAlreadyChosen =
      chosenProducts?.length && chosenProducts.find((p) => p.id === product.id);

    if (!productAlreadyChosen)
      chosenProductsUpdated.push({ ...product, quantity: 1 });

    setChosenProducts(chosenProductsUpdated);
    trackEvent({ eventToken: 'Escolheu produto', sourceIntegration });
    const chosenProcutsString = chosenProductsUpdated
      .map((p) => p.name + ': ' + p.quantity)
      .join(', ');

    eventChooseProduct([product], 'add', chosenProcutsString);
    navigate('/resellers');
  }

  return (
    <Container>
      <Stack mt={'40px'}>
        <CategoriesList
          categories={categories}
          onChange={(c) => setSelectedCategory(c)}
          selectedCategory={selectedCategory}
        />
      </Stack>
      <Box sx={{ mt: '20px' }}>
        <Box sx={{ mt: '14px', mb: '16px' }}>
          <Typography fontWeight={'400'} fontSize={'16px'}>
            Selecione o produto
          </Typography>
        </Box>
      </Box>
      <Box>
        {loadingProducts && <Loading />}
        {!loadingProducts && isFetchedProducts && products.length <= 0 && (
          <Box sx={{ textAlign: 'center' }}>
            <ErrorOutline
              fontSize="large"
              sx={{ mt: '40px', color: theme.palette.grey[400] }}
            />
            <Typography
              variant="subtitle2"
              sx={{ fontSize: '1rem', color: theme.palette.primary.dark }}
            >
              Sem revendas cadastradas
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.grey[400] }}
            >
              Atualmente não temos revendas no endereço informado
            </Typography>
          </Box>
        )}
        {!!products.length &&
          products.map((product) => (
            <Stack
              key={product.id}
              flexDirection={'column'}
              sx={{
                mb: '12px'
              }}
            >
              <Card
                onClick={() => handleProductChoice(product)}
                id="testep13"
                variant="custom"
                sx={{
                  height: '101px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  pl: '16px',
                  borderBottomLeftRadius: product.observation ? 0 : undefined,
                  borderBottomRightRadius: product.observation ? 0 : undefined
                }}
              >
                <CardMedia
                  component="img"
                  id="testep13"
                  image={product.picture}
                  alt="Live from space album cover"
                  sx={{ height: 44, width: 27.5, objectFit: 'contain' }}
                />
                <CardContent
                  id="testep13"
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingY: '0 !important',
                    pr: '0px'
                  }}
                >
                  <Box>
                    <Typography
                      id="testep13"
                      variant="subtitle2"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: '400'
                      }}
                    >
                      <Typography component={'span'} sx={{ fontWeight: '400' }}>
                        {product.name}
                      </Typography>{' '}
                    </Typography>
                    <Typography
                      id="testep13"
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: '400',
                        color: theme.palette.grey[600]
                      }}
                    >
                      {product.desc}
                    </Typography>
                    {product.details && (
                      <ProductObservations
                        product={product}
                        onSuccess={() => handleProductChoice(product)}
                      />
                    )}
                  </Box>
                </CardContent>
                <Stack
                  id={'testep13'}
                  height={'100%'}
                  width={'36px'}
                  bgcolor={theme.palette.green.xdark}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <CartIcon width={16} />
                </Stack>
              </Card>
              {product.observation && (
                <Stack
                  p={'4px 12px'}
                  border={`solid 1px #E0E0E0`}
                  borderTop={'none'}
                  sx={{
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px'
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '11px',
                      fontWeight: '400',
                      lineHeight: '14px',
                      color: theme.palette.grey[600]
                    }}
                  >
                    {product.observation}
                  </Typography>
                </Stack>
              )}
            </Stack>
          ))}
      </Box>
    </Container>
  );
}
