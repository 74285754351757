import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';

export const HeaderBar = styled(AppBar)`
  position: relative;
  height: 80px;
  justify-content: center;
  background: ${({ theme }) => {
    if (theme.palette.primary.gradient) {
      return theme.palette.primary.gradient;
    }

    return theme.palette.primary.dark;
  }};
`;
