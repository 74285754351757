import { Order } from 'types';
import { createSlice } from '@reduxjs/toolkit';
import { createOrder } from './orderThunk';

type InitialState = {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
  order: Order;
};

const initialState: InitialState = {
  loading: 'idle',
  error: '',
  order: {} as Order
};

export const orderSlice = createSlice({
  initialState,
  name: 'order',
  reducers: {
    reset(state) {
      state.order = initialState.order;
    }
  },
  extraReducers(builder) {
    builder.addCase(createOrder.fulfilled, (state, action) => {
      console.log('order created:', action.payload);
      state.order = action.payload;
    });
  }
});

export const { reset } = orderSlice.actions;

export default orderSlice.reducer;
