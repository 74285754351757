import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CardMedia,
  Card,
  CardContent,
  Typography,
  Box,
  useTheme,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { appGasService } from 'config/services/appgas';
import { toast } from 'react-toastify';
import * as S from './style';
import { useAddresses } from 'hooks/useAddressesContext';

export interface IModalProps {
  open: boolean;
  onClose: () => void;
  productsAlreadyChosen: string[];
  handleProductQuantity: (
    product: IProductsState,
    action: 'sub' | 'add'
  ) => void;
}

interface IProductsState {
  id: string;
  name: string;
  desc: string;
  picture: string;
  price: number;
  quantity: number;
}

export default function AddMoreProductModal(props: IModalProps) {
  const { onClose, open, handleProductQuantity, productsAlreadyChosen } = props;

  const { addressDataInput } = useAddresses();

  const address = addressDataInput;

  const theme = useTheme();
  const [products, setProducts] = useState<IProductsState[]>([]);

  useEffect(() => {
    if (!address) {
      return;
    }

    (async () => {
      try {
        const { data } = await appGasService.get(
          `/v2/integrations/products?${
            (address.longitude && address.latitude) ||
            (address.long &&
              address.lat &&
              `longitude=${address.longitude || address.long}&latitude=${
                address.latitude || address.lat
              }`)
          }`
        );

        setProducts(
          data.length
            ? (data as IProductsState[]).filter(
                (product) => !productsAlreadyChosen.includes(product.id)
              )
            : []
        );
      } catch (error) {
        console.log(error);

        toast.error('Ocorreu um erro ao tentar buscar os produtos.', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    })();
  }, [productsAlreadyChosen, address]);

  function handleProductChoice(product: IProductsState) {
    handleProductQuantity(product, 'add');
    onClose();
  }

  return (
    <Dialog maxWidth="md" fullWidth={true} onClose={onClose} open={open}>
      <DialogTitle
        sx={{ fontSize: '1.375', p: '16px', position: 'relative' }}
        color={theme.palette.primary.main}
      >
        Adicionar outros produtos
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            top: '12px',
            right: '16px',
            position: 'absolute',
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          {!!products.length &&
            products.map((product) => (
              <Card
                onClick={() => handleProductChoice(product)}
                key={product.id}
                variant="custom"
                sx={{
                  height: '86px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  mb: '8px',
                  p: '16px'
                }}
              >
                <CardMedia
                  component="img"
                  image={product.picture}
                  alt="Live from space album cover"
                  sx={{ height: 44, width: 27.5, objectFit: 'contain' }}
                />
                <CardContent
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingY: '0 !important'
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">{product.name}</Typography>
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                        color: theme.palette.text.secondary
                      }}
                    >
                      {product.desc}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <S.BuyButton
                      variant="contained"
                      onClick={() => handleProductChoice(product)}
                    >
                      Adicionar
                    </S.BuyButton>
                  </Box>
                </CardContent>
              </Card>
            ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
