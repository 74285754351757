import { Typography } from './style';
import { useTheme } from '@mui/material';

type Props = {
  error?: boolean;
  success?: boolean;
  children?: React.ReactNode;
};

export default function HelperText({ error, success, children }: Props) {
  const theme = useTheme();

  return (
    <>
      {error && (
        <Typography color={theme.palette.error.main}>{children}</Typography>
      )}

      {!error && success && (
        <Typography color={theme.palette.green.main}>{children}</Typography>
      )}

      {!success && !error && (
        <Typography color={theme.palette.grey[500]}>{children}</Typography>
      )}
    </>
  );
}
