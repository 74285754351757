import { createContext, Dispatch, SetStateAction } from 'react';

export type RatingContextData = {
  rating: number;
  setRating: Dispatch<SetStateAction<number>>;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
};

export const RatingContext = createContext<RatingContextData>(
  {} as RatingContextData
);
