/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { appGasService } from 'config/services/appgas';
import { IOrderData } from 'views/OrderTracking/types/IOrderData';
import { Card, CardHeader, Typography } from '@mui/material';
import { getOnlyHourFromDateTime } from 'helpers/getOnlyHourFromDateTime';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { differenceInHours, differenceInMinutes, parse } from 'date-fns';
import { useHeader } from 'hooks/useHeaderContext';
import {
  eventFinishedOrder,
  eventFinishedOrderWithCoupon,
  eventCanceledOrder
} from 'config/services/gtmEvents';
import { OrderContext } from 'providers/order-context/order.context';

type props = {
  orderId: string;
  couponCode: string;
  currency: string;
  items: string;
  paymentType: string;
  value: number;
  getStatus: (input: number) => void;
  getIsSeparatingOrder?: (input: boolean) => void;
};

export default function DeliveryTime({
  orderId,
  couponCode,
  currency,
  items,
  paymentType,
  value,
  getStatus,
  getIsSeparatingOrder
}: props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { clearOrder } = useHeader();
  const { setOrder } = useContext(OrderContext);

  const [currentStatus, setCurrentStatus] = useState(0);

  useEffect(() => {
    getStatus(currentStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  const { data: orderTracking, isLoading } = useQuery<IOrderData>(
    'order-details',
    async () => {
      const { data } = await appGasService.get(
        `/v2/integrations/orders/${orderId}`
      );
      setOrder(data);
      return data;
    },
    { refetchInterval: 10000 }
  );

  useEffect(() => {
    // Limpar o cache quando o componente for desmontado
    return () => {
      queryClient.removeQueries('order-details');
    };
  }, [queryClient]);

  const statusOrder: { [key: string]: string } = {
    waiting: 'waiting',
    accepted: 'accepted',
    driving: 'driving',
    finish: 'finish',
    processing: 'processing',
    expired: 'expired'
  };

  const canceledStatus = [
    'user_canceled',
    'admin_canceled',
    'driver_canceled',
    'user_cancelled',
    'admin_cancelled',
    'driver_cancelled',
    'manager_cancelled',
    'accept_expired'
  ];

  const isSeparating =
    orderTracking &&
    orderTracking.accepted_at &&
    ((currentStatus as number) === 2 || (currentStatus as number) === 1) &&
    differenceInMinutes(
      new Date(),
      parse(orderTracking.accepted_at, 'dd/MM/yyyy HH:mm', new Date())
    ) < 5;

  useEffect(() => {
    getIsSeparatingOrder && getIsSeparatingOrder(isSeparating ? true : false);
  }, [isSeparating, getIsSeparatingOrder]);

  const backgrounds: {
    [key: number]: {
      first: any;
      second: any;
      third: any;
    };
  } = {
    0: {
      first: {
        background: '#3968A6'
      },
      second: {
        background: '#E0E0E0'
      },
      third: {
        background: '#E0E0E0'
      }
    },
    1: {
      first: {
        background: 'rgba(76,184,64,1)'
      },
      second: {
        backgroundColor: isSeparating ? '#3968A6' : 'rgb(76,184,64)',
        background: isSeparating
          ? 'linear-gradient(90deg, rgba(76,184,64,1) 0%, rgba(224,224,224,1) 63%)'
          : undefined
      },
      third: {
        backgroundColor: isSeparating ? '#E0E0E0' : 'rgb(76,184,64)',
        background: isSeparating
          ? undefined
          : 'linear-gradient(90deg, rgba(76,184,64,1) 0%, rgba(224,224,224,1) 63%)'
      }
    },
    2: {
      first: {
        background: 'rgba(76,184,64,1)'
      },
      second: {
        backgroundColor: isSeparating ? '#3968A6' : 'rgb(76,184,64)',
        background: isSeparating
          ? 'linear-gradient(90deg, rgba(76,184,64,1) 0%, rgba(224,224,224,1) 63%)'
          : undefined
      },
      third: {
        backgroundColor: isSeparating ? '#E0E0E0' : 'rgb(76,184,64)',
        background: isSeparating
          ? undefined
          : 'linear-gradient(90deg, rgba(76,184,64,1) 0%, rgba(224,224,224,1) 63%)'
      }
    }
  };

  const statusOrderList = Object.values(statusOrder);

  function checkRatingTimeIsExpired() {
    if (orderTracking?.finished_at) {
      const today = new Date();
      const HOURS_DIFFERENCE_TO_CANCEL_RATING = 24;
      const finishedAt = orderTracking.finished_at.split(' ')[0].split('/');
      const finishedDate = finishedAt && new Date(finishedAt.toString());

      const differenceTodayAndFinished = differenceInHours(finishedDate, today);

      const isExpired =
        differenceTodayAndFinished >= HOURS_DIFFERENCE_TO_CANCEL_RATING;

      return isExpired;
    }
  }

  useEffect(() => {
    if (!isLoading && orderTracking) {
      if (orderTracking.rated || checkRatingTimeIsExpired()) {
        clearOrder();
      }
      if (canceledStatus.includes(orderTracking.status)) {
        eventCanceledOrder(couponCode, currency, items, paymentType, value);
        navigate('/order-cancelled');
      }
      if (orderTracking.status === 'expired') {
        navigate('/order-cancelled?cancelledBy=order_expired');
      }
      if (orderTracking.status === 'finish') {
        if (couponCode) {
          eventFinishedOrderWithCoupon(
            couponCode,
            currency,
            items,
            paymentType,
            value
          );
        } else {
          eventFinishedOrder(couponCode, currency, items, paymentType, value);
        }
        navigate('/order-rating');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTracking]);

  useEffect(() => {
    if (!isLoading && orderTracking) {
      const currentStatusIndex = statusOrderList.findIndex(
        (item) => item === statusOrder[orderTracking.status]
      );

      if (orderTracking.rating || checkRatingTimeIsExpired()) {
        clearOrder();
      } else if (canceledStatus.includes(orderTracking.status)) {
        navigate('/order-cancelled');
      } else if (orderTracking.status === 'finish') {
        console.log('order:status', orderTracking.status);
        navigate('/order-rating');
      } else {
        // Atualiza o estado do componente para garantir que a interface seja renderizada corretamente
        setCurrentStatus(currentStatusIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTracking, isLoading]);

  // function currentStatus() {
  //   if (!isLoading && orderTracking) {
  //     const status = resellerWithFakeDriver(orderTracking);
  //     const currentStatus = statusOrderList.findIndex(
  //       (item) => item === statusOrder[status]
  //     );
  //     if (canceledStatus.includes(orderTracking.status)) {
  //       navigate('/order-cancelled');
  //     }
  //     return currentStatus;
  //   }
  // }

  function deliveryTimeEstimate() {
    if (!isLoading && orderTracking) {
      const { delivery_time_initial, delivery_time_final } = orderTracking;

      if (delivery_time_initial && delivery_time_final) {
        return `${getOnlyHourFromDateTime(
          orderTracking.delivery_time_initial
        )} -  ${getOnlyHourFromDateTime(orderTracking.delivery_time_final)}`;
      }
    }
  }

  return (
    <Box sx={{ mt: '16px' }}>
      <Card
        variant="custom"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <CardHeader
          title={
            <Box>
              <Box
                sx={{
                  mb: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AccessTimeIcon
                    sx={{ mr: '10px', fontSize: '18px' }}
                    color="primary"
                  />
                  {(currentStatus as number) === 0 ? (
                    <Typography>Aguardando aceite do pedido</Typography>
                  ) : isSeparating ? (
                    <Typography>Separando seu pedido</Typography>
                  ) : (
                    <Typography>Estimativa de entrega</Typography>
                  )}
                </Box>
                {!isLoading && !orderTracking?.canceled_at && (
                  <Typography sx={{ color: '#616161', fontWeight: 700 }}>
                    {(currentStatus as number) === 4
                      ? 'Processando pagamento'
                      : deliveryTimeEstimate()}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    mr: '8px',
                    width: '15%',
                    height: '4px',
                    borderRadius: '5px',
                    ...backgrounds[currentStatus as number]?.first
                  }}
                />
                <Box
                  sx={{
                    mr: '8px',
                    width: '15%',
                    height: '4px',
                    borderRadius: '5px',
                    ...backgrounds[currentStatus as number]?.second
                  }}
                />
                <Box
                  sx={{
                    width: '85%',
                    height: '4px',
                    borderRadius: '5px',
                    ...backgrounds[currentStatus as number]?.third
                  }}
                />
              </Box>

              <Box sx={{ mt: '12px', color: '#212429' }}>
                {(currentStatus as number) === 0 && (
                  <Box sx={{ fontSize: '12px' }}>
                    <Box sx={{ fontWeight: 500 }}>
                      Aguardando a revenda aceitar o pedido
                    </Box>
                    <Box sx={{ fontWeight: 300, color: '#757575' }}>
                      O aceite do seu pedido pode levar até 10 minutos.
                    </Box>
                  </Box>
                )}

                {(currentStatus as number) === (1 || 2) && (
                  <Box sx={{ fontSize: '12px' }}>
                    {isSeparating ? (
                      <>
                        <Box sx={{ fontWeight: 500 }}>
                          Seu pedido está sendo separado
                        </Box>
                        <Box sx={{ fontWeight: 300, color: '#757575' }}>
                          Após essa ação seu pedido sairá para entrega.
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box sx={{ fontWeight: 500 }}>
                          Seu pedido saiu para entrega
                        </Box>
                        <Box sx={{ fontWeight: 300, color: '#757575' }}>
                          O entregador já está em rota para o seu endereço.
                        </Box>
                      </>
                    )}
                  </Box>
                )}

                {(currentStatus as number) === 4 && (
                  <Box sx={{ fontSize: '12px' }}>
                    <Box sx={{ fontWeight: 500 }}>
                      Seu pedido está sendo processado
                    </Box>
                    <Box sx={{ fontWeight: 300, color: '#757575' }}>
                      Após pagamento com pix, a confirmação pode levar até 5
                      minutos.
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          }
        />
      </Card>
    </Box>
  );
}
