import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface ConfirmationCodeModalProps {
  open: boolean;
  onClose: () => void;
}

export function ConfirmationCodeModal(props: ConfirmationCodeModalProps) {
  const { onClose, open } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{ padding: '24px' }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          sx={{
            fontSize: '1.375rem',
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightLight
          }}
        >
          {isSmallScreen ? '' : ' Use o código e confirme seu pedido'}
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {isSmallScreen && (
          <Typography
            sx={{
              fontSize: '16px',
              color: theme.palette.primary.xxdark,
              fontWeight: '600',
              lineHeight: '18px',
              mb: '10px'
            }}
          >
            Use o código e confirme seu pedido
          </Typography>
        )}
        <Stack
          direction={'row'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          gap={'8px'}
        >
          <Stack>
            <Stack
              bgcolor={'#9E9E9E'}
              width={'4px'}
              height={'4px'}
              borderRadius={'100px'}
              mt={'8px'}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: '0.9rem',
              color: '#9E9E9E'
            }}
          >
            O código de entrega é uma medida de segurança para os clientes e
            entregadores, e garante que o pedido foi entregue.
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          gap={'8px'}
          mt={'7px'}
        >
          <Stack>
            <Stack
              bgcolor={'#9E9E9E'}
              width={'4px'}
              height={'4px'}
              borderRadius={'100px'}
              mt={'8px'}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: '0.9rem',
              color: '#9E9E9E'
            }}
          >
            Os 4 digitos do código são gerados de forma aleatória.
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          gap={'8px'}
          mt={'7px'}
        >
          <Stack>
            <Stack
              bgcolor={'#9E9E9E'}
              width={'4px'}
              height={'4px'}
              borderRadius={'100px'}
              mt={'8px'}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: '0.9rem',
              color: '#9E9E9E'
            }}
          >
            Compartilhe com o entregador presencialmente, na hora da entrega do
            pedido (Nunca compartilhe antes!).
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
