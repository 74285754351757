import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useTheme,
  IconButton,
  useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from './style';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { eventSmsCode, eventWppCode } from 'config/services/gtmEvents';

import { SendCodeAppUserUseCase } from 'use-cases/appuser/send-code-appuser.use-case';
import { useLoadingState } from 'hooks/useLoadingState';

export interface IModalProps {
  open: boolean;
  onClose: () => void;
  phoneNumber: string;
}

export default function SendCodeVerificationModal(props: IModalProps) {
  const { onClose, open, phoneNumber } = props;
  const { finishLoading, isLoading, startLoading } = useLoadingState();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleSendCodeVerification = async (gateway: 'WHATSAPP' | 'SMS') => {
    if (gateway === 'SMS') {
      eventSmsCode();
    } else {
      eventWppCode();
    }

    startLoading();

    await new SendCodeAppUserUseCase()
      .handle({
        gateway,
        phone: phoneNumber
      })
      .then(() => {
        toast.success(`Código enviado por ${gateway}`, {
          autoClose: 5000,
          draggable: true,
          theme: 'colored',
          closeOnClick: true,
          pauseOnHover: true,
          closeButton: false,
          hideProgressBar: false,
          position: isSmallScreen ? 'top-center' : 'bottom-center',
          icon: () => <CheckCircleOutlineIcon />
        });
        navigate(`/check-phone?gateway=${gateway}`);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        finishLoading();
      });
  };

  return (
    <Dialog maxWidth="sm" fullWidth={true} onClose={onClose} open={open}>
      <DialogTitle
        sx={{
          p: '16px 24px',
          position: 'relative',
          fontWeight: '400',
          [theme.breakpoints.down('md')]: {
            fontSize: '1rem'
          }
        }}
        color={theme.palette.primary.main}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>Canal de recebimento do SMS</Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Button
            sx={{ textTransform: 'inherit', ml: '8px' }}
            onClick={() => handleSendCodeVerification('WHATSAPP')}
            disabled={true}
          >
            Aguarde...
          </Button>
        ) : (
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Button
              sx={{ textTransform: 'inherit' }}
              onClick={() => handleSendCodeVerification('WHATSAPP')}
            >
              Whatsapp
            </Button>
            <Button onClick={() => handleSendCodeVerification('SMS')}>
              SMS
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
