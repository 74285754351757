/* eslint-disable react-hooks/exhaustive-deps */
import {
  ButtonsBox,
  IconButton,
  ModalTitle,
  CancelButton,
  ConfirmButton,
  ModalSubTitle
} from './styles';

import * as Yup from 'yup';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import CustomInput from 'components/CustomInput';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import { toPattern } from 'vanilla-masker';
import { useCheckout } from 'views/Review/hooks/useCheckoutContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import ProcessingModal from './components/ProcessingModal';
import { useAppDispatch } from 'store';
import { createOrder } from 'store/order/orderThunk';
import { toast } from 'react-toastify';
import { appGasService } from 'config/services/appgas';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from 'config/services/adjustTracking';
import { isValidCPF } from 'helpers/validate-cpf';
import Grid from '@mui/material/Grid';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { SummaryContext } from 'providers/summary-context/summary.context';
import { useAddresses } from 'hooks/useAddressesContext';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { OrderContext } from 'providers/order-context/order.context';
import { Order } from 'types';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

const picpayFormSchema = Yup.object({
  cpf: Yup.string().required(),
  firstName: Yup.string(),
  lastName: Yup.string()
}).required();

type PicpayFormData = Yup.InferType<typeof picpayFormSchema>;

export default function JeittoModal(props: ModalProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [checkStatusCount, setCheckStatusCount] = useState(0);

  const { paymentId } = useCheckout();

  const { integration } = useContext(SourceIntegrationContext);
  const sourceIntegration = integration.source;

  const { control, handleSubmit, watch } = useForm<PicpayFormData>({
    defaultValues: {
      cpf: '',
      firstName: '',
      lastName: ''
    },
    resolver: yupResolver(picpayFormSchema)
  });

  const watchCpf = watch('cpf');

  const { onClose, open } = props;

  const [processingModalOpen, setProcessingModalOpen] = useState(false);

  const { cpf, setCpf, firstName, setFirstName, lastName, setLastName } =
    useCheckout();

  const { summaryData } = useContext(SummaryContext);

  const { addressDataInput } = useAddresses();

  const { reseller } = useContext(ResellerContext);

  const { chosenProducts } = useContext(ChosenProductsContext);

  const { leadData } = useContext(LeadDataContext);

  const { order, setOrder } = useContext(OrderContext);

  const checkStatusCallback = useCallback(
    async (didCancel = false) => {
      if (!didCancel) {
        if (order?.id && checkStatusCount <= 30) {
          const { data } = await appGasService.get(
            `/v2/integrations/orders/${order?.id}`
          );

          if (data.status === 'failed') {
            const message =
              data.payment_failure_reason ||
              'Ocorreu um erro ao tentar criar o pedido.';

            toast.error(message, {
              position: 'bottom-center',
              autoClose: 5000,
              draggable: true,
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: false
            });

            setProcessingModalOpen(false);

            return;
          }

          if (data.status === 'canceled') {
            return navigate('/order-tracking');
          }

          if (data.status === 'waiting') {
            return navigate('/order-tracking');
          }

          await new Promise((resolve) => setTimeout(resolve, 15000));

          setCheckStatusCount(checkStatusCount + 1);

          await checkStatusCallback(didCancel);
        }
      }
    },
    [order?.id, navigate, checkStatusCount]
  );

  useEffect(() => {
    let didCancel = false;

    checkStatusCallback(didCancel);

    return () => {
      didCancel = true;
    };
  }, [order?.id]);

  async function handleProccessOrder(data: PicpayFormData) {
    if (!addressDataInput || !reseller) {
      return;
    }

    try {
      const { cpf } = data;

      setProcessingModalOpen(true);
      trackEvent({ eventToken: 'Solicitação em Andamento', sourceIntegration });

      const dispatchCreateOrder = await dispatch(
        createOrder({
          cpf,
          firstName,
          lastName,
          paymentId,
          sourceIntegration,
          addressId: addressDataInput?.id,
          companyId: reseller.id,
          salesChannel: 'compraweb',
          name: leadData.name,
          phone: leadData.phone,
          productIds: chosenProducts.map((product) => {
            return {
              ...product,
              brand_id: product.brand_id || reseller.brand_id
            };
          }),
          coupon: summaryData?.coupon || '',
          brand_id: reseller.brand_id
        })
      ).unwrap();

      const order = {
        id: dispatchCreateOrder.id,
        driver: dispatchCreateOrder.driver,
        total: dispatchCreateOrder.total,
        payment_method: dispatchCreateOrder.payment_method,
        change: dispatchCreateOrder.change,
        order_number: dispatchCreateOrder.order_number,
        address: dispatchCreateOrder.address
      };

      setOrder(order as Order);

      await checkStatusCallback();
    } catch (error) {
      const typedError = error as Error;

      setProcessingModalOpen(false);
      trackEvent({ eventToken: 'Crédito Não Aprovado', sourceIntegration });

      toast.error(
        typedError?.message || 'Ocorreu um erro ao tentar criar o pedido.',
        {
          position: 'bottom-center',
          autoClose: 5000,
          draggable: true,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: false
        }
      );
    }
  }

  function formatCPF(value: string) {
    if (value) {
      return toPattern(value, '999.999.999-99');
    }

    return value;
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={onClose}
        sx={{ padding: '24px' }}
      >
        <DialogTitle>
          <ModalTitle>Dados necessários</ModalTitle>

          <ModalSubTitle>
            Precisamos dos seguintes dados para poder avançar.
          </ModalSubTitle>

          <IconButton aria-label="close-modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(handleProccessOrder)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      name="change"
                      label="Primeiro nome"
                      value={firstName}
                      icon={<AccountCircleOutlinedIcon color="primary" />}
                      onChange={(input) => {
                        const value = input.target.value;

                        const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z_ ]*$/;

                        if (
                          value !== '' &&
                          !ALPHA_NUMERIC_DASH_REGEX.test(value)
                        ) {
                          return;
                        }

                        field.onChange(input);
                        setFirstName(input.target.value);
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      name="change"
                      label="Último nome"
                      value={lastName}
                      icon={<AccountCircleOutlinedIcon color="primary" />}
                      onChange={(input) => {
                        const value = input.target.value;

                        const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z_ ]*$/;

                        if (
                          value !== '' &&
                          !ALPHA_NUMERIC_DASH_REGEX.test(value)
                        ) {
                          return;
                        }

                        field.onChange(input);
                        setLastName(input.target.value);
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item md={12}>
                <Controller
                  name="cpf"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      name="change"
                      label="CPF"
                      value={formatCPF(cpf)}
                      icon={<AccountCircleOutlinedIcon color="primary" />}
                      onChange={(input) => {
                        field.onChange(input);
                        setCpf(formatCPF(input.target.value));
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <ButtonsBox>
              <CancelButton variant="text" onClick={() => onClose()}>
                Cancelar
              </CancelButton>

              <ConfirmButton
                type="submit"
                disabled={!isValidCPF(watchCpf)}
                variant="contained"
              >
                Confirmar
              </ConfirmButton>
            </ButtonsBox>
          </form>
        </DialogContent>
      </Dialog>

      <ProcessingModal
        open={processingModalOpen}
        onClose={() => setProcessingModalOpen(false)}
      />
    </>
  );
}
