import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Address = {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
};

export const addressSlice = createSlice({
  name: 'address',
  initialState: {
    error: '',
    loading: false,
    address: {} as Address,
    addresses: [] as Address[]
  },
  reducers: {
    reset(state) {
      state.error = '';
      state.loading = false;
      state.address = {} as Address;
      state.addresses = [] as Address[];
    },
    addresses(state, action: PayloadAction<Address[]>) {
      state.error = '';
      state.loading = false;
      state.addresses = action.payload;
    },
    error(state, action: PayloadAction<string>) {
      state.addresses = [];
      state.loading = false;
      state.error = action.payload;
    },
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  }
});

export const { addresses, error, loading, reset } = addressSlice.actions;

export default addressSlice.reducer;
