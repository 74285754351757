import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Container } from 'components/Container';
import { Stepper } from 'components/Stepper';
import { DeliveryInfos } from './components/delivery-infos/delivery-infos';
import { CartProductsList } from './components/cart-products-list/cart-products-list';
import PreviousAndNextButtons from 'components/PreviousAndNextButtons';
import { useNavigate } from 'react-router-dom';
import { TotalPrice } from './components/total-price/total-price';
import { useContext, useEffect, useState } from 'react';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { useAddresses } from 'hooks/useAddressesContext';
import { appGasService } from 'config/services/appgas';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import axios from 'axios';
import { formatPrice } from 'helpers/formatPrice';
import { AdditionalOffers } from 'components/AdditionalOffers/additional-offers';
import { useHeader } from 'hooks/useHeaderContext';
import { readStorage } from 'hooks/useLocalStorage';
import { ISummaryData } from 'views/Review/types/ISummaryData';

export default function Cart() {
  const { palette, breakpoints } = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));
  const { reseller } = useContext(ResellerContext);
  const [isSummaryLoading, setIsSummayLoading] = useState<boolean>(false);
  const { addressDataInput, createAddress } = useAddresses();
  const { chosenProducts, setChosenProducts } = useContext(
    ChosenProductsContext
  );
  const customerToken = readStorage('customer_token');
  const [summaryData, setSummaryData] = useState<ISummaryData | null>(null);
  const { setTitle, setEditAddress } = useHeader();

  useEffect(() => {
    setTitle('Detalhes do pedido');
    setEditAddress(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!customerToken) return navigate('/create-lead');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToken]);

  const fetchSummary = async () => {
    if (!reseller || !reseller.id) {
      return;
    }

    setIsSummayLoading(true);
    let addressId = null;

    try {
      if (!addressDataInput?.id) {
        await createAddress().then((data) => {
          addressId = data.id;
        });
      }

      const { data } = await appGasService.post(
        `/v3/integrations/orders/summary`,
        {
          company_id: reseller.id,
          address_id: addressDataInput?.id || addressId,
          coupon: '',
          product_ids: chosenProducts.map((product) => {
            return {
              ...product,
              brand_id: product.brand_id || reseller.brand_id
            };
          }),
          sales_channel: 'compraweb',
          brand_id: reseller.brand_id,
          giftcard: ''
        }
      );

      setSummaryData(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error?.response?.data?.message);
      }
    } finally {
      setIsSummayLoading(false);
    }
  };

  useEffect(() => {
    fetchSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProducts]);

  return (
    <Container>
      <Stack direction={'column'} gap={'16px'}>
        <Box
          sx={{
            mt: '2rem',
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '8px',
            border: '1px solid #e0e0e0'
          }}
        >
          <Stepper
            totalSteps={4}
            currentStep={3}
            title={'Meu carrinho'}
            showStepInfo={true}
          />
        </Box>
        <DeliveryInfos />
        <CartProductsList />
        {reseller?.products[0]?.zone_id && (
          <AdditionalOffers
            setChosenProducts={setChosenProducts}
            chosenProducts={chosenProducts}
            zone_id={reseller?.products[0]?.zone_id}
          />
        )}
        {!isSmallScreen && (
          <>
            <Divider
              sx={{ mt: '8px', mb: '8px', borderColor: palette.grey[300] }}
            />
            <TotalPrice
              totalPrice={summaryData?.subtotal}
              isLoading={isSummaryLoading}
            />
          </>
        )}
        <Box
          sx={{
            position: 'sticky',
            bottom: 0
          }}
        >
          <PreviousAndNextButtons
            previousActionText={'Avançar'}
            nextAction={async () => {
              navigate('/checkout');
            }}
            previousActionButtonColor={palette.primary.main}
            previousAction={() => navigate('/resellers')}
          >
            {isSmallScreen && (
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                pb={'20px'}
                alignItems={'center'}
              >
                <Typography
                  sx={{ fontSize: '12px' }}
                  variant="caption"
                  lineHeight={'11px'}
                  color={palette.grey[600]}
                  fontWeight={'600'}
                >
                  Total do pedido:
                </Typography>
                {isSummaryLoading ? (
                  <Skeleton variant="text" width={'40px'} sx={{ p: '0' }} />
                ) : (
                  <Typography
                    sx={{ fontSize: '12px' }}
                    variant="caption"
                    lineHeight={'11px'}
                    color={palette.grey[800]}
                    fontWeight={'600'}
                  >
                    {summaryData?.subtotal && formatPrice(summaryData.subtotal)}
                  </Typography>
                )}
              </Stack>
            )}
          </PreviousAndNextButtons>
        </Box>
      </Stack>
    </Container>
  );
}
