import TagManager from 'react-gtm-module';
import { AddressInputData } from 'views/SaveAddress/types';
import moengage from '@moengage/web-sdk';
import { IProductsState } from 'providers/chosen-products-context/chosen-products-contex';

export const eventChooseProduct = (
  items: IProductsState[],
  action: 'sub' | 'add',
  moEngageString: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: action === 'add' ? 'add_to_cart' : 'remove_from_cart',
      items: mountItemsObject(items)
    }
  });
  moengage.track_event('add_to_cart', { items: moEngageString });
};

export const eventInputAddress = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'input_address'
    }
  });
  moengage.track_event('input_address');
};

export const eventChooseAddress = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'choose_address'
    }
  });
  moengage.track_event('choose_address');
};

export const eventFinishAddress = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_shipping_info'
    }
  });
  moengage.track_event('add_shipping_info');
};

export const eventAddToCart = (items: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_cart',
      items: items
    }
  });
  moengage.track_event('add_to_cart', { items: items });
};

export const eventBeginCheckout = (
  items: IProductsState[],
  value: number,
  moEngageString: string,
  moEngageValue: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'begin_checkout',
      items: mountItemsObject(items),
      value: value
    }
  });
  moengage.track_event('begin_checkout', {
    items: moEngageString,
    value: moEngageValue
  });
};

export const eventAllResellersClosed = (
  address: AddressInputData,
  hour: Date
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'all_resellers_closed',
      address: address,
      hour: hour
    }
  });
  moengage.track_event('all_resellers_closed', {
    address: address,
    hour: hour
  });
};

export const eventClosedResellers = (address: AddressInputData, hour: Date) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'closed_resellers',
      address: address,
      hour: hour
    }
  });
};

export const eventNoResellers = (state: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'no_resellers',
      state: state
    }
  });
  moengage.track_event('no_resellers', {
    state: state
  });
};

export const eventNoResellersAvaiable = (state: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'no_resellers_avaiable',
      state: state
    }
  });
};

export const eventInputPhone = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'input_phone'
    }
  });
  moengage.track_event('input_phone');
};

export const eventInputName = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'input_name'
    }
  });
  moengage.track_event('input_name');
};

export const eventSmsCode = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sms_code'
    }
  });
  moengage.track_event('sms_code');
};

export const eventWppCode = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'wpp_code'
    }
  });
  moengage.track_event('wpp_code');
};

export const eventCodeConfirmed = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'generate_lead'
    }
  });
  moengage.track_event('generate_lead');
};

export const eventResendSMSCode = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'resend_sms'
    }
  });
  moengage.track_event('resend_sms');
};

export const eventResendWPPCode = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'resend_wpp'
    }
  });
  moengage.track_event('resend_wpp');
};

export const eventInvalidSMSCode = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'invalid_sms'
    }
  });
  moengage.track_event('invalid_sms');
};

export const eventInvalidWPPCode = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'invalid_wpp'
    }
  });
  moengage.track_event('invalid_wpp');
};

export const eventAddPaymentInfo = (
  coupon: string,
  curreny: string,
  items: IProductsState[],
  payment_type: string,
  value: number,
  moEngageString: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_payment_info',
      coupon: coupon,
      currency: curreny,
      items: mountItemsObject(items),
      payment_type: payment_type,
      value: value
    }
  });
  moengage.track_event('add_payment_info', {
    coupon: coupon,
    currency: curreny,
    items: moEngageString,
    payment_type: payment_type,
    value: value
  });
};

export const eventAddCoupon = (coupon: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_coupon',
      coupon: coupon
    }
  });
  moengage.track_event('add_coupon', {
    coupon: coupon
  });
};

export const eventInvalidCoupon = (coupon: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'invalid_coupon',
      coupon: coupon
    }
  });
  moengage.track_event('invalid_coupon', {
    coupon: coupon
  });
};

export const eventPurchase = (
  coupon: string,
  payment_type: string,
  value: number,
  orderNumber: number,
  items: IProductsState[]
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      coupon: coupon || '',
      payment_type: payment_type,
      value: value,
      items: mountItemsObject(items),
      transactionId: orderNumber
    }
  });
  moengage.track_event('purchase', {
    coupon: coupon,
    payment_type: payment_type,
    value: value
  });
};

export const eventErrorOrder = (message: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'error_order_process',
      message: message
    }
  });
};

export const eventInProgressOrder = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'in_progress_order'
    }
  });
  moengage.track_event('in_progress_order');
};

export const eventFinishedOrder = (
  coupon: string,
  currency: string,
  items: string,
  payment_type: string,
  value: number
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'finished_order',
      coupon: coupon,
      currency: currency,
      items: items,
      payment_type: payment_type,
      value: value
    }
  });
  moengage.track_event('finished_order', {
    coupon: coupon,
    currency: currency,
    items: items,
    payment_type: payment_type,
    value: value
  });
};

export const eventCanceledOrder = (
  coupon: string,
  currency: string,
  items: string,
  payment_type: string,
  value: number
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'canceled_order',
      coupon: coupon,
      currency: currency,
      items: items,
      payment_type: payment_type,
      value: value
    }
  });
  moengage.track_event('canceled_order', {
    coupon: coupon,
    currency: currency,
    items: items,
    payment_type: payment_type,
    value: value
  });
};
export const eventFinishedOrderWithCoupon = (
  coupon: string,
  currency: string,
  items: string,
  payment_type: string,
  value: number
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'finished_order_with_coupon',
      coupon: coupon,
      currency: currency,
      items: items,
      payment_type: payment_type,
      value: value
    }
  });
  moengage.track_event('finished_order_with_coupon', {
    coupon: coupon,
    currency: currency,
    items: items,
    payment_type: payment_type,
    value: value
  });
};

const mountItemsObject = (items: IProductsState[]) => {
  return items.map((item) => {
    return {
      name: item?.name || '',
      item_id: item?.id || '',
      price: item?.price || 0
    };
  });
};
