import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import {
  IconButton,
  ButtonsBox,
  ModalTitle,
  CancelButton,
  ConfirmButton
} from './styles';
import { Order } from 'types';

interface IModalProps {
  open: boolean;
  setOpenReasonsModal: () => void;
  onClose: () => void;
  order: Order;
}

export default function CancelModal(props: IModalProps) {
  const { onClose, open, setOpenReasonsModal, order } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function openRocketChat() {
    window.dispatchEvent(new Event('openRocketChat'));
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{ padding: '24px' }}
    >
      <DialogTitle>
        <ModalTitle>Mais ações</ModalTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <ButtonsBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto'
            }}
          >
            <CancelButton
              sx={{ margin: '0' }}
              color="error"
              variant="outlined"
              onClick={() => {
                setOpenReasonsModal();
                onClose();
              }}
              disabled={!order.permitted_cancel}
            >
              CANCELAR PEDIDO
            </CancelButton>

            {isSmallScreen && (
              <ConfirmButton
                variant="outlined"
                href={`tel:+55${order.driver.phone}`}
              >
                Ligar para a revenda
              </ConfirmButton>
            )}

            <ConfirmButton variant="contained" onClick={() => openRocketChat()}>
              Falar com o Appgas
            </ConfirmButton>
          </ButtonsBox>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
