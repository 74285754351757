import { HeaderStepper } from './styles';
import { Stepper } from 'components/Stepper';
import { Container } from 'components/Container';
import FormCNPJ from './components/FormCNPJ';
import { Box } from '@mui/material';

export default function CheckCNPJ() {
  return (
    <Container>
      <HeaderStepper>
        <Stepper
          totalSteps={3}
          currentStep={1}
          showStepInfo={true}
          title={'Dados da empresa'}
        />
      </HeaderStepper>

      <Box sx={{ mt: '16px' }}>
        <FormCNPJ />
      </Box>
    </Container>
  );
}
