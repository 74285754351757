import { createContext } from 'react';
import { LeadInputData } from 'views/CreateLead/types';

export interface LeadDataContextData {
  leadData: LeadInputData;
  temporaryPhone: string;
  isLoadingUserData: boolean;
  setLeadData: (input: LeadInputData) => void;
  setTemporaryPhone: (input: string) => void;
  logoutUser: () => void;
}

export const LeadDataContext = createContext<LeadDataContextData>(
  {} as LeadDataContextData
);
