interface IProps {
  width: number;
  height: number;
  color?: string;
}

export function MoneyIcon(props: IProps) {
  const { color } = props;
  return (
    <svg
      {...props}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33366 0.666626H15.3337V8.66663H3.33366V0.666626ZM9.33366 2.66663C9.86409 2.66663 10.3728 2.87734 10.7479 3.25241C11.1229 3.62749 11.3337 4.13619 11.3337 4.66663C11.3337 5.19706 11.1229 5.70577 10.7479 6.08084C10.3728 6.45591 9.86409 6.66663 9.33366 6.66663C8.80323 6.66663 8.29452 6.45591 7.91945 6.08084C7.54437 5.70577 7.33366 5.19706 7.33366 4.66663C7.33366 4.13619 7.54437 3.62749 7.91945 3.25241C8.29452 2.87734 8.80323 2.66663 9.33366 2.66663ZM6.00033 1.99996C6.00033 2.35358 5.85985 2.69272 5.6098 2.94277C5.35975 3.19282 5.02061 3.33329 4.66699 3.33329V5.99996C5.02061 5.99996 5.35975 6.14044 5.6098 6.39048C5.85985 6.64053 6.00033 6.97967 6.00033 7.33329H12.667C12.667 6.97967 12.8075 6.64053 13.0575 6.39048C13.3076 6.14044 13.6467 5.99996 14.0003 5.99996V3.33329C13.6467 3.33329 13.3076 3.19282 13.0575 2.94277C12.8075 2.69272 12.667 2.35358 12.667 1.99996H6.00033ZM0.666992 3.33329H2.00033V9.99996H12.667V11.3333H0.666992V3.33329Z"
        fill={color ? color : '#000'}
      />
    </svg>
  );
}
