import * as Yup from 'yup';
import SearchIcon from '@mui/icons-material/Search';

import { useForm, Controller } from 'react-hook-form';

import { hideVirtualKeyboard } from './hideKeyboard';
import { trackEvent } from 'config/services/adjustTracking';
import CustomInput from '../../../../../../components/CustomInput';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useAddresses } from 'hooks/useAddressesContext';
import { eventInputAddress } from 'config/services/gtmEvents';
import { useContext } from 'react';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';

const searchFormSchema = Yup.object({
  address: Yup.string()
});

type SearchAddressFormData = Yup.InferType<typeof searchFormSchema>;

export function SearchAddressForm() {
  let timeoutRef = 0;

  const { clearList, searchAddress, addresses } = useAddresses();
  const { integration } = useContext(SourceIntegrationContext);
  const sourceIntegration = integration.source;

  const { control, handleSubmit, setValue } = useForm<SearchAddressFormData>({
    defaultValues: {
      address: ''
    }
  });

  function clearAll() {
    clearList();
    setValue('address', '');
  }

  async function handleSearchAddress(data: SearchAddressFormData) {
    const { address } = data;

    if (address?.length && address.length >= 2) {
      if (timeoutRef) clearTimeout(timeoutRef);

      timeoutRef = setTimeout(async () => {
        searchAddress(address);
        trackEvent({ eventToken: 'Procurou endereço', sourceIntegration });
      }, 900) as unknown as number;
    }
  }

  async function onSubmit(data: SearchAddressFormData) {
    hideVirtualKeyboard();

    const { address } = data;

    if (address?.length) {
      searchAddress(address);
      trackEvent({ eventToken: 'Procurou endereço', sourceIntegration });
    }
  }

  const ClearButton = () => {
    if (!addresses.length) return <></>;

    return (
      <IconButton sx={{ p: 0 }} onClick={clearAll}>
        <ClearIcon />
      </IconButton>
    );
  };

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <CustomInput
            {...field}
            key="address"
            name="address"
            label="Buscar endereço"
            icon={<SearchIcon titleAccess="icone de busca" />}
            endIcon={<ClearButton />}
            onBlur={(e) => {
              if (e.target.value) {
                eventInputAddress();
              }
            }}
            onChange={(input) => {
              field.onChange(input);
              handleSearchAddress({ address: input.target.value });
            }}
          />
        )}
      />
    </form>
  );
}
