import {
  styled,
  Typography,
  Radio as MUIRadio,
  FormLabel as MUIFormLabel,
  RadioGroup as MUIRadioGroup,
  FormControl as MUIFormControl,
  FormControlLabel as MUIFormControlLabel
} from '@mui/material';

export const FormControl = styled(MUIFormControl)`
  width: 100%;
`;

export const ChangeDescription = styled(Typography)(
  ({ theme }) => `
  font-size: 0.75rem;
  color: ${theme.palette.grey[900]};
`
);

export const FormLabel = styled(MUIFormLabel)(
  ({ theme }) => `
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${theme.palette.grey[900]} !important;
  font-weight: ${theme.typography.fontWeightRegular};
`
);

export const RadioGroup = styled(MUIRadioGroup)`
  width: 100%;
  flexdirection: row;
`;

export const Radio = styled(MUIRadio)`
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const FormControlLabel = styled(MUIFormControlLabel)`
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  cursor: pointer;
  font-size: 1rem;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-left: 0px !important;
  margin-right: 0px !important;
  color: ${({ theme }) => theme.palette.primary.main};
`;
