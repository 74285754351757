import { createContext, useContext } from 'react';
import { themeCreator, Options } from './themes';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';

export type ThemeContextData = { themeName: Options };

export const ThemeContext = createContext<ThemeContextData>(
  {} as ThemeContextData
);

export const ThemeProvider: React.FC = (props) => {
  const { integration } = useContext(SourceIntegrationContext);
  const themeName = (integration?.configurations?.theme || 'appgas') as Options;

  const theme = themeCreator(themeName);

  return (
    <ThemeContext.Provider value={{ themeName }}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
