import { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import { Container } from '../../components/Container';
import FormCheckPhone from './components/FormCheckPhone';
import { Stepper } from '../../components/Stepper';

import { useTheme } from '@mui/material/styles';
import { toPattern } from 'vanilla-masker';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import { useNavigate } from 'react-router-dom';

export default function CheckPhone() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { temporaryPhone } = useContext(LeadDataContext);

  useEffect(() => {
    !temporaryPhone && navigate('/create-lead');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!temporaryPhone) {
    return null;
  }

  return (
    <Container>
      <Box
        sx={{
          mt: '2rem',
          backgroundColor: 'white',
          padding: '15px',
          borderRadius: '8px',
          border: '1px solid #e0e0e0'
        }}
      >
        <Stepper
          totalSteps={3}
          currentStep={2}
          title={'Dados pessoais'}
          showStepInfo={true}
        />
      </Box>

      <Box sx={{ m: '16px 0' }}>
        <Typography sx={{ fontWeight: theme.typography.fontWeightLight }}>
          Informe o código de verificação que enviamos para{' '}
          <Box
            component="span"
            sx={{ color: theme.palette.primary.light, fontWeight: 'bold' }}
          >
            {toPattern(temporaryPhone, '(99) 99999-9999')}
          </Box>
        </Typography>
      </Box>

      <FormCheckPhone />
    </Container>
  );
}
