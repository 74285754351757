import { createContext } from 'react';
import { AddressInputData } from 'views/SaveAddress/types';

export interface IAddress {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

export interface IAddressDataInput {
  address: string;
  city: string;
  latitude: number;
  longitude: number;
  neighborhood: string;
  number: string;
  placeId: string;
  state: string;
  street: string;
  zip: string;
  complement?: string;
  referencePoint?: string;
}

export interface ICurrentLocationData {
  place_id: string;
  formatted_address: string;
}

export interface ICurrentLocation {
  loading: boolean;
  error: string | null;
  data: ICurrentLocationData | null;
}

export type AddressDetails = {
  results: {
    address_components: {
      long_name: string;
      short_name: string;
      types: string[];
    }[];
    geometry: {
      location: {
        lat: string;
        lng: string;
      };
    };
    formatted_address: string;
  }[];
};

export interface IAddressesContextData {
  addresses: IAddress[];
  currentLocation: ICurrentLocation;
  isSearchAddressLoading: boolean;
  addressDataInput: AddressInputData | null;
  formAddressDataInput: AddressInputData | null;
  setFormAddressDataInput: (input: AddressInputData | null) => void;
  clearList: () => void;
  searchAddress: (input: string) => void;
  setAdrreses: (input: IAddress[]) => void;
  chooseAddress: (address: IAddress) => void;
  getCurrentGeolocationByBrowser: () => void;
  setCurrentLocation: (input: ICurrentLocation) => void;
  setAddressDataInput: (input: AddressInputData | null) => void;
  setIsAddressModalOpen: (input: boolean) => void;
  createAddress: () => Promise<{ id: number }>;
}

export const AddressesContext = createContext<IAddressesContextData>(
  {} as IAddressesContextData
);
