import { createContext } from 'react';
import { ISummaryData } from 'views/Review/types/ISummaryData';

export interface SummaryContextData {
  summaryData: ISummaryData | null;
  setSummaryData: (input: ISummaryData | null) => void;
}

export const SummaryContext = createContext<SummaryContextData>(
  {} as SummaryContextData
);
