/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Typography } from '@mui/material';
import { Container } from '../../components/Container';
import EmojiEmotionsOutlined from '@mui/icons-material/EmojiEmotionsOutlined';

import { appGasService } from 'config/services/appgas';
import { useTheme } from '@mui/material';

import Comment from './components/Comment';
import { useRating } from './hooks/useOrderRatingContext';
import { useHeader } from '../../hooks/useHeaderContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reset as resetCompany } from 'store/company/companySlice';
import { gasToast } from 'helpers/toasts';
import { OrderContext } from 'providers/order-context/order.context';

export default function OrderRating() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setTitle, setSubtitle, setColor, setEditAddress } = useHeader();

  const { comment } = useRating();

  const { order, setOrder } = useContext(OrderContext);

  const clearOrder = () => {
    dispatch(resetCompany());
    setOrder(null);
  };

  const resetAndRedirect = () => {
    setTitle('Compra Web Appgas');
    setSubtitle('Seja bem-vindo(a)');
    setEditAddress(false);
    setColor(theme.palette.primary.dark);

    const permanentItems = ['leadData', 'addressData'];

    try {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (!permanentItems.includes(key as string)) {
          localStorage.removeItem(key as string);
        }
      }

      clearOrder();
    } catch {
      clearOrder();
    }

    navigate('/');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('5 minutes have passed!');

      resetAndRedirect();
    }, 5 * 60 * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (!order) {
      return;
    }

    try {
      await appGasService.post(
        `/v2/integrations/orders/${order.id}/create_rating`,
        {
          message: comment
        }
      );

      gasToast({ type: 'success', message: 'Obrigado pela avaliação.' });

      resetAndRedirect();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error?.response?.data?.message);
      }
    }
  }

  useEffect(() => {
    setColor(theme.palette.green.xdark);
    setSubtitle('Seu pedido foi entregue!');

    if (!order?.id) {
      return navigate('/');
    }

    (async () => {
      const { data } = await appGasService.get(
        `/v2/integrations/orders/${order.id}`
      );
      setTitle(`Pedido entregue em ${data.finished_at}`);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Box sx={{ mt: '68px', mb: '0px', textAlign: 'center' }}>
          <EmojiEmotionsOutlined
            sx={{
              color: theme.palette.green.xdark,
              fontSize: '60px'
            }}
          />

          <Typography
            sx={{
              mt: '20px',
              fontWeight: 'bold'
            }}
            fontSize="large"
            color={theme.palette.green.xdark}
          >
            Obrigado pela sua compra!
          </Typography>

          <Typography
            sx={{
              mt: '8px',
              mb: '26px',
              color: theme.palette.grey[700],
              fontWeight: theme.typography.fontWeightLight
            }}
          >
            Como foi sua experiência de compra com a{' '}
            <Typography
              component="span"
              sx={{ color: theme.palette.grey[700], fontWeight: '700' }}
            >
              {order?.reseller.display_name}
            </Typography>{' '}
            ?
          </Typography>
        </Box>
        <Comment />
        <Box sx={{ textAlign: 'center' }}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              py: '13.5px',
              fontWeight: 700,
              width: { xs: '100%', md: 'initial' }
            }}
          >
            Confirmar
          </Button>
        </Box>
      </Container>
    </form>
  );
}
