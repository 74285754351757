import { forwardRef, ReactElement, useState } from 'react';
import { InputBaseComponentProps, InputBaseProps } from '@mui/material';
import * as S from './style';

import Label from './components/Label';
import StartIcon from './components/StartIcon';
import HelperText from './components/HelperText';
import { useTheme } from '@mui/system';

type Props = {
  name: string;
  type?: string;
  value?: unknown;
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  label?: string | undefined;
  icon?: ReactElement | undefined;
  endIcon?: ReactElement | undefined;
  inputProps?: InputBaseComponentProps;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: (input: React.ChangeEvent<HTMLInputElement>) => void;
} & InputBaseProps;

const CustomInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      icon,
      endIcon,
      label,
      value,
      type,
      error,
      success,
      onBlur,
      onChange,
      helperText,
      inputProps = {},
      required = false,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();

    const [focused, setFocused] = useState(false);

    const inputWithLabelStyle = {
      height: '21px',
      lineHeight: '21px',
      marginTop: '16px'
    };

    const formBorderColor = () => {
      if (error) return theme.palette.error.main;

      if (success) return theme.palette.green.main;

      if (focused) return theme.palette.primary.main;

      return '#e0e0e0';
    };

    return (
      <>
        <S.Form
          disabled={disabled}
          error={error}
          sx={{ borderColor: formBorderColor() }}
          focused={focused}
        >
          {!!icon && (
            <StartIcon error={error} success={success}>
              {icon}
            </StartIcon>
          )}

          {!!label && (
            <Label
              error={error}
              htmlFor={name}
              success={success}
              compositionWithIcon={icon ? true : false}
            >
              {label}
            </Label>
          )}

          <S.Input
            fullWidth
            id={name}
            name={name}
            type={type}
            value={value}
            inputRef={ref}
            disabled={disabled}
            required={required}
            onBlur={(e) => {
              setFocused(false);
              if (onBlur) onBlur(e);
            }}
            onChange={onChange}
            inputProps={inputProps}
            onFocus={() => setFocused(true)}
            sx={label ? inputWithLabelStyle : {}}
            {...{ ...rest }}
          />

          {!!endIcon && (
            <StartIcon error={error} success={success}>
              {endIcon}
            </StartIcon>
          )}
        </S.Form>

        <HelperText error={error} success={success}>
          {helperText}
        </HelperText>
      </>
    );
  }
);

CustomInput.displayName = 'CustomInput';

export default CustomInput;
