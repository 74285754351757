import { combineReducers } from 'redux';
import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import order from './order/orderSlice';
import address from './address/addressSlice';
import company from './company/companySlice';
import authentication from './authentication/authenticationSlice';
import { bannerAPI } from './banner/requests';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [bannerAPI.reducerPath]
};

const reducers = combineReducers({
  address,
  company,
  order,
  authentication,
  [bannerAPI.reducerPath]: bannerAPI.reducer
});

const persistedReducers = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bannerAPI.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
