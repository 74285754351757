import { Button as MUIButton, styled } from '@mui/material';

export const Button = styled(MUIButton)({
  color: '#616161',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  width: '100%',
  padding: '13px 16px',
  fontWeight: '400'
});
