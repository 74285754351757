import { Theme } from '@mui/material/styles';
import { alelo } from './themes/alelo';
import { aleloec } from './themes/aleloec';
import { appgas } from './themes/appgas';
import { ifood } from './themes/ifood';
import { jeitto } from './themes/jeitto';
import { bancoMercantil } from './themes/bancomercantil';
import { c6bank } from './themes/c6bank';
import { credz } from './themes/credz';
import { creditas } from './themes/creditas';
import { ecobonuz } from './themes/ecobonuz';
import { picpay } from './themes/picpay';
import { bancoOriginal } from './themes/bancooriginal';
import { kaledo } from './themes/kaledo';
import { sindrodsantos } from './themes/sindrodsantos';
import { consigaz } from './themes/consigaz';
import { bradescocartoes } from './themes/bradescocartoes';
import { elo } from './themes/elo';
import { caixaelo } from './themes/caixaelo';
import { socialbank } from './themes/socialbank';

export type Options =
  | 'appgas'
  | 'alelo'
  | 'ifood'
  | 'jeitto'
  | 'bancomercantil'
  | 'bancooriginal'
  | 'credz'
  | 'creditas'
  | 'ecobonuz'
  | 'kaledo'
  | 'picpay'
  | 'sindrodsantos'
  | 'mastercard'
  | 'paoclass'
  | 'consigaz'
  | 'c6bank'
  | 'kaloo'
  | 'parceiros'
  | 'aleloec'
  | 'bradescocartoes'
  | 'bradesco'
  | 'elo'
  | 'caixaelo'
  | 'socialbank';

export function themeCreator(theme: Options): Theme {
  return themeMap[theme];
}

const themeMap: { [key in Options]: Theme } = {
  alelo,
  appgas,
  aleloec,
  ifood,
  jeitto,
  credz,
  creditas,
  ecobonuz,
  picpay,
  kaledo,
  bancomercantil: bancoMercantil,
  bancooriginal: bancoOriginal,
  sindrodsantos,
  c6bank,
  mastercard: appgas,
  paoclass: appgas,
  consigaz,
  kaloo: appgas,
  parceiros: appgas,
  bradescocartoes,
  bradesco: bradescocartoes,
  elo,
  caixaelo,
  socialbank
};
