import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { Product } from 'types';
import { Category } from 'types/Category';

interface ListProductsUseCaseProps {
  longitude: number | string;
  latitude: number | string;
  address_id?: number | string;
  category_id?: number | string;
}

interface Response {
  categories: Category[];
  products: Product[];
}

export class ListProductsUseCase {
  private api = appGasService;

  async handle(props: ListProductsUseCaseProps) {
    const { address_id, category_id, latitude, longitude } = props;

    const { data } = await this.api.get<unknown, AxiosResponse<Response>>(
      `/v3/integrations/products?longitude=${longitude}&latitude=${latitude}${
        address_id ? `&address_id=${address_id}` : ''
      }${category_id ? `&category_id=${category_id}` : ''}`
    );

    return data;
  }
}
