import { Box, CardMedia, Typography } from '@mui/material';
import { ResellerBadgeModel } from 'types';

interface ResellerBadgeProps {
  badge: ResellerBadgeModel;
}

export function ResellerBadge(props: ResellerBadgeProps) {
  const { background_color, icon, label_color, name } = props.badge;

  return (
    <Box
      px={'4px'}
      py={'1.5px'}
      sx={{ backgroundColor: background_color }}
      borderRadius={'4px'}
      gap={'4px'}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'max-content'}
    >
      <CardMedia
        component="img"
        image={`${icon}`}
        alt="icon"
        sx={{ height: 8, width: 8, objectFit: 'contain' }}
      />
      <Typography
        variant="body1"
        fontSize={'10px'}
        fontWeight={'500'}
        color={label_color}
      >
        {name}
      </Typography>
    </Box>
  );
}
