import {
  ButtonsBox,
  IconButton,
  ModalTitle,
  ModalSubTitle,
  NoChangeButton,
  ConfirmChangeButton
} from './styles';
import { toast } from 'react-toastify';
import CustomInput from 'components/CustomInput';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

import { useCheckout } from 'views/Review/hooks/useCheckoutContext';
import { formatMoney } from 'helpers/InputMoneyMask';
import { formatPrice } from 'helpers/formatPrice';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  purchaseTotal: number;
};

export default function ChangeModal(props: ModalProps) {
  const { onClose, open, purchaseTotal } = props;

  const { change, setChange } = useCheckout();

  function doNotNeedChange() {
    setChange('0.0');
    onClose();
  }

  const changeMoney = (): number => {
    let numero = change.replace('R$', '').trim();

    numero = numero.replace(/\./g, '');
    numero = numero.replace(',', '.');

    return parseFloat(numero);
  };

  function handleChange() {
    if (changeMoney() <= purchaseTotal) {
      toast.error('Troco inferior ao valor total', {
        autoClose: 7000,
        draggable: true,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'bottom-center',
        hideProgressBar: false
      });

      return;
    }

    onClose();
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{ padding: '24px' }}
    >
      <DialogTitle>
        <ModalTitle>Precisa de troco?</ModalTitle>

        <ModalSubTitle>
          Valor total da compra: {formatPrice(Number(purchaseTotal))}
        </ModalSubTitle>

        <IconButton aria-label="close-modal" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <CustomInput
          name="change"
          value={formatMoney(change)}
          label="Troco para quanto"
          onChange={(e) => {
            setChange(formatMoney(e.target.value));
          }}
          icon={<AccountBalanceWalletOutlinedIcon color="primary" />}
        />

        <ButtonsBox>
          <NoChangeButton variant="text" onClick={() => doNotNeedChange()}>
            Não preciso de troco
          </NoChangeButton>

          <ConfirmChangeButton
            variant="contained"
            onClick={() => handleChange()}
          >
            Confirmar
          </ConfirmChangeButton>
        </ButtonsBox>
      </DialogContent>
    </Dialog>
  );
}
