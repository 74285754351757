import { ModalTitle, ModalSubTitle } from './styles';

import { Dialog, DialogTitle } from '@mui/material';
import Loading from 'components/Loading';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

export default function ProcessingModal(props: ModalProps) {
  const { onClose, open } = props;

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={onClose}
      sx={{ padding: '24px' }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Loading />

        <ModalTitle>Solicitação em andamento</ModalTitle>

        <ModalSubTitle>
          Aguarde só um pouquinho, estamos processando sua solicitação e logo
          mais ela será realizada.
        </ModalSubTitle>
      </DialogTitle>
    </Dialog>
  );
}
