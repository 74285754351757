import { CardMedia, Stack, Tabs, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Category } from 'types/Category';

interface CategoriesListProps {
  categories: Category[];
  onChange: (category: Category | null) => void;
  selectedCategory: Category | null;
}

export function CategoriesList(props: CategoriesListProps) {
  const { categories, selectedCategory, onChange } = props;
  const theme = useTheme();

  return (
    <Tabs variant="scrollable" scrollButtons="auto">
      <Stack direction={'row'} gap={'8px'} width={'100%'}>
        {categories.map((category) => (
          <Stack
            key={category.id}
            sx={{
              border:
                selectedCategory?.id == category.id
                  ? `solid 1px ${theme.palette.primary.main}`
                  : `solid 1px ${grey[300]}`,
              borderRadius: '13px',
              cursor: 'pointer',
              bgcolor:
                selectedCategory?.id == category.id
                  ? theme.palette.primary.xdark
                  : '#fff',
              height: '105px',
              width: '100px'
            }}
            justifyContent={'center'}
            alignItems={'center'}
            onClick={() => {
              if (selectedCategory?.id !== category.id) {
                onChange(category);
              }
            }}
            px={'20px'}
            gap={'10px'}
          >
            <CardMedia
              component="img"
              image={category.picture}
              sx={{
                width: '40px',
                objectFit: 'contain'
              }}
            />
            <Typography
              fontSize={'12px'}
              whiteSpace={'normal'}
              lineHeight={'15px'}
              sx={{
                color: selectedCategory?.id == category.id ? '#fff' : '#000'
              }}
              textAlign={'center'}
              fontWeight={'600'}
            >
              {category.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Tabs>
  );
}
