import { styled, Box as MUIBox } from '@mui/material';

export const HeaderStepper = styled(MUIBox)(
  () => `
  padding: 15px;
  margin-top: 2rem;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #e0e0e0;
`
);
