import { useContext, useState } from 'react';
import { Box, useTheme, Typography, IconButton, lighten } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { SummaryContext } from 'providers/summary-context/summary.context';
import { CardGiftcard } from '@mui/icons-material';
import SmashGiftCardsModal from './SmashGiftCardsModal';
import { formatPrice } from 'helpers/formatPrice';

export default function SmashGiftCards() {
  const theme = useTheme();

  const { summaryData, setSummaryData } = useContext(SummaryContext);
  const [modalOpen, setModalOpen] = useState(false);

  const removeGiftCard = () => {
    const newSummary = {
      ...summaryData,
      giftcard: ''
    };

    delete newSummary.giftcard_discount;

    setSummaryData(newSummary);
  };

  const isGiftCardApplied = summaryData?.giftcard;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <CardGiftcard color="primary" sx={{ mr: '12px' }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: !isGiftCardApplied
                ? theme.palette.grey[900]
                : theme.palette.primary.main
            }}
            variant="subtitle1"
          >
            {!isGiftCardApplied ? 'Gift Card' : 'Gift card aplicado'}
          </Typography>
          {isGiftCardApplied && (
            <Box
              sx={{
                ml: '14px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 6px',
                borderRadius: '4px',
                fontSize: '10px',
                fontWeight: 700,
                height: '22px',
                background: theme.palette.primary.main,
                color: '#fff'
              }}
            >
              <span>
                {summaryData.giftcard_discount &&
                  formatPrice(summaryData.giftcard_discount)}{' '}
                desconto
              </span>
            </Box>
          )}
        </Box>
      </Box>

      <IconButton
        size="small"
        onClick={() =>
          !isGiftCardApplied ? setModalOpen(true) : removeGiftCard()
        }
        sx={{
          background: isGiftCardApplied
            ? '#E0E0E0'
            : theme.palette.primary.xdark,
          color: isGiftCardApplied ? ' #757575 ' : '#FFF',
          '&:hover': {
            background: lighten(
              isGiftCardApplied ? '#E0E0E0' : theme.palette.primary.xdark,
              0.1
            )
          }
        }}
      >
        {!isGiftCardApplied && <AddIcon fontSize="small" />}
        {isGiftCardApplied && <CloseIcon fontSize="small" />}
      </IconButton>

      <SmashGiftCardsModal
        open={modalOpen}
        title="Resgatar gift card"
        onClose={() => setModalOpen(false)}
      />
    </Box>
  );
}
