import { ReactNode } from 'react';
import { useTheme } from '@mui/material';

import { CustomLabel } from './style';

type Props = {
  error?: boolean;
  htmlFor?: string;
  success?: boolean;
  children?: ReactNode;
  compositionWithIcon: boolean;
};

export default function Label({
  error,
  htmlFor,
  success,
  children,
  compositionWithIcon = false
}: Props) {
  const theme = useTheme();

  const labelStyle = (color: string, compositionWithIcon: boolean) => ({
    color,
    paddingLeft: compositionWithIcon ? '32px' : 'inital',
    fontWeight: theme.typography.fontWeightLight,
    '&.MuiInputLabel-shrink': {
      paddingLeft: compositionWithIcon ? '45px' : 'initial',
      color: theme.palette.primary.dark
    },
    '&.Mui-focused': {
      color: theme.palette.primary.dark
    }
  });

  return (
    <>
      {error && (
        <CustomLabel
          sx={labelStyle(theme.palette.error.main, compositionWithIcon)}
        >
          <label htmlFor={htmlFor}>{children}</label>
        </CustomLabel>
      )}

      {success && (
        <CustomLabel
          sx={labelStyle(theme.palette.green.main, compositionWithIcon)}
        >
          <label htmlFor={htmlFor}>{children}</label>
        </CustomLabel>
      )}

      {!success && !error && (
        <CustomLabel
          sx={labelStyle(theme.palette.grey['400'], compositionWithIcon)}
        >
          <label htmlFor={htmlFor}>{children}</label>
        </CustomLabel>
      )}
    </>
  );
}
